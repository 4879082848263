import {Instance, types} from 'mobx-state-tree';

import {IWidgetData} from 'core/interfaces';
import {EntityBaseInfo, Widget} from 'core/models';

const SampleResultInfo = types
  .compose(
    EntityBaseInfo,
    types.model('SampleResultInfo', {
      sampleUuid: types.string,
      widget: Widget
    })
  )
  .views((self) => ({
    get widgetData(): IWidgetData {
      return {
        uuid: self.widget.uuid,
        name: self.widget.name,
        data: self.widget.data,
        type: self.widget.widgetType,
        entityUuid: self.uuid
      };
    }
  }));

export type TSampleResultInfoModel = Instance<typeof SampleResultInfo>;

export {SampleResultInfo};
