import {FC, DragEvent, useState, useRef, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {ERFElement, IRFMenuItem} from '@progress-fe/rf-core';
import {Box, Flex, useOutsideClick} from '@chakra-ui/react';

import {RFMenuItem, RFMenuGroupItem} from '../../../../index';

interface IProps {
  item: IRFMenuItem;
  isDragging: boolean;
  updateIsDragging: (isDragging: boolean) => void;
  onDragStart: (event: DragEvent<HTMLDivElement>, type: ERFElement, template: string) => void;
}

const RFMenuGroupFC: FC<IProps> = ({item, isDragging, updateIsDragging, onDragStart}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick({ref: ref, handler: () => setIsOpen(false)});

  useEffect(() => {
    if (!isDragging) {
      setIsOpen(false);
    }
  }, [isDragging]);

  return (
    <Box position="relative">
      <RFMenuItem
        item={item}
        isDragging={isDragging}
        updateIsDragging={updateIsDragging}
        onClick={() => setIsOpen(!isOpen)}
      />

      {isOpen && (
        <Flex
          ref={ref}
          p="4px"
          gap="4px"
          bg="buttonBg"
          boxShadow="shadow_2"
          position="absolute"
          flexDirection="column"
          left="calc(100% + 5px)"
          borderRadius="4px"
          top="-4px"
        >
          {item.items?.map((subItem) => (
            <RFMenuGroupItem
              key={subItem.type}
              item={subItem}
              updateIsDragging={updateIsDragging}
              onDragStart={onDragStart}
            />
          ))}
        </Flex>
      )}
    </Box>
  );
};

export const RFMenuGroup = observer(RFMenuGroupFC);
