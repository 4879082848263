import {Instance, types} from 'mobx-state-tree';

import {ReactionInfo, ResetModel, TReactionInfoModel} from 'core/models';

const ProjectReactions = types
  .compose(
    ResetModel,
    types.model('ProjectReactions', {
      projectUuid: '',
      checkpointUuid: '',
      reactions: types.optional(types.array(ReactionInfo), [])
    })
  )
  .actions((self) => ({
    _loadReactions() {
      self.reactions.push(
        ReactionInfo.create({uuid: '1', name: 'Реакция 1', lastUpdated: new Date()})
      );
      self.reactions.push(
        ReactionInfo.create({uuid: '2', name: 'Реакция 2', lastUpdated: new Date()})
      );
    }
  }))
  .actions((self) => ({
    init(projectUuid: string, checkpointUuid: string) {
      self.resetModel();
      self.projectUuid = projectUuid;
      self.checkpointUuid = checkpointUuid;
      self._loadReactions();
    }
  }))
  .views((self) => ({
    get dictionary(): Record<string, TReactionInfoModel> {
      const dict = self.reactions.reduce(
        (acc: Record<string, TReactionInfoModel>, cur: TReactionInfoModel) => {
          acc[cur.uuid] = cur;
          return acc;
        },
        {}
      );

      return dict;
    },
    get isFormLoading(): boolean {
      return false;
    },
    get isFormDataUpdating(): boolean {
      return false;
    }
  }));

export type TProjectReactionsModel = Instance<typeof ProjectReactions>;

export {ProjectReactions};
