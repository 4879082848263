import {applySnapshot, cast, flow, Instance, types} from 'mobx-state-tree';
import {v4 as uuidv4} from 'uuid';

import {ResetModel, RequestModel, SampleInfo, JsonSchemaForm, TSampleInfoModel} from 'core/models';
import {
  OilApi,
  SampleOut,
  SamplesOut,
  RJSFSchemas,
  AppProjectsOilSchemasSchemasUpdateOut
} from 'api';

const ProjectSamples = types
  .compose(
    ResetModel,
    types.model('ProjectSamples', {
      projectUuid: '',
      checkpointUuid: '',

      samples: types.optional(types.array(SampleInfo), []),
      jsonForm: types.maybeNull(JsonSchemaForm),

      creationRequest: types.optional(RequestModel, {}),
      deleteRequest: types.optional(RequestModel, {}),
      fetchRequest: types.optional(RequestModel, {})
    })
  )
  .actions((self) => ({
    _clearJsonForm() {
      self.jsonForm?.clear();
      self.jsonForm = null;
    },
    _reloadJsonForm: flow(function* (uuid: string) {
      self.jsonForm = JsonSchemaForm.create({entityUuid: uuid});
      const response: {[key: string]: RJSFSchemas} = yield self.jsonForm.fetchRequest.send(
        OilApi.oilGetSample.bind(OilApi),
        {
          sampleUuid: uuid,
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (response) {
        self.jsonForm = JsonSchemaForm.create({entityUuid: uuid});
        self.jsonForm.setJsonSchemas(response);
      }
    })
  }))
  .actions((self) => ({
    _reload: flow(function* () {
      self.samples = cast([]);

      const response: SamplesOut = yield self.fetchRequest.send(OilApi.oilGetSamples.bind(OilApi), {
        projectUuid: self.projectUuid,
        checkpointUuid: self.checkpointUuid
      });

      const responseSamples = response.samples.map((m) => ({
        uuid: m.uuid,
        name: m.name,
        lastUpdated: new Date(),
        deletable: true
      }));

      applySnapshot(self.samples, responseSamples);
    })
  }))
  .actions((self) => ({
    init: flow(function* (projectUuid: string, checkpointUuid: string) {
      self.projectUuid = projectUuid;
      self.checkpointUuid = checkpointUuid;
      yield self._reload();
    })
  }))
  .actions((self) => ({
    addSample: flow(function* () {
      const response: SampleOut = yield self.creationRequest.send(
        OilApi.oilCreateSample.bind(OilApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          idempotencyKey: uuidv4()
        }
      );

      return self.creationRequest.isDone && !!response
        ? SampleInfo.create({
            uuid: response.uuid,
            name: response.name,
            lastUpdated: new Date(),
            deletable: true
          })
        : null;
    }),
    removeSample: flow(function* (uuid: string) {
      yield self.deleteRequest.send(OilApi.oilDeleteSample.bind(OilApi), {
        projectUuid: self.projectUuid,
        checkpointUuid: self.checkpointUuid,
        sampleUuid: uuid
      });

      return self.deleteRequest.isDone;
    }),
    _updateSampleName(uuid: string, name: string) {
      const sample = self.samples.find((n) => n.uuid === uuid);
      sample?.setName(name);
    }
  }))
  .actions((self) => ({
    _updateSampleFormData: flow(function* (uuid: string, schemaId: string, data: unknown) {
      const jsonSchema = self.jsonForm?.jsonSchemas.find((js) => js.id === schemaId);
      if (!self.jsonForm || !jsonSchema) {
        return;
      }

      jsonSchema.updateFormData(data);
      const response: AppProjectsOilSchemasSchemasUpdateOut =
        yield self.jsonForm.updateRequest.send(OilApi.oilUpdateSample.bind(OilApi), {
          sampleUuid: uuid,
          body: data as object,
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        });

      if (!response) {
        return;
      }

      if (response.name) {
        self._updateSampleName(uuid, response.name);
      }
      if (response.schemas) {
        self.jsonForm?.setJsonSchemas(response.schemas);
      }
    })
  }))
  .actions((self) => ({
    hasSample(uuid: string): boolean {
      return self.samples.some((e) => e.uuid === uuid);
    },
    findSample(uuid: string): TSampleInfoModel | undefined {
      return self.samples.find((e) => e.uuid === uuid);
    }
  }))
  .views((self) => ({
    get isLoading(): boolean {
      return (
        self.fetchRequest.isPending ||
        self.creationRequest.isPending ||
        self.deleteRequest.isPending
      );
    },
    get isCreating(): boolean {
      return self.creationRequest.isPending;
    },
    get isFormLoading(): boolean {
      return self.jsonForm?.isFormLoading ?? false;
    },
    get isFormDataUpdating(): boolean {
      return self.jsonForm?.isFormDataUpdating ?? false;
    }
  }));

export type TProjectSamplesModel = Instance<typeof ProjectSamples>;

export {ProjectSamples};
