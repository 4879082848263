import {Instance, types} from 'mobx-state-tree';

import {EWidgetType} from 'core/enums';

const Widget = types.model('Widget', {
  uuid: types.string,
  name: types.string,
  widgetType: types.enumeration(Object.values(EWidgetType)),
  data: types.maybeNull(types.frozen<unknown>())
});
export type TWidgetModel = Instance<typeof Widget>;

export {Widget};
