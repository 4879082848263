/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {WorkZoneElement} from './WorkZoneElement';
import {
  WorkZoneElementFromJSON,
  WorkZoneElementFromJSONTyped,
  WorkZoneElementToJSON
} from './WorkZoneElement';
import type {EdgesInner} from './EdgesInner';
import {EdgesInnerFromJSON, EdgesInnerFromJSONTyped, EdgesInnerToJSON} from './EdgesInner';

/**
 *
 * @export
 * @interface WorkZone
 */
export interface WorkZone {
  /**
   *
   * @type {Array<EdgesInner>}
   * @memberof WorkZone
   */
  edges?: Array<EdgesInner>;
  /**
   *
   * @type {Array<WorkZoneElement>}
   * @memberof WorkZone
   */
  nodes?: Array<WorkZoneElement>;
}

/**
 * Check if a given object implements the WorkZone interface.
 */
export function instanceOfWorkZone(value: object): value is WorkZone {
  return true;
}

export function WorkZoneFromJSON(json: any): WorkZone {
  return WorkZoneFromJSONTyped(json, false);
}

export function WorkZoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkZone {
  if (json == null) {
    return json;
  }
  return {
    edges:
      json['edges'] == null ? undefined : (json['edges'] as Array<any>).map(EdgesInnerFromJSON),
    nodes:
      json['nodes'] == null ? undefined : (json['nodes'] as Array<any>).map(WorkZoneElementFromJSON)
  };
}

export function WorkZoneToJSON(value?: WorkZone | null): any {
  if (value == null) {
    return value;
  }
  return {
    edges:
      value['edges'] == null ? undefined : (value['edges'] as Array<any>).map(EdgesInnerToJSON),
    nodes:
      value['nodes'] == null ? undefined : (value['nodes'] as Array<any>).map(WorkZoneElementToJSON)
  };
}
