/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {RJSFSchemas} from './RJSFSchemas';
import {RJSFSchemasFromJSON, RJSFSchemasFromJSONTyped, RJSFSchemasToJSON} from './RJSFSchemas';
import type {WorkZone} from './WorkZone';
import {WorkZoneFromJSON, WorkZoneFromJSONTyped, WorkZoneToJSON} from './WorkZone';

/**
 *
 * @export
 * @interface ElementUpdateOut
 */
export interface ElementUpdateOut {
  /**
   *
   * @type {string}
   * @memberof ElementUpdateOut
   */
  name: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ElementUpdateOut
   */
  dataChanged: boolean;
  /**
   *
   * @type {{ [key: string]: RJSFSchemas; }}
   * @memberof ElementUpdateOut
   */
  schemas: {[key: string]: RJSFSchemas} | null;
  /**
   *
   * @type {WorkZone}
   * @memberof ElementUpdateOut
   */
  workzone: WorkZone | null;
  /**
   *
   * @type {boolean}
   * @memberof ElementUpdateOut
   */
  modelsChanged: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ElementUpdateOut
   */
  elementsChanged: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ElementUpdateOut
   */
  logicalElementsChanged: boolean;
}

/**
 * Check if a given object implements the ElementUpdateOut interface.
 */
export function instanceOfElementUpdateOut(value: object): value is ElementUpdateOut {
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('dataChanged' in value) || value['dataChanged'] === undefined) return false;
  if (!('schemas' in value) || value['schemas'] === undefined) return false;
  if (!('workzone' in value) || value['workzone'] === undefined) return false;
  if (!('modelsChanged' in value) || value['modelsChanged'] === undefined) return false;
  if (!('elementsChanged' in value) || value['elementsChanged'] === undefined) return false;
  if (!('logicalElementsChanged' in value) || value['logicalElementsChanged'] === undefined)
    return false;
  return true;
}

export function ElementUpdateOutFromJSON(json: any): ElementUpdateOut {
  return ElementUpdateOutFromJSONTyped(json, false);
}

export function ElementUpdateOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ElementUpdateOut {
  if (json == null) {
    return json;
  }
  return {
    name: json['name'],
    dataChanged: json['data_changed'],
    schemas: json['schemas'] == null ? null : mapValues(json['schemas'], RJSFSchemasFromJSON),
    workzone: WorkZoneFromJSON(json['workzone']),
    modelsChanged: json['models_changed'],
    elementsChanged: json['elements_changed'],
    logicalElementsChanged: json['logical_elements_changed']
  };
}

export function ElementUpdateOutToJSON(value?: ElementUpdateOut | null): any {
  if (value == null) {
    return value;
  }
  return {
    name: value['name'],
    data_changed: value['dataChanged'],
    schemas: value['schemas'] == null ? null : mapValues(value['schemas'], RJSFSchemasToJSON),
    workzone: WorkZoneToJSON(value['workzone']),
    models_changed: value['modelsChanged'],
    elements_changed: value['elementsChanged'],
    logical_elements_changed: value['logicalElementsChanged']
  };
}
