/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const UoM = {
  MolarFlowRate: 'molar_flow_rate',
  MassFlowRate: 'mass_flow_rate',
  VolumetricFlowRate: 'volumetric_flow_rate'
} as const;
export type UoM = (typeof UoM)[keyof typeof UoM];

export function instanceOfUoM(value: any): boolean {
  for (const key in UoM) {
    if (Object.prototype.hasOwnProperty.call(UoM, key)) {
      if ((UoM as Record<string, UoM>)[key] === value) {
        return true;
      }
    }
  }
  return false;
}

export function UoMFromJSON(json: any): UoM {
  return UoMFromJSONTyped(json, false);
}

export function UoMFromJSONTyped(json: any, ignoreDiscriminator: boolean): UoM {
  return json as UoM;
}

export function UoMToJSON(value?: UoM | null): any {
  return value as any;
}
