import {FC} from 'react';
import {Grid} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {FlexDivider} from '@progress-fe/ui-kit';
import {EStructureItem} from '@progress-fe/core';
import {Panel, PanelGroup, PanelResizeHandle} from 'react-resizable-panels';

import {useStore} from 'core/hooks';

import {ElementList, ModelsList, ElementsResultsList, Settings, TasksList} from './components';

const ProjectStructureFC: FC = () => {
  const {techProcessStore, catalogsStore} = useStore();
  const {projectModels, projectElements, projectElementsResults, projectTask} = techProcessStore;
  const {uiState, selectEntityByType, errors, checkpointUuid} = techProcessStore;
  const {elementsCatalog, modelsCatalog} = catalogsStore;
  const {entityId, subEntityId, entityType} = uiState;

  return (
    <Grid gridTemplateRows={'32px 8px 1fr'} h="var(--height-layout)">
      {/* PROJECT SETTINGS */}
      <Settings
        isSelected={entityType === EStructureItem.Settings}
        onSelect={() => selectEntityByType(EStructureItem.Settings)}
      />

      <FlexDivider />
      <PanelGroup direction="vertical" autoSaveId={checkpointUuid}>
        {/* PROJECT ELEMENTS */}
        <Panel minSize={11}>
          <ElementList
            isLoading={projectElements.isLoading}
            elements={projectElements.elements}
            nodes={techProcessStore.workzone.nodes}
            menuItems={elementsCatalog.svgMenuItems}
            errors={errors.filter((e) => e.entityType === 'element')}
            selectedId={entityType === EStructureItem.Element ? entityId : null}
            selectedSubId={entityType === EStructureItem.Element ? subEntityId : null}
            onSelect={(uuid, subUuid) => selectEntityByType(EStructureItem.Element, uuid, subUuid)}
            onCreate={techProcessStore.createElement}
            onDelete={techProcessStore.deleteElements}
            onDuplicate={techProcessStore.duplicateElement}
          />
        </Panel>
        <PanelResizeHandle>
          <FlexDivider withDots />
        </PanelResizeHandle>
        {/* PROJECT ELEMENTS MODELS */}
        <Panel minSize={11}>
          <ModelsList
            isLoading={projectModels.isLoading}
            models={projectModels.models}
            menuItems={modelsCatalog.svgMenuItems}
            errors={errors.filter((e) => e.entityType === 'model')}
            selectedId={entityType === EStructureItem.Model ? entityId : null}
            selectedSubId={entityType === EStructureItem.Model ? subEntityId : null}
            onSelect={(uuid, subUuid) => selectEntityByType(EStructureItem.Model, uuid, subUuid)}
            onGetElementsByModel={projectModels.getElementsInUse}
            onCreate={techProcessStore.createModel}
            onDelete={techProcessStore.deleteModel}
            onDuplicate={techProcessStore.duplicateModel}
          />
        </Panel>
        <PanelResizeHandle>
          <FlexDivider withDots />
        </PanelResizeHandle>
        {/* PROJECT TASKS & LOGICAL ELEMENTS */}
        <Panel minSize={11}>
          <TasksList
            isLoading={projectTask.isLoading}
            taskType={projectTask.type}
            logicalElements={projectTask.logicalElements}
            menuItems={projectTask.logicalMenuItems}
            isTaskSelected={entityType === EStructureItem.Task && !entityId}
            selectedId={entityType === EStructureItem.Task ? entityId : null}
            onSelect={(uuid) => selectEntityByType(EStructureItem.Task, uuid)}
            onCreate={techProcessStore.createLogicalElement}
            onDelete={techProcessStore.deleteLogicalElement}
            onDuplicate={techProcessStore.duplicateLogicalElement}
          />
        </Panel>
        <PanelResizeHandle>
          <FlexDivider withDots />
        </PanelResizeHandle>
        {/* PROJECT ELEMENTS RESULTS */}
        <Panel minSize={11}>
          <ElementsResultsList
            isLoading={projectElementsResults.isLoading}
            elementResults={projectElementsResults.elementResults}
            selectedId={entityType === EStructureItem.ElementResult ? entityId : null}
            onSelect={(uuid) => selectEntityByType(EStructureItem.ElementResult, uuid)}
          />
        </Panel>
      </PanelGroup>
    </Grid>
  );
};

export const ProjectStructure = observer(ProjectStructureFC);
