import {applySnapshot, cast, flow, Instance, types} from 'mobx-state-tree';
import FileSaver from 'file-saver';
import {v4 as uuidv4} from 'uuid';

import {ResetModel, RequestModel, BlendInfo, TBlendInfoModel, JsonSchemaForm} from 'core/models';
import {
  AppProjectsOilSchemasSchemasBlendOut,
  AppProjectsOilSchemasSchemasUpdateOut,
  BlendsOut,
  OilApi,
  RJSFSchemas
} from 'api';

const ProjectBlends = types
  .compose(
    ResetModel,
    types.model('ProjectBlends', {
      projectUuid: '',
      checkpointUuid: '',

      blends: types.optional(types.array(BlendInfo), []),
      jsonForm: types.maybeNull(JsonSchemaForm),

      creationRequest: types.optional(RequestModel, {}),
      deleteRequest: types.optional(RequestModel, {}),
      fetchRequest: types.optional(RequestModel, {}),
      exportRequest: types.optional(RequestModel, {})
    })
  )
  .actions((self) => ({
    _clearJsonForm() {
      self.jsonForm?.clear();
      self.jsonForm = null;
    },
    _reloadJsonForm: flow(function* (uuid: string) {
      self.jsonForm = JsonSchemaForm.create({entityUuid: uuid});
      const response: {[key: string]: RJSFSchemas} = yield self.jsonForm.fetchRequest.send(
        OilApi.oilGetBlend.bind(OilApi),
        {
          blendUuid: uuid,
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (response) {
        self.jsonForm = JsonSchemaForm.create({entityUuid: uuid});
        self.jsonForm.setJsonSchemas(response);
      }
    })
  }))
  .actions((self) => ({
    _reload: flow(function* () {
      self.blends = cast([]);

      const response: BlendsOut = yield self.fetchRequest.send(OilApi.oilGetBlends.bind(OilApi), {
        projectUuid: self.projectUuid,
        checkpointUuid: self.checkpointUuid
      });

      const responseBlends = response.blends.map((m) => ({
        uuid: m.uuid,
        name: m.name,
        lastUpdated: new Date(),
        deletable: true
      }));

      applySnapshot(self.blends, responseBlends);
    })
  }))
  .actions((self) => ({
    init: flow(function* (projectUuid: string, checkpointUuid: string) {
      self.projectUuid = projectUuid;
      self.checkpointUuid = checkpointUuid;
      yield self._reload();
    })
  }))
  .actions((self) => ({
    addBlend: flow(function* () {
      const response: AppProjectsOilSchemasSchemasBlendOut = yield self.creationRequest.send(
        OilApi.oilCreateBlend.bind(OilApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          idempotencyKey: uuidv4()
        }
      );

      return self.creationRequest.isDone && !!response
        ? BlendInfo.create({
            uuid: response.uuid,
            name: response.name,
            lastUpdated: new Date(),
            deletable: true
          })
        : null;
    }),
    removeBlend: flow(function* (uuid: string) {
      yield self.deleteRequest.send(OilApi.oilDeleteBlend.bind(OilApi), {
        projectUuid: self.projectUuid,
        checkpointUuid: self.checkpointUuid,
        blendUuid: uuid
      });

      return self.deleteRequest.isDone;
    }),
    _updateBlendName(uuid: string, name: string) {
      const blend = self.blends.find((n) => n.uuid === uuid);
      blend?.setName(name);
    }
  }))
  .actions((self) => ({
    _updateBlendFormData: flow(function* (uuid: string, schemaId: string, data: unknown) {
      const jsonSchema = self.jsonForm?.jsonSchemas.find((js) => js.id === schemaId);
      if (!self.jsonForm || !jsonSchema) {
        return;
      }

      jsonSchema.updateFormData(data);
      const response: AppProjectsOilSchemasSchemasUpdateOut =
        yield self.jsonForm.updateRequest.send(OilApi.oilUpdateBlend.bind(OilApi), {
          blendUuid: uuid,
          body: data as object,
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        });

      if (!response) {
        return;
      }

      if (response.name) {
        self._updateBlendName(uuid, response.name);
      }
      if (response.schemas) {
        self.jsonForm?.setJsonSchemas(response.schemas);
      }
    })
  }))
  .actions((self) => ({
    exportBlend: flow(function* (blendUuid: string) {
      const response = yield self.exportRequest.send(OilApi.oilGetOilBlend.bind(OilApi), {
        blendUuid: blendUuid,
        checkpointUuid: self.checkpointUuid,
        projectUuid: self.projectUuid
      });

      if (self.exportRequest.isDone) {
        const blend = self.findBlend(blendUuid);
        const blob = new Blob([response], {type: 'text/plain;charset=utf-8'});
        FileSaver.saveAs(blob, `${blend?.name ?? 'blend'}.json`);
      }
    })
  }))
  .actions((self) => ({
    hasBlend(uuid: string): boolean {
      return self.blends.some((e) => e.uuid === uuid);
    },
    findBlend(uuid: string): TBlendInfoModel | undefined {
      return self.blends.find((e) => e.uuid === uuid);
    }
  }))
  .views((self) => ({
    get isLoading(): boolean {
      return (
        self.fetchRequest.isPending ||
        self.creationRequest.isPending ||
        self.deleteRequest.isPending
      );
    },
    get isCreating(): boolean {
      return self.creationRequest.isPending;
    },
    get isFormLoading(): boolean {
      return self.jsonForm?.isFormLoading ?? false;
    },
    get isFormDataUpdating(): boolean {
      return self.jsonForm?.isFormDataUpdating ?? false;
    }
  }));

export type TProjectBlendModel = Instance<typeof ProjectBlends>;

export {ProjectBlends};
