import {FC, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Grid, GridItem} from '@chakra-ui/react';
import {FlexDivider} from '@progress-fe/ui-kit';
import {hasValue} from '@progress-fe/core';
import {Rnd} from 'react-rnd';

import {EJournalItemType} from 'core/enums';
import {IJournalPoint} from 'core/interfaces';

import {JournalPointList, JournalPointItems, JournalTabs} from './components';

interface IProps {
  journalPoints: IJournalPoint[];
  onClose: () => void;
}

const HEADER_HEIGHT = 48;
const FILTER_HEIGHT = 34;
const DIVIDER_HEIGHT = 8;
const JOURNAL_HEIGHT_MIN = 150;
const JOURNAL_HEIGHT_DEFAULT = 250;

const JournalViewerFC: FC<IProps> = ({journalPoints, onClose}) => {
  const [logsHeight, setLogsHeight] = useState(JOURNAL_HEIGHT_DEFAULT);

  const [activeNumber, setActiveNumber] = useState<number | null>(null);
  const [filterType, setFilterType] = useState<EJournalItemType | null>(null);

  useEffect(() => {
    setActiveNumber(journalPoints.length > 0 ? journalPoints[0].calculationNumber : null);
  }, [journalPoints, journalPoints.length]);

  const items = journalPoints.find((p) => p.calculationNumber === activeNumber)?.items || [];
  const filteredItems = filterType ? items.filter((l) => l.type === filterType) : items;

  return (
    <Rnd
      style={{zIndex: 10}}
      disableDragging={true}
      enableResizing={{
        top: true,
        left: false,
        right: false,
        bottom: false,
        topLeft: false,
        topRight: false,
        bottomLeft: false,
        bottomRight: false
      }}
      minHeight={JOURNAL_HEIGHT_MIN}
      maxHeight={document.body.offsetHeight - HEADER_HEIGHT}
      default={{
        x: 0,
        y: document.body.offsetHeight - HEADER_HEIGHT - logsHeight,
        width: '100%',
        height: logsHeight
      }}
      onResize={(_, __, ref) => {
        setLogsHeight(ref.offsetHeight);
      }}
    >
      <Box h={`${logsHeight}px`} overflowY={'hidden'} bg="darkWhite">
        <FlexDivider withDots sx={{bg: 'bg'}} />

        <Grid gridTemplateColumns={'200px 1fr'}>
          <GridItem borderRight={'1px solid'} borderColor={'border'}>
            <Box overflowY={'auto'} h={`${logsHeight - 8}px`}>
              {hasValue(activeNumber) && (
                <JournalPointList
                  points={journalPoints}
                  activeNumber={activeNumber}
                  onSelectNumber={setActiveNumber}
                />
              )}
            </Box>
          </GridItem>
          <GridItem>
            <JournalTabs
              filterType={filterType}
              onSelectFilterType={setFilterType}
              onClose={onClose}
            />
            <Box overflowY={'auto'} h={`${logsHeight - DIVIDER_HEIGHT - FILTER_HEIGHT}px`}>
              <JournalPointItems items={filteredItems} />
            </Box>
          </GridItem>
        </Grid>
      </Box>
    </Rnd>
  );
};

export const JournalViewer = observer(JournalViewerFC);
