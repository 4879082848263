/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {CalculationResultWidget} from './CalculationResultWidget';
import {
  CalculationResultWidgetFromJSON,
  CalculationResultWidgetFromJSONTyped,
  CalculationResultWidgetToJSON
} from './CalculationResultWidget';
import type {ElementClassName} from './ElementClassName';
import {
  ElementClassNameFromJSON,
  ElementClassNameFromJSONTyped,
  ElementClassNameToJSON
} from './ElementClassName';

/**
 *
 * @export
 * @interface TechprocessCalculationResult
 */
export interface TechprocessCalculationResult {
  /**
   *
   * @type {string}
   * @memberof TechprocessCalculationResult
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof TechprocessCalculationResult
   */
  elementUuid: string;
  /**
   *
   * @type {ElementClassName}
   * @memberof TechprocessCalculationResult
   */
  elementType: ElementClassName;
  /**
   *
   * @type {string}
   * @memberof TechprocessCalculationResult
   */
  name: string;
  /**
   *
   * @type {CalculationResultWidget}
   * @memberof TechprocessCalculationResult
   */
  widget: CalculationResultWidget;
}

/**
 * Check if a given object implements the TechprocessCalculationResult interface.
 */
export function instanceOfTechprocessCalculationResult(
  value: object
): value is TechprocessCalculationResult {
  if (!('uuid' in value) || value['uuid'] === undefined) return false;
  if (!('elementUuid' in value) || value['elementUuid'] === undefined) return false;
  if (!('elementType' in value) || value['elementType'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('widget' in value) || value['widget'] === undefined) return false;
  return true;
}

export function TechprocessCalculationResultFromJSON(json: any): TechprocessCalculationResult {
  return TechprocessCalculationResultFromJSONTyped(json, false);
}

export function TechprocessCalculationResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TechprocessCalculationResult {
  if (json == null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    elementUuid: json['element_uuid'],
    elementType: ElementClassNameFromJSON(json['element_type']),
    name: json['name'],
    widget: CalculationResultWidgetFromJSON(json['widget'])
  };
}

export function TechprocessCalculationResultToJSON(
  value?: TechprocessCalculationResult | null
): any {
  if (value == null) {
    return value;
  }
  return {
    uuid: value['uuid'],
    element_uuid: value['elementUuid'],
    element_type: ElementClassNameToJSON(value['elementType']),
    name: value['name'],
    widget: CalculationResultWidgetToJSON(value['widget'])
  };
}
