/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {PositionByUuid} from './PositionByUuid';
import {
  PositionByUuidFromJSON,
  PositionByUuidFromJSONTyped,
  PositionByUuidToJSON
} from './PositionByUuid';

/**
 *
 * @export
 * @interface PositionsInfo
 */
export interface PositionsInfo {
  /**
   *
   * @type {Array<PositionByUuid>}
   * @memberof PositionsInfo
   */
  positions: Array<PositionByUuid>;
  /**
   *
   * @type {string}
   * @memberof PositionsInfo
   */
  subSchemeUuid?: string | null;
}

/**
 * Check if a given object implements the PositionsInfo interface.
 */
export function instanceOfPositionsInfo(value: object): value is PositionsInfo {
  if (!('positions' in value) || value['positions'] === undefined) return false;
  return true;
}

export function PositionsInfoFromJSON(json: any): PositionsInfo {
  return PositionsInfoFromJSONTyped(json, false);
}

export function PositionsInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PositionsInfo {
  if (json == null) {
    return json;
  }
  return {
    positions: (json['positions'] as Array<any>).map(PositionByUuidFromJSON),
    subSchemeUuid: json['sub_scheme_uuid'] == null ? undefined : json['sub_scheme_uuid']
  };
}

export function PositionsInfoToJSON(value?: PositionsInfo | null): any {
  if (value == null) {
    return value;
  }
  return {
    positions: (value['positions'] as Array<any>).map(PositionByUuidToJSON),
    sub_scheme_uuid: value['subSchemeUuid']
  };
}
