import {FC, ReactNode, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {JsFormConfigContext, TJsFormConfigContext} from '@progress-fe/ui-kit';

import {useStore} from 'core';

const ProjectJsFormConfigContextFC: FC<{children: ReactNode}> = ({children}) => {
  const {techProcessStore, samplesAndBlendsStore, catalogsStore, favoriteComponents} = useStore();
  const {addComponentsToFavorite, removeComponentsFromFavorite, projectSettings} = techProcessStore;
  const {pureComponents, componentsDictionary} = catalogsStore;
  const {sampleDictionary} = samplesAndBlendsStore;
  const {pseudoComponents} = projectSettings;
  const {runCallback} = techProcessStore;

  const value: TJsFormConfigContext = useMemo(
    () => ({
      components: {
        favorites: favoriteComponents,
        pureList: pureComponents,
        pseudoList: pseudoComponents,
        dictionary: componentsDictionary
      },
      samples: sampleDictionary,
      addToFavorites: addComponentsToFavorite,
      removeFromFavorites: removeComponentsFromFavorite,
      runCallback: runCallback
    }),
    [
      addComponentsToFavorite,
      componentsDictionary,
      favoriteComponents,
      pureComponents,
      pseudoComponents,
      removeComponentsFromFavorite,
      runCallback,
      sampleDictionary
    ]
  );

  return <JsFormConfigContext.Provider value={value}>{children}</JsFormConfigContext.Provider>;
};

export const ProjectJsFormConfigContext = observer(ProjectJsFormConfigContextFC);
