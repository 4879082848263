/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  CheckpointInUpdate,
  CheckpointOut,
  CheckpointsInfoOut,
  HTTPValidationError,
  JournalTaskRunPoint,
  JournalTaskRunPointEnum,
  ProfilerMode,
  ResponseStatusCheckpointOut
} from '../models/index';
import {
  CheckpointInUpdateFromJSON,
  CheckpointInUpdateToJSON,
  CheckpointOutFromJSON,
  CheckpointOutToJSON,
  CheckpointsInfoOutFromJSON,
  CheckpointsInfoOutToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  JournalTaskRunPointFromJSON,
  JournalTaskRunPointToJSON,
  JournalTaskRunPointEnumFromJSON,
  JournalTaskRunPointEnumToJSON,
  ProfilerModeFromJSON,
  ProfilerModeToJSON,
  ResponseStatusCheckpointOutFromJSON,
  ResponseStatusCheckpointOutToJSON
} from '../models/index';

export interface CheckpointsCreateCheckpointRequest {
  projectUuid: string;
  fromCheckpoint: string;
  idempotencyKey: string;
  xProfilerMode?: ProfilerMode;
}

export interface CheckpointsDeleteCheckpointRequest {
  projectUuid: string;
  checkpointUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface CheckpointsGetCheckpointRequest {
  projectUuid: string;
  checkpointUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface CheckpointsGetJournalRequest {
  projectUuid: string;
  checkpointUuid: string;
  orderByField?: JournalTaskRunPointEnum | null;
  orderBy?: CheckpointsGetJournalOrderByEnum;
  xProfilerMode?: ProfilerMode;
}

export interface CheckpointsGetJournal0Request {
  projectUuid: string;
  checkpointUuid: string;
  orderByField?: JournalTaskRunPointEnum | null;
  orderBy?: CheckpointsGetJournal0OrderByEnum;
  xProfilerMode?: ProfilerMode;
}

export interface CheckpointsGetProjectCheckpointsRequest {
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface CheckpointsUpdateCheckpointRequest {
  projectUuid: string;
  checkpointUuid: string;
  checkpointInUpdate: CheckpointInUpdate;
  xProfilerMode?: ProfilerMode;
}

/**
 *
 */
export class CheckpointsApi extends runtime.BaseAPI {
  /**
   * Create Checkpoint
   */
  async checkpointsCreateCheckpointRaw(
    requestParameters: CheckpointsCreateCheckpointRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ResponseStatusCheckpointOut>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling checkpointsCreateCheckpoint().'
      );
    }

    if (requestParameters['fromCheckpoint'] == null) {
      throw new runtime.RequiredError(
        'fromCheckpoint',
        'Required parameter "fromCheckpoint" was null or undefined when calling checkpointsCreateCheckpoint().'
      );
    }

    if (requestParameters['idempotencyKey'] == null) {
      throw new runtime.RequiredError(
        'idempotencyKey',
        'Required parameter "idempotencyKey" was null or undefined when calling checkpointsCreateCheckpoint().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['fromCheckpoint'] != null) {
      queryParameters['fromCheckpoint'] = requestParameters['fromCheckpoint'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(requestParameters['idempotencyKey']);
    }

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints`.replace(
          `{${'project_uuid'}}`,
          encodeURIComponent(String(requestParameters['projectUuid']))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResponseStatusCheckpointOutFromJSON(jsonValue)
    );
  }

  /**
   * Create Checkpoint
   */
  async checkpointsCreateCheckpoint(
    requestParameters: CheckpointsCreateCheckpointRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ResponseStatusCheckpointOut> {
    const response = await this.checkpointsCreateCheckpointRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete Checkpoint
   */
  async checkpointsDeleteCheckpointRaw(
    requestParameters: CheckpointsDeleteCheckpointRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling checkpointsDeleteCheckpoint().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling checkpointsDeleteCheckpoint().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete Checkpoint
   */
  async checkpointsDeleteCheckpoint(
    requestParameters: CheckpointsDeleteCheckpointRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.checkpointsDeleteCheckpointRaw(requestParameters, initOverrides);
  }

  /**
   * Get Checkpoint
   */
  async checkpointsGetCheckpointRaw(
    requestParameters: CheckpointsGetCheckpointRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CheckpointOut>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling checkpointsGetCheckpoint().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling checkpointsGetCheckpoint().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CheckpointOutFromJSON(jsonValue));
  }

  /**
   * Get Checkpoint
   */
  async checkpointsGetCheckpoint(
    requestParameters: CheckpointsGetCheckpointRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CheckpointOut> {
    const response = await this.checkpointsGetCheckpointRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Получение журнала, сгруппированного по времени запуска заданий на расчёт
   * Get Journal
   */
  async checkpointsGetJournalRaw(
    requestParameters: CheckpointsGetJournalRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<JournalTaskRunPoint>>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling checkpointsGetJournal().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling checkpointsGetJournal().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['orderByField'] != null) {
      queryParameters['orderByField'] = requestParameters['orderByField'];
    }

    if (requestParameters['orderBy'] != null) {
      queryParameters['orderBy'] = requestParameters['orderBy'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/journal`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(JournalTaskRunPointFromJSON)
    );
  }

  /**
   * Получение журнала, сгруппированного по времени запуска заданий на расчёт
   * Get Journal
   */
  async checkpointsGetJournal(
    requestParameters: CheckpointsGetJournalRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<JournalTaskRunPoint>> {
    const response = await this.checkpointsGetJournalRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Получение журнала, сгруппированного по времени запуска заданий на расчёт
   * Get Journal
   * @deprecated
   */
  async checkpointsGetJournal_1Raw(
    requestParameters: CheckpointsGetJournal0Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<JournalTaskRunPoint>>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling checkpointsGetJournal_1().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling checkpointsGetJournal_1().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['orderByField'] != null) {
      queryParameters['orderByField'] = requestParameters['orderByField'];
    }

    if (requestParameters['orderBy'] != null) {
      queryParameters['orderBy'] = requestParameters['orderBy'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/techprocess/journal`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(JournalTaskRunPointFromJSON)
    );
  }

  /**
   * Получение журнала, сгруппированного по времени запуска заданий на расчёт
   * Get Journal
   * @deprecated
   */
  async checkpointsGetJournal_1(
    requestParameters: CheckpointsGetJournal0Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<JournalTaskRunPoint>> {
    const response = await this.checkpointsGetJournal_1Raw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Project Checkpoints
   */
  async checkpointsGetProjectCheckpointsRaw(
    requestParameters: CheckpointsGetProjectCheckpointsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CheckpointsInfoOut>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling checkpointsGetProjectCheckpoints().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints`.replace(
          `{${'project_uuid'}}`,
          encodeURIComponent(String(requestParameters['projectUuid']))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CheckpointsInfoOutFromJSON(jsonValue)
    );
  }

  /**
   * Get Project Checkpoints
   */
  async checkpointsGetProjectCheckpoints(
    requestParameters: CheckpointsGetProjectCheckpointsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CheckpointsInfoOut> {
    const response = await this.checkpointsGetProjectCheckpointsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update Checkpoint
   */
  async checkpointsUpdateCheckpointRaw(
    requestParameters: CheckpointsUpdateCheckpointRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ResponseStatusCheckpointOut>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling checkpointsUpdateCheckpoint().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling checkpointsUpdateCheckpoint().'
      );
    }

    if (requestParameters['checkpointInUpdate'] == null) {
      throw new runtime.RequiredError(
        'checkpointInUpdate',
        'Required parameter "checkpointInUpdate" was null or undefined when calling checkpointsUpdateCheckpoint().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: CheckpointInUpdateToJSON(requestParameters['checkpointInUpdate'])
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResponseStatusCheckpointOutFromJSON(jsonValue)
    );
  }

  /**
   * Update Checkpoint
   */
  async checkpointsUpdateCheckpoint(
    requestParameters: CheckpointsUpdateCheckpointRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ResponseStatusCheckpointOut> {
    const response = await this.checkpointsUpdateCheckpointRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

/**
 * @export
 */
export const CheckpointsGetJournalOrderByEnum = {
  Asc: 'asc',
  Desc: 'desc'
} as const;
export type CheckpointsGetJournalOrderByEnum =
  (typeof CheckpointsGetJournalOrderByEnum)[keyof typeof CheckpointsGetJournalOrderByEnum];
/**
 * @export
 */
export const CheckpointsGetJournal0OrderByEnum = {
  Asc: 'asc',
  Desc: 'desc'
} as const;
export type CheckpointsGetJournal0OrderByEnum =
  (typeof CheckpointsGetJournal0OrderByEnum)[keyof typeof CheckpointsGetJournal0OrderByEnum];
