import {EElement} from '@progress-fe/core';

import {TElementInfoSnapshotIn} from 'core/models';

export const ELEMENTS_SAMPLE_ELEMENTS_LIST: {
  projectId: string;
  projectName: string;
  items: TElementInfoSnapshotIn[];
}[] = [
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000000',
    projectName: 'Элементы (моковый проект)',
    items: [
      {
        uuid: '196C89E0-1111-0000-0000-000000000001',
        type: EElement.CokerElement,
        name: 'CORER-100',
        lastUpdated: new Date()
      },
      {
        uuid: '196C89E0-1111-0000-0000-000000000002',
        type: EElement.PumpElement,
        name: 'PUMP-100',
        lastUpdated: new Date()
      }
    ]
  }
];
