import {Instance, types} from 'mobx-state-tree';

import {ResetModel} from 'core/models';

const ProjectsFilter = types.compose(
  ResetModel,
  types.model('ProjectsFilter', {
    activeType: types.optional(types.string, 'All'),
    query: types.optional(types.string, '')
  })
);

export type TProjectsFilterModel = Instance<typeof ProjectsFilter>;

export {ProjectsFilter};
