import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {SystemStyleObject} from '@chakra-ui/styled-system';
import {VirtualTable, ISimpleTableColumn, ISimpleTableRow} from '@progress-fe/ui-kit';
import {useT, EComponentBaseType, IComponent} from '@progress-fe/core';

interface IProps {
  components: IComponent[];
  tableSx?: SystemStyleObject;
}

const SelectedComponentsFC: FC<IProps> = ({components, tableSx}) => {
  const {t} = useT();

  const columns: ISimpleTableColumn[] = [
    {name: t('fields.name'), align: 'left'},
    {name: t('fields.type'), align: 'left'},
    {name: t('fields.class'), align: 'left'}
  ];

  const pureRows: ISimpleTableRow<string>[] = components.map((component) => {
    return {
      key: component.uuid,
      values: [
        component.name,
        t(`enum.componentBaseType.${EComponentBaseType.PURE}`),
        component.family ? t(`enum.componentFamilyClass.${component.family}`) : '—'
      ]
    };
  });

  return (
    <VirtualTable
      columns={columns}
      rows={pureRows}
      noItemsTitle={t('common.noComponents')}
      tableSx={tableSx}
    />
  );
};

export const SelectedComponents = observer(SelectedComponentsFC);
