import {Instance, SnapshotIn, types} from 'mobx-state-tree';

const AdditionalTab = types.model('AdditionalTab', {
  uniqueCode: types.string,
  name: types.string,
  isActive: types.boolean
});

export type TAdditionalTabModel = Instance<typeof AdditionalTab>;
export type TAdditionalTabSnapshotIn = SnapshotIn<typeof AdditionalTab>;

export {AdditionalTab};
