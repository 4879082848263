/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {CalculationStatusEnum} from './CalculationStatusEnum';
import {
  CalculationStatusEnumFromJSON,
  CalculationStatusEnumFromJSONTyped,
  CalculationStatusEnumToJSON
} from './CalculationStatusEnum';

/**
 *
 * @export
 * @interface CalculationTaskOut
 */
export interface CalculationTaskOut {
  /**
   *
   * @type {CalculationStatusEnum}
   * @memberof CalculationTaskOut
   */
  calcStatus: CalculationStatusEnum;
}

/**
 * Check if a given object implements the CalculationTaskOut interface.
 */
export function instanceOfCalculationTaskOut(value: object): value is CalculationTaskOut {
  if (!('calcStatus' in value) || value['calcStatus'] === undefined) return false;
  return true;
}

export function CalculationTaskOutFromJSON(json: any): CalculationTaskOut {
  return CalculationTaskOutFromJSONTyped(json, false);
}

export function CalculationTaskOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CalculationTaskOut {
  if (json == null) {
    return json;
  }
  return {
    calcStatus: CalculationStatusEnumFromJSON(json['calc_status'])
  };
}

export function CalculationTaskOutToJSON(value?: CalculationTaskOut | null): any {
  if (value == null) {
    return value;
  }
  return {
    calc_status: CalculationStatusEnumToJSON(value['calcStatus'])
  };
}
