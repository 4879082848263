import {FC} from 'react';
import {Box} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {EStructureItem, useT} from '@progress-fe/core';

import {useStore} from 'core/hooks';
import {BaseJsForms, ErrorBoundary} from 'ui-kit';

import {TaskForm, SettingsForms} from './components';

const ProjectFormsFC: FC = () => {
  const {techProcessStore} = useStore();
  const {projectTask, projectElements, projectModels, projectElementsResults} = techProcessStore;
  const {backToEntity, uiState, errors} = techProcessStore;
  const {entityType, entityId, subEntityId} = uiState;

  const {t} = useT();

  const getEntityToBack = () => {
    switch (entityType) {
      case EStructureItem.Element: {
        return projectElements.findElement(entityId) ?? null;
      }
      case EStructureItem.Model: {
        return projectModels.findModel(entityId) ?? null;
      }
      default: {
        return null;
      }
    }
  };

  const parentFormName = uiState.isSubEntitySelected ? getEntityToBack()?.name : null;

  return (
    <ErrorBoundary height={'100%'} msg={t('common.jsFormError')}>
      <Box position={'relative'} p={'12px 0'} h={'100%'}>
        {/* Settings forms */}
        {entityType === EStructureItem.Settings && <SettingsForms />}

        {/* Element forms */}
        {entityType === EStructureItem.Element && !!entityId && !subEntityId && (
          <BaseJsForms
            uuid={entityId}
            name={projectElements.findElement(entityId)?.name}
            jsonSchemas={projectElements.jsonForm?.jsonSchemas}
            additionalTabs={projectElements.jsonForm?.additionalTabs}
            parentFormName={parentFormName}
            errors={errors.filter((e) => e.entityUuid === entityId)}
            isLoading={projectElements.isFormLoading || projectElements.isTabsChanging}
            isUpdating={projectElements.isFormDataUpdating}
            onBackToForm={uiState.isSubEntitySelected ? backToEntity : undefined}
            onToggleTab={(code) => projectElements.toggleElementTab(entityId, code)}
            onUpdateFormData={async (uuid, jsonSchema, formData, tabCode) => {
              await techProcessStore.updateElementFormData(
                uuid,
                null,
                jsonSchema,
                formData,
                tabCode
              );
            }}
          />
        )}

        {/* Sub element forms */}
        {entityType === EStructureItem.Element && !!entityId && !!subEntityId && (
          <BaseJsForms
            uuid={subEntityId}
            name={projectElements.findSubElement(entityId, subEntityId)?.name}
            jsonSchemas={projectElements.jsonForm?.jsonSchemas}
            additionalTabs={projectElements.jsonForm?.additionalTabs}
            parentFormName={parentFormName}
            isLoading={projectElements.isFormLoading}
            isUpdating={projectElements.isFormDataUpdating}
            errors={errors.filter((e) => e.entityUuid === subEntityId)}
            onBackToForm={uiState.isSubEntitySelected ? backToEntity : undefined}
            onUpdateFormData={async (subUuid, jsonSchema, formData, tabCode) => {
              await techProcessStore.updateElementFormData(
                entityId,
                subUuid,
                jsonSchema,
                formData,
                tabCode
              );
            }}
          />
        )}

        {/* Model forms */}
        {entityType === EStructureItem.Model && !!entityId && !subEntityId && (
          <BaseJsForms
            uuid={entityId}
            name={projectModels.findModel(entityId)?.name}
            jsonSchemas={projectModels.jsonForm?.jsonSchemas}
            additionalTabs={projectModels.jsonForm?.additionalTabs}
            parentFormName={parentFormName}
            isLoading={projectModels.isFormLoading}
            isUpdating={projectModels.isFormDataUpdating}
            errors={errors.filter((e) => e.entityUuid === entityId)}
            onBackToForm={uiState.isSubEntitySelected ? backToEntity : undefined}
            onUpdateFormData={async (uuid, jsonSchema, formData) => {
              await techProcessStore.updateModelFormData(uuid, null, jsonSchema, formData);
            }}
          />
        )}

        {/* Sub model forms */}
        {entityType === EStructureItem.Model && !!entityId && !!subEntityId && (
          <BaseJsForms
            uuid={subEntityId}
            name={projectModels.findSubModel(entityId, subEntityId)?.name}
            jsonSchemas={projectModels.jsonForm?.jsonSchemas}
            additionalTabs={projectModels.jsonForm?.additionalTabs}
            parentFormName={parentFormName}
            isLoading={projectModels.isFormLoading}
            isUpdating={projectModels.isFormDataUpdating}
            errors={errors.filter((e) => e.entityUuid === subEntityId)}
            onBackToForm={uiState.isSubEntitySelected ? backToEntity : undefined}
            onUpdateFormData={async (subUuid, jsonSchema, formData) => {
              await techProcessStore.updateModelFormData(entityId, subUuid, jsonSchema, formData);
            }}
          />
        )}

        {/* Task form */}
        {entityType === EStructureItem.Task && !entityId && <TaskForm taskInstance={projectTask} />}

        {/* Logical element forms */}
        {entityType === EStructureItem.Task && !!entityId && (
          <BaseJsForms
            uuid={entityId}
            name={projectTask.findLogicalElement(entityId)?.name}
            jsonSchemas={projectTask.jsonForm?.jsonSchemas}
            additionalTabs={projectTask.jsonForm?.additionalTabs}
            parentFormName={parentFormName}
            isLoading={projectTask.isFormLoading}
            isUpdating={projectTask.isFormDataUpdating}
            onBackToForm={uiState.isSubEntitySelected ? backToEntity : undefined}
            onUpdateFormData={techProcessStore.updateLogicalElementFormData}
          />
        )}

        {/* Result forms */}
        {entityType === EStructureItem.ElementResult && !!entityId && (
          <BaseJsForms
            uuid={entityId}
            name={projectElementsResults.findElementResult(entityId)?.name}
            jsonSchemas={projectElementsResults.jsonForm?.jsonSchemas}
            additionalTabs={projectElementsResults.jsonForm?.additionalTabs}
            parentFormName={parentFormName}
            isLoading={projectElementsResults.isFormLoading}
            isUpdating={projectElementsResults.isFormDataUpdating}
            onBackToForm={uiState.isSubEntitySelected ? backToEntity : undefined}
            onUpdateFormData={techProcessStore.updateElementResultFormData}
          />
        )}
      </Box>
    </ErrorBoundary>
  );
};

export const ProjectForms = observer(ProjectFormsFC);
