/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {Position} from './Position';
import {PositionFromJSON, PositionFromJSONTyped, PositionToJSON} from './Position';

/**
 *
 * @export
 * @interface PositionByUuid
 */
export interface PositionByUuid {
  /**
   *
   * @type {string}
   * @memberof PositionByUuid
   */
  uuid: string;
  /**
   *
   * @type {Position}
   * @memberof PositionByUuid
   */
  position: Position;
}

/**
 * Check if a given object implements the PositionByUuid interface.
 */
export function instanceOfPositionByUuid(value: object): value is PositionByUuid {
  if (!('uuid' in value) || value['uuid'] === undefined) return false;
  if (!('position' in value) || value['position'] === undefined) return false;
  return true;
}

export function PositionByUuidFromJSON(json: any): PositionByUuid {
  return PositionByUuidFromJSONTyped(json, false);
}

export function PositionByUuidFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PositionByUuid {
  if (json == null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    position: PositionFromJSON(json['position'])
  };
}

export function PositionByUuidToJSON(value?: PositionByUuid | null): any {
  if (value == null) {
    return value;
  }
  return {
    uuid: value['uuid'],
    position: PositionToJSON(value['position'])
  };
}
