import {applySnapshot, cast, flow, types} from 'mobx-state-tree';
import {IRFNodePort, TRFWorkZone} from '@progress-fe/rf-core';
import {EElement} from '@progress-fe/core';
import {XYPosition} from '@xyflow/react';
import {v4 as uuidv4} from 'uuid';

import {IEntityToRefresh, INodeXYPosition} from 'core/interfaces';
import {
  WorkZone,
  RJSFSchemas,
  ElementsOut,
  TechProcessApi,
  ElementClassName,
  ElementUpdateOut,
  CreateElementActionResult,
  FullElementSchemas
} from 'api';
import {
  Workzone,
  ResetModel,
  RequestModel,
  ElementInfo,
  TEntityBaseInfoModel,
  TElementInfoModel,
  JsonSchemaForm
} from 'core/models';
import {
  ELEMENTS_LIST,
  WORKZONES_LIST,
  SUB_WORKZONES_LIST
} from 'core/mocks/projects/projects.mocks';

const ProjectElements = types
  .compose(
    ResetModel,
    types.model('ProjectElements', {
      projectUuid: '',
      checkpointUuid: '',

      elements: types.optional(types.array(ElementInfo), []),
      jsonForm: types.maybeNull(JsonSchemaForm),

      workzone: types.optional(Workzone, {}),
      subWorkzone: types.maybeNull(Workzone),

      fetchRequest: types.optional(RequestModel, {}),
      positionRequest: types.optional(RequestModel, {}),
      duplicateRequest: types.optional(RequestModel, {}),
      createRequest: types.optional(RequestModel, {}),
      deleteRequest: types.optional(RequestModel, {}),
      disconnectRequest: types.optional(RequestModel, {}),
      connectRequest: types.optional(RequestModel, {})
    })
  )
  .actions((self) => ({
    hasElement(uuid: string): boolean {
      return self.elements.some((e) => e.uuid === uuid);
    },
    findElement(uuid: string): TElementInfoModel | undefined {
      return self.elements.find((e) => e.uuid === uuid);
    },
    findSubElement(uuid: string, subUuid: string): TEntityBaseInfoModel | undefined {
      const element = self.elements.find((e) => e.uuid === uuid);
      return element?.subElements.find((e) => e.uuid === subUuid);
    }
  }))
  .actions((self) => ({
    _clearJsonForm() {
      self.jsonForm?.clear();
      self.jsonForm = null;
    },
    _reloadJsonForm: flow(function* (uuid: string, subUuid?: string | null) {
      self.jsonForm = JsonSchemaForm.create({entityUuid: subUuid ?? uuid});
      const response: FullElementSchemas = yield self.jsonForm.fetchRequest.send(
        TechProcessApi.techProcessGetElementSchemas.bind(TechProcessApi),
        {
          elementUuid: subUuid ?? uuid,
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (response) {
        self.jsonForm.setJsonSchemas(response.tabs);
        self.jsonForm.setAdditionalTabs(response.additionalTabs);
      }
    })
  }))
  .actions((self) => ({
    _loadElements: flow(function* () {
      const mockElements = ELEMENTS_LIST.find((m) => m.projectId === self.projectUuid)?.items || [];
      if (mockElements.length) {
        self.elements = cast(mockElements);
        return;
      }

      const response: ElementsOut = yield self.fetchRequest.send(
        TechProcessApi.techProcessGetElements.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      const responseElements = response.elements.map((el) => ({
        uuid: el.uuid,
        name: el.name,
        lastUpdated: new Date(),
        type: el.type as EElement,
        deletable: el.deletable,
        subElements:
          el.subElements?.map((sel) => ({
            uuid: sel.uuid,
            name: sel.name,
            lastUpdated: new Date(),
            type: sel.type as EElement,
            deletable: sel.deletable
          })) || []
      }));

      applySnapshot(self.elements, responseElements);
    }),
    _loadWorkZone: flow(function* () {
      const mockWorkZone = WORKZONES_LIST.find((d) => d.projectId === self.projectUuid);
      if (mockWorkZone) {
        self.workzone.nodes = cast(mockWorkZone.nodes);
        self.workzone.edges = cast(mockWorkZone.edges);
        return;
      }

      const response: WorkZone = yield self.workzone.fetchRequest.send(
        TechProcessApi.techProcessGetWorkZone.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (response) {
        const workZone = response as TRFWorkZone;
        self.workzone.nodes = cast(workZone.nodes);
        self.workzone.edges = cast(workZone.edges);
      }
    })
  }))
  .actions((self) => ({
    _loadSubWorkZone: flow(function* (elementUuid: string) {
      if (self.subWorkzone?.parentUuid === elementUuid) {
        return;
      }

      self.subWorkzone = Workzone.create();

      const mockSubWorkZone = SUB_WORKZONES_LIST.find(
        (w) => w.projectUuid === self.projectUuid && w.elementUuid === elementUuid
      );

      if (mockSubWorkZone) {
        self.subWorkzone.parentUuid = elementUuid;
        self.subWorkzone.nodes = cast(mockSubWorkZone.nodes);
        self.subWorkzone.edges = cast(mockSubWorkZone.edges);
        return;
      }

      const response: WorkZone = yield self.subWorkzone.fetchRequest.send(
        TechProcessApi.techProcessGetSubWorkZone.bind(TechProcessApi),
        {
          elementUuid: elementUuid,
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (response) {
        const workZone = response as TRFWorkZone;
        self.subWorkzone.parentUuid = elementUuid;
        self.subWorkzone.nodes = cast(workZone.nodes);
        self.subWorkzone.edges = cast(workZone.edges);
      }
    }),
    _clearSubWorkzone(): void {
      self.subWorkzone?.fetchRequest.cancel();
      self.subWorkzone = null;
    }
  }))
  .actions((self) => ({
    _reload: flow(function* () {
      yield self._loadElements();
      yield self._loadWorkZone();
    })
  }))
  .actions((self) => ({
    init: flow(function* (projectUuid: string, checkpointUuid: string) {
      self.resetModel();
      self.projectUuid = projectUuid;
      self.checkpointUuid = checkpointUuid;
      yield self._reload();
    })
  }))
  .actions((self) => ({
    add: flow(function* (elementType: EElement, position: XYPosition) {
      const response: CreateElementActionResult = yield self.createRequest.send(
        TechProcessApi.techProcessCreateElement.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          idempotencyKey: uuidv4(),
          newElement: {
            type: elementType as ElementClassName,
            position: position
          }
        }
      );

      if (self.createRequest.isDone && !!response) {
        const newElement = ElementInfo.create({
          uuid: response.data.element.uuid,
          name: response.data.element.name,
          lastUpdated: new Date(),
          deletable: response.data.element.deletable,
          type: elementType,
          subElements:
            response.data.element.subElements?.map((sel) => ({
              uuid: sel.uuid,
              name: sel.name,
              lastUpdated: new Date(),
              type: sel.type as EElement
            })) || []
        });

        const entityToRefresh: IEntityToRefresh = {
          dataChanged: false,
          elementsChanged: false, // always false
          modelsChanged: response.data.modelsChanged,
          logicalElementsChanged: response.data.logicalElementsChanged
        };

        return {
          element: newElement,
          changedEntities: entityToRefresh
        };
      } else {
        return {
          element: null,
          changedEntities: null
        };
      }
    }),
    remove: flow(function* (uuids: Array<string>) {
      yield self.deleteRequest.send(
        TechProcessApi.techProcessDeleteElementsGroup.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          elementsToDeleteIn: {
            elements: uuids
          }
        }
      );

      return self.deleteRequest.isDone;
    }),
    duplicate: flow(function* (uuid: string) {
      const response: CreateElementActionResult = yield self.duplicateRequest.send(
        TechProcessApi.techProcessDuplicateElement.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          idempotencyKey: uuidv4(),
          elementUuid: uuid
        }
      );

      return response?.data.element.uuid;
    }),
    connect: flow(function* (from: IRFNodePort, to: IRFNodePort) {
      yield self.connectRequest.send(TechProcessApi.techProcessAddConnection.bind(TechProcessApi), {
        projectUuid: self.projectUuid,
        checkpointUuid: self.checkpointUuid,
        bodyTechProcessAddConnection: {
          sourceInfo: {
            elementUuid: from.uuid,
            portCode: from.port,
            portQualifier: from.qualifier
          },
          targetInfo: {
            elementUuid: to.uuid,
            portCode: to.port,
            portQualifier: to.qualifier
          }
        }
      });

      return self.connectRequest.isDone;
    }),
    disconnect: flow(function* (from: IRFNodePort, to: IRFNodePort) {
      yield self.disconnectRequest.send(
        TechProcessApi.techProcessRemoveConnection.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          bodyTechProcessRemoveConnection: {
            sourceInfo: {
              elementUuid: from.uuid,
              portCode: from.port,
              portQualifier: from.qualifier
            },
            targetInfo: {
              elementUuid: to.uuid,
              portCode: to.port,
              portQualifier: to.qualifier
            }
          }
        }
      );

      return self.disconnectRequest.isDone;
    })
  }))
  .actions((self) => ({
    _updateElementName(uuid: string, name: string) {
      const element = self.findElement(uuid);
      element?.setName(name);
    },
    _updateSubElementName(uuid: string, subUuid: string, name: string) {
      const subElement = self.findSubElement(uuid, subUuid);
      subElement?.setName(name);
    }
  }))
  .actions((self) => ({
    _updateElementFormData: flow(function* (
      uuid: string,
      subUuid: string | null,
      schemaId: string,
      data: unknown,
      tabCode?: string
    ) {
      const jsonSchema = self.jsonForm?.jsonSchemas.find((js) => js.id === schemaId);
      if (!self.jsonForm || !jsonSchema) {
        return {changedEntities: null};
      }

      jsonSchema.updateFormData(data);
      const response: ElementUpdateOut = yield self.jsonForm.updateRequest.send(
        TechProcessApi.techProcessUpdateElementInstance.bind(TechProcessApi),
        {
          elementUuid: subUuid ?? uuid,
          tabCode: tabCode,
          body: data as object,
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (!response) {
        return {changedEntities: null};
      }

      if (response.name && !subUuid) {
        self._updateElementName(uuid, response.name);
      }
      if (response.name && subUuid) {
        self._updateSubElementName(uuid, subUuid, response.name);
      }
      if (response.schemas) {
        self.jsonForm?.setJsonSchemas(response.schemas);
      }
      if (response.workzone && !subUuid) {
        self.workzone.setNodesAndEdges(response.workzone as TRFWorkZone);
      }
      if (response.workzone && subUuid && self.subWorkzone) {
        self.subWorkzone.setNodesAndEdges(response.workzone as TRFWorkZone);
      }

      const entityToRefresh: IEntityToRefresh = {
        dataChanged: response.dataChanged,
        modelsChanged: response.modelsChanged,
        elementsChanged: response.elementsChanged,
        logicalElementsChanged: response.logicalElementsChanged
      };

      return {
        changedEntities: entityToRefresh
      };
    })
  }))
  .actions((self) => ({
    updateNodesPositions: flow(function* (positions: Array<INodeXYPosition>) {
      self.workzone.nodes = cast(
        self.workzone.nodes.map((n) => {
          const item = positions.find((p) => p.uuid === n.id);
          return item ? {...n, position: item.position} : n;
        })
      );

      yield self.positionRequest.send(
        TechProcessApi.techProcessUpdateElementInstancesGroupPosition.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          positionsInfo: {
            positions: positions
          }
        }
      );
    }),
    updateSubNodesPositions: flow(function* (parentUuid: string, positions: INodeXYPosition[]) {
      if (self.subWorkzone) {
        self.subWorkzone.nodes = cast(
          self.subWorkzone.nodes.map((n) => {
            const item = positions.find((p) => p.uuid === n.id);
            return item ? {...n, position: item.position} : n;
          })
        );

        yield self.positionRequest.send(
          TechProcessApi.techProcessUpdateElementInstancesGroupPosition.bind(TechProcessApi),
          {
            projectUuid: self.projectUuid,
            checkpointUuid: self.checkpointUuid,
            positionsInfo: {
              positions: positions,
              subSchemeUuid: parentUuid
            }
          }
        );
      }
    })
  }))
  .actions((self) => ({
    toggleElementTab: flow(function* (elementUuid: string, tabUniqueCode: string) {
      console.info('[Store]: Toggle element tab.');
      if (!self.jsonForm) {
        return;
      }

      self.jsonForm.toggleAdditionalTab(tabUniqueCode);
      const response: {[key: string]: RJSFSchemas} = yield self.jsonForm.changeTabRequest.send(
        TechProcessApi.techProcessSetElementAdditionalTabs.bind(TechProcessApi),
        {
          elementUuid: elementUuid,
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          additionalTabsInfo: {
            tabs:
              self.jsonForm?.additionalTabs
                .filter((at) => at.isActive)
                .map((at) => ({uniqueCode: at.uniqueCode})) ?? []
          }
        }
      );

      if (self.jsonForm.changeTabRequest.isDone && response) {
        self.jsonForm.setJsonSchemas(response);
      } else {
        self.jsonForm.toggleAdditionalTab(tabUniqueCode);
      }
    })
  }))
  .views((self) => ({
    get subWorkzoneElement() {
      const parentUuid = self.subWorkzone?.parentUuid;
      return parentUuid ? (self.findElement(parentUuid) ?? null) : null;
    }
  }))
  .views((self) => ({
    get isLoading(): boolean {
      return (
        self.fetchRequest.isPending ||
        self.createRequest.isPending ||
        self.deleteRequest.isPending ||
        self.duplicateRequest.isPending
      );
    },
    get isWorkzoneLoading(): boolean {
      return (
        self.workzone.fetchRequest.isPending || self.subWorkzone?.fetchRequest.isPending || false
      );
    },
    get isTabsChanging(): boolean {
      return self.jsonForm?.isTabsChanging ?? false;
    },
    get isFormLoading(): boolean {
      return self.jsonForm?.isFormLoading ?? false;
    },
    get isFormDataUpdating(): boolean {
      return self.jsonForm?.isFormDataUpdating ?? false;
    }
  }));

export {ProjectElements};
