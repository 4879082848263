import {FC, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, IconButton} from '@chakra-ui/react';
import {Svg} from '@progress-fe/ui-kit';

import {ECalculationStatus} from 'core/enums';

interface IProps {
  runStatus: ECalculationStatus | null;
  isDisabled: boolean;
  onClick?: () => void;
}

const StatusIconButtonFC: FC<IProps> = ({runStatus, isDisabled, onClick}) => {
  const variant: string = useMemo(() => {
    switch (runStatus) {
      case ECalculationStatus.CalcResultSuccess:
        return 'success';
      case ECalculationStatus.CalcResultPartialSuccess:
        return 'warning';
      case ECalculationStatus.CalcResultError:
        return 'danger';
      default:
        return 'ghost';
    }
  }, [runStatus]);

  const color: string = useMemo(() => {
    switch (runStatus) {
      case ECalculationStatus.CalcResultSuccess:
        return 'white';
      case ECalculationStatus.CalcResultPartialSuccess:
        return 'white';
      case ECalculationStatus.CalcResultError:
        return 'white';
      default:
        return 'black';
    }
  }, [runStatus]);

  return (
    <Box>
      <IconButton
        size="sm"
        aria-label=""
        isDisabled={isDisabled}
        variant={variant}
        onClick={onClick}
        icon={
          <Box color={color}>
            <Svg name="Error" />
          </Box>
        }
      />
    </Box>
  );
};

export const StatusIconButton = observer(StatusIconButtonFC);
