import {cast, flow, Instance, types} from 'mobx-state-tree';
import {IRFMenuItem, ElementIcon, ElementGroupIcon, RFElementByElement} from '@progress-fe/rf-core';
import {ISvgMenuItem} from '@progress-fe/ui-kit';
import {
  i18n,
  TFlowType,
  TPortType,
  IElement,
  EElement,
  EElementGroup,
  IElementGroup
} from '@progress-fe/core';

import {CatalogsApi, ElementGroup} from 'api';
import {RequestModel, ResetModel} from 'core/models';

const ElementsCatalog = types.compose(
  ResetModel,
  types
    .model('ElementsCatalog', {
      elements: types.optional(types.array(types.frozen<IElement>()), []),
      elementGroups: types.optional(types.array(types.frozen<IElementGroup>()), []),
      fetchRequest: types.optional(RequestModel, {})
    })
    .actions((self) => ({
      load: flow(function* () {
        const response: Array<ElementGroup> = yield self.fetchRequest.send(
          CatalogsApi.catalogsGetElementsCatalog.bind(CatalogsApi),
          {}
        );

        const elements: IElement[] = [];
        const elementGroups: IElementGroup[] = [];

        response.forEach((gr) => {
          elementGroups.push({
            name: gr.name,
            type: gr.type as EElementGroup
          });

          gr.elements.forEach((el) => {
            elements.push({
              type: el.type as EElement,
              groupType: gr.type as EElementGroup,
              isDisabled: el.isDisabled,
              templates: el.templates.map((t) => ({
                code: t.templateCode,
                ports: t.ports.map((p) => ({
                  code: p.code,
                  portType: p.type as TPortType,
                  flowType: p.flowType as TFlowType
                }))
              }))
            });
          });
        });

        self.elements = cast(elements);
        self.elementGroups = cast(elementGroups);
      })
    }))
    .views((self) => ({
      get availableGroups() {
        const excludeGroups = [EElementGroup.DebugGroup];
        return self.elementGroups.filter((eg) => !excludeGroups.includes(eg.type));
      },
      get rfMenuItems(): IRFMenuItem[] {
        const menuItems: IRFMenuItem[] = [];
        this.availableGroups.forEach((gr) => {
          const group: IRFMenuItem = {
            name: gr.name,
            icon: ElementGroupIcon[gr.type],
            isDraggable: false,
            items: []
          };

          self.elements.forEach((el) => {
            if (el.groupType === gr.type) {
              group.items?.push({
                icon: ElementIcon[el.type],
                type: RFElementByElement[el.type],
                name: i18n.t(`enum.element.long.${el.type}`),
                templateCode: el.templates ? el.templates[0]?.code || '' : '',
                isDisabled: el.isDisabled,
                isDraggable: true
              });
            }
          });

          menuItems.push(group);
        });

        return menuItems;
      },
      get svgMenuItems(): ISvgMenuItem<string>[] {
        const menuItems: ISvgMenuItem<string>[] = [];
        this.availableGroups.forEach((gr) => {
          const group: ISvgMenuItem<string> = {
            id: gr.type,
            name: gr.name,
            items: []
          };

          self.elements.forEach((el) => {
            if (el.groupType === gr.type) {
              group.items?.push({
                id: RFElementByElement[el.type] || '',
                name: i18n.t(`enum.element.long.${el.type}`),
                isDisabled: el.isDisabled
              });
            }
          });

          menuItems.push(group);
        });

        return menuItems;
      }
    }))
);

export type TElementsCatalogModel = Instance<typeof ElementsCatalog>;

export {ElementsCatalog};
