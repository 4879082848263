import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Center, Flex, Grid, Text} from '@chakra-ui/react';
import {RFRenderEmpty} from '@progress-fe/rf-core';
import {useT} from '@progress-fe/core';
import {
  ILineChartResult,
  IComplexTableResult,
  ComplexTableResult,
  LineChartResult
} from '@progress-fe/ui-kit';

import {EWidgetType} from 'core/enums';
import {IWidgetData} from 'core/interfaces';
import {ErrorBoundary, RFMenu} from 'ui-kit';

interface IProps {
  height: number;
  selectedEntityId?: string | null;
  widgetList?: IWidgetData[];
  onSelect?: (entityUuid: string) => void;
}

const RFWidgetsZoneFC: FC<IProps> = ({height, selectedEntityId, widgetList = [], onSelect}) => {
  const {t} = useT();

  return (
    <Grid width="100%" gridTemplateColumns="48px 1fr" height={height}>
      <RFMenu menuItems={[]} height={height} />

      <Box width="100%" height={height}>
        <RFRenderEmpty />
      </Box>

      <Box position="absolute" left="48px" right="0" top="0" bottom="0">
        <Box pt="70px">
          <Flex
            gap="16px"
            p="0 0 24px 0"
            overflowY="auto"
            alignItems="center"
            flexDirection="column"
            height="calc(var(--height-layout) - 70px)"
          >
            {widgetList.map((widget) => (
              <Box
                key={widget.uuid}
                border="1px solid"
                borderRadius="8px"
                width="90%"
                maxWidth="600px"
                borderColor={selectedEntityId === widget.entityUuid ? 'accent' : 'transparent'}
                onClick={() => onSelect?.(widget.entityUuid)}
              >
                <Box key={widget.uuid} border="1px solid" borderColor="transparent">
                  <Box
                    p="24px"
                    bg="white"
                    borderRadius="8px"
                    border="1px solid"
                    borderColor="border"
                  >
                    {/* NO DATA */}
                    {!widget.data ? (
                      <Center h="70px">
                        <Text>{t('common.noData')}</Text>
                      </Center>
                    ) : (
                      <>
                        {/* TABLE RESULTS VIEW */}
                        <ErrorBoundary height={'200px'} msg={t('widgets.tableDataInvalid')}>
                          {widget.type === EWidgetType.ComplexTable && widget.data && (
                            <ComplexTableResult
                              title={widget.name}
                              noItemsTitle={t('common.noResults')}
                              columnsConfig={(widget.data as IComplexTableResult).columnsConfig}
                              rowsConfig={(widget.data as IComplexTableResult).rowsConfig}
                              data={(widget.data as IComplexTableResult).data}
                            />
                          )}
                        </ErrorBoundary>

                        {/* CHART RESULTS VIEW */}
                        <ErrorBoundary height={'200px'} msg={t('widgets.chartDataInvalid')}>
                          {widget.type === EWidgetType.LineChart && widget.data && (
                            <LineChartResult data={widget.data as ILineChartResult} height={300} />
                          )}
                        </ErrorBoundary>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            ))}
          </Flex>
        </Box>
      </Box>
    </Grid>
  );
};

export const RFWidgetsZone = observer(RFWidgetsZoneFC);
