import {applySnapshot, flow, Instance, types} from 'mobx-state-tree';
import {EElement} from '@progress-fe/core';
import {v4 as uuidv4} from 'uuid';

import {
  ResetModel,
  ModelInfo,
  RequestModel,
  JsonSchemaForm,
  TEntityBaseInfoModel,
  TModelInfoModel
} from 'core/models';
import {
  ModelsOut,
  RJSFSchemas,
  TechProcessApi,
  DeleteModelPossibility,
  TechProcessActionResult,
  CreateModelActionResult,
  AppProjectsTechprocessSchemasUpdateOut
} from 'api';

const ProjectModels = types
  .compose(
    ResetModel,
    types.model('ProjectModels', {
      projectUuid: '',
      checkpointUuid: '',

      models: types.optional(types.array(ModelInfo), []),
      jsonForm: types.maybeNull(JsonSchemaForm),

      duplicateRequest: types.optional(RequestModel, {}),
      createRequest: types.optional(RequestModel, {}),
      deleteRequest: types.optional(RequestModel, {}),
      inUseRequest: types.optional(RequestModel, {}),
      fetchRequest: types.optional(RequestModel, {})
    })
  )
  .actions((self) => ({
    hasModel(modelId: string) {
      return self.models.some((m) => m.uuid === modelId);
    },
    findModel(uuid: string): TModelInfoModel | undefined {
      return self.models.find((e) => e.uuid === uuid);
    },
    findSubModel(uuid: string, subUuid: string): TEntityBaseInfoModel | undefined {
      const model = self.models.find((e) => e.uuid === uuid);
      return model?.subModels.find((e) => e.uuid === subUuid);
    }
  }))
  .actions((self) => ({
    _clearJsonForm() {
      self.jsonForm?.clear();
      self.jsonForm = null;
    },
    _reloadJsonForm: flow(function* (uuid: string, subUuid?: string | null) {
      self.jsonForm = JsonSchemaForm.create({entityUuid: subUuid ?? uuid});
      const response: {[key: string]: RJSFSchemas} = yield self.jsonForm.fetchRequest.send(
        TechProcessApi.techProcessGetModelSchemas.bind(TechProcessApi),
        {
          modelInstanceUuid: subUuid ?? uuid,
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (response) {
        self.jsonForm = JsonSchemaForm.create({entityUuid: subUuid ?? uuid});
        self.jsonForm.setJsonSchemas(response);
      }
    })
  }))
  .actions((self) => ({
    _reload: flow(function* () {
      const response: ModelsOut = yield self.fetchRequest.send(
        TechProcessApi.techProcessGetModels.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      const responseModels = response.models.map((m) => ({
        uuid: m.uuid,
        name: m.name,
        lastUpdated: new Date(),
        type: m.type as EElement,
        deletable: m.deletable,
        subModels:
          m.subModels?.map((sm) => ({
            uuid: sm.uuid,
            name: sm.name,
            lastUpdated: new Date(),
            type: sm.type as EElement,
            deletable: sm.deletable
          })) || []
      }));

      applySnapshot(self.models, responseModels);
    })
  }))
  .actions((self) => ({
    init: flow(function* (projectUuid: string, checkpointUuid: string) {
      self.projectUuid = projectUuid;
      self.checkpointUuid = checkpointUuid;
      yield self._reload();
    }),
    add: flow(function* (modelUuid: string) {
      const response: TechProcessActionResult = yield self.createRequest.send(
        TechProcessApi.techProcessCreateModel.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          idempotencyKey: uuidv4(),
          newModel: {
            uuid: modelUuid
          }
        }
      );

      return self.createRequest.isDone && !!response
        ? ModelInfo.create({
            uuid: response.data.uuid,
            name: response.data.name,
            lastUpdated: new Date(),
            type: response.data.type as EElement,
            deletable: response.data.deletable
          })
        : null;
    }),
    duplicate: flow(function* (uuid: string) {
      const response: CreateModelActionResult = yield self.duplicateRequest.send(
        TechProcessApi.techProcessDuplicateModelInstance.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          idempotencyKey: uuidv4(),
          modelInstanceUuid: uuid
        }
      );

      return response?.data.uuid;
    }),
    remove: flow(function* (uuid: string) {
      yield self.deleteRequest.send(
        TechProcessApi.techProcessDeleteModelInstance.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          modelInstanceUuid: uuid
        }
      );

      return self.deleteRequest.isDone;
    }),
    _updateModelName(uuid: string, name: string) {
      const model = self.models.find((n) => n.uuid === uuid);
      model?.setName(name);
    },
    _updateSubModelName(uuid: string, subUuid: string, name: string) {
      const subModel = self.findSubModel(uuid, subUuid);
      subModel?.setName(name);
    }
  }))
  .actions((self) => ({
    _updateModelFormData: flow(function* (
      uuid: string,
      subUuid: string | null,
      schemaId: string,
      data: unknown
    ) {
      const jsonSchema = self.jsonForm?.jsonSchemas.find((js) => js.id === schemaId);
      if (!self.jsonForm || !jsonSchema) {
        return;
      }

      jsonSchema.updateFormData(data);
      const response: AppProjectsTechprocessSchemasUpdateOut =
        yield self.jsonForm.updateRequest.send(
          TechProcessApi.techProcessUpdateModelInstance.bind(TechProcessApi),
          {
            modelInstanceUuid: uuid,
            body: data as object,
            projectUuid: self.projectUuid,
            checkpointUuid: self.checkpointUuid
          }
        );

      if (!response) {
        return;
      }

      if (response.name && !subUuid) {
        self._updateModelName(uuid, response.name);
      }
      if (response.name && subUuid) {
        self._updateSubModelName(uuid, subUuid, response.name);
      }
      if (response.schemas) {
        self.jsonForm?.setJsonSchemas(response.schemas);
      }
    })
  }))
  .actions((self) => ({
    getElementsInUse: flow(function* (uuid: string) {
      const response: DeleteModelPossibility = yield self.inUseRequest.send(
        TechProcessApi.techProcessCheckModelInstanceDeleteable.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          modelInstanceUuid: uuid
        }
      );

      return response.elementNames;
    })
  }))
  .views((self) => ({
    get isLoading(): boolean {
      return (
        self.fetchRequest.isPending ||
        self.createRequest.isPending ||
        self.deleteRequest.isPending ||
        self.duplicateRequest.isPending
      );
    },
    get isFormLoading(): boolean {
      return self.jsonForm?.isFormLoading ?? false;
    },
    get isFormDataUpdating(): boolean {
      return self.jsonForm?.isFormDataUpdating ?? false;
    }
  }));

export type TProjectModelsModel = Instance<typeof ProjectModels>;

export {ProjectModels};
