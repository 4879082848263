import {cast, flow, types} from 'mobx-state-tree';
import {delay, EStructureItem, ISampleInfo} from '@progress-fe/core';

import {OilApi, CalculationTaskRunStatus} from 'api';
import {ProjectBase, RUN_DELAY_MS} from 'core/models';

import {ProjectSamples, ProjectBlends, ProjectSamplesResults, ProjectBlendsResults} from './models';

const SamplesAndBlendsStore = types
  .compose(
    ProjectBase,
    types.model('SamplesAndBlendsStore', {
      projectSamples: types.optional(ProjectSamples, {}),
      projectSamplesResults: types.optional(ProjectSamplesResults, {}),
      projectBlends: types.optional(ProjectBlends, {}),
      projectBlendsResults: types.optional(ProjectBlendsResults, {})
    })
  )
  .views((self) => ({
    get isFormsUpdating(): boolean {
      return (
        self.projectSamples.isFormDataUpdating ||
        self.projectBlends.isFormDataUpdating ||
        self.projectSamplesResults.isFormDataUpdating ||
        self.projectBlendsResults.isFormDataUpdating
      );
    }
  }))
  .actions((self) => ({
    _clearAllJsonForms(): void {
      self.projectSamples._clearJsonForm();
      self.projectBlends._clearJsonForm();
      self.projectSamplesResults._clearJsonForm();
      self.projectBlendsResults._clearJsonForm();
    },
    // This is called during first load or after running
    _reloadActiveEntity(): void {
      this._clearAllJsonForms();

      const {entityId} = self.uiState;

      if (self.uiState.entityType === EStructureItem.Sample) {
        self.projectSamples._reloadJsonForm(entityId).then();
      } else if (self.uiState.entityType === EStructureItem.Blend) {
        self.projectBlends._reloadJsonForm(entityId).then();
      } else if (self.uiState.entityType === EStructureItem.SampleResult) {
        self.projectSamplesResults._reloadJsonForm(entityId).then();
      } else if (self.uiState.entityType === EStructureItem.BlendResult) {
        self.projectBlendsResults._reloadJsonForm(entityId).then();
      }
    },
    // This is called by a structure item
    selectEntityByType(entityType: EStructureItem, uuid?: string | null, subUuid?: string | null) {
      this._clearAllJsonForms();
      self.uiState.select(entityType, uuid, subUuid);

      if (entityType === EStructureItem.Sample && !!uuid) {
        self.projectSamples._reloadJsonForm(uuid).then();
      } else if (entityType === EStructureItem.Blend && !!uuid) {
        self.projectBlends._reloadJsonForm(uuid).then();
      } else if (entityType === EStructureItem.BlendResult && !!uuid) {
        self.projectBlendsResults._reloadJsonForm(uuid).then();
      } else if (entityType === EStructureItem.SampleResult && !!uuid) {
        self.projectSamplesResults._reloadJsonForm(uuid).then();
      }

      self.uiState._setTabIndex(0);
      self._saveUiState();
    }
  }))
  .actions((self) => ({
    uninitialize() {
      self._clearIntervals();
      self.resetModel();
    }
  }))
  .actions((self) => ({
    createSample: flow(function* () {
      const sample = yield self.projectSamples.addSample();
      if (sample) {
        self.projectSamples.samples.push(sample);
        self.selectEntityByType(EStructureItem.Sample, sample.uuid);
        return sample.uuid;
      } else {
        return null;
      }
    }),
    deleteSample: flow(function* (uuid: string) {
      const isDone = yield self.projectSamples.removeSample(uuid);
      if (isDone) {
        const {samples} = self.projectSamples;
        self.projectSamples.samples = cast([...samples.filter((n) => n.uuid !== uuid)]);
        self.selectEntityByType(EStructureItem.Settings);
      }
    }),
    async updateSampleFormData(uuid: string, schemaId: string, data: unknown) {
      await self.projectSamples._updateSampleFormData(uuid, schemaId, data);
    }
  }))
  .actions((self) => ({
    createBlend: flow(function* () {
      const blend = yield self.projectBlends.addBlend();
      if (blend) {
        self.projectBlends.blends.push(blend);
        self.selectEntityByType(EStructureItem.Blend, blend.uuid);
        return blend.uuid;
      } else {
        return null;
      }
    }),
    deleteBlend: flow(function* (uuid: string) {
      const isDone = yield self.projectBlends.removeBlend(uuid);
      if (isDone) {
        const {blends} = self.projectBlends;
        self.projectBlends.blends = cast([...blends.filter((n) => n.uuid !== uuid)]);
        self.selectEntityByType(EStructureItem.Settings);
      }
    }),
    async updateBlendFormData(uuid: string, schemaId: string, data: unknown) {
      await self.projectBlends._updateBlendFormData(uuid, schemaId, data);
    }
  }))
  .actions((self) => ({
    async updateSampleResultFormData(uuid: string, schemaId: string, data: unknown) {
      await self.projectSamplesResults._updateSampleResultFormData(uuid, schemaId, data);
    },
    async updateBlendResultFormData(uuid: string, schemaId: string, data: unknown) {
      await self.projectBlendsResults._updateBlendResultFormData(uuid, schemaId, data);
    }
  }))
  .actions((self) => ({
    _executeRun: flow(function* () {
      if (self.runRequest.isPending) return;

      console.info('[Store]: Calculation in progress...');
      const response: CalculationTaskRunStatus = yield self.runRequest.send(
        OilApi.oilRunOilCalculationTask.bind(OilApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      self._setRunResults(response ?? null);
      self._reloadActiveEntity();

      yield self.projectSamplesResults._reload();
      yield self.projectBlendsResults._reload();
      yield self._loadJournal();

      console.info('[Store]: Calculation finished.');
      self.isRunning = false;
    })
  }))
  .actions((self) => {
    const actions = {
      _tryRun: flow(function* () {
        self._runAttempt++;
        console.info(`[Store]: Calculation sample and blends attempt ${self._runAttempt}.`);
        if (!self.isFormsUpdating) {
          if (self._runWaiter) {
            clearInterval(self._runWaiter);
            self._runAttempt = 0;
          }
          yield self._executeRun();
        }
      }),
      run: flow(function* () {
        self.isRunning = true;
        yield delay(RUN_DELAY_MS);

        self._runWaiter = setInterval(() => {
          actions._tryRun().then();
        }, RUN_DELAY_MS);
      })
    };
    return actions;
  })
  .actions((self) => ({
    _reloadProjectData: flow(function* (projectUuid: string, checkpointUuid: string) {
      yield Promise.allSettled([
        self.projectSamples.init(projectUuid, checkpointUuid),
        self.projectBlends.init(projectUuid, checkpointUuid),
        self.projectSamplesResults.init(projectUuid, checkpointUuid),
        self.projectBlendsResults.init(projectUuid, checkpointUuid),
        self._loadJournal()
      ]);
    })
  }))
  .actions((self) => ({
    initProject: flow(function* (projectId: string, checkpointId: string) {
      self.isLoading = true;
      yield self._baseInit(projectId, checkpointId);

      if (self.projectInfo && self.checkpointUuid) {
        yield self._reloadProjectData(self.projectInfo.uuid, self.checkpointUuid);
        self._reloadActiveEntity();
      }

      self.isLoading = false;
    })
  }))
  .views((self) => ({
    get sampleDictionary(): ISampleInfo[] {
      return self.projectSamples.samples.map((s) => ({sampleId: s.uuid, sampleName: s.name}));
    }
  }));

export {SamplesAndBlendsStore};
