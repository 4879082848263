/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const PortType = {
  Target: 'target',
  Source: 'source'
} as const;
export type PortType = (typeof PortType)[keyof typeof PortType];

export function instanceOfPortType(value: any): boolean {
  for (const key in PortType) {
    if (Object.prototype.hasOwnProperty.call(PortType, key)) {
      if ((PortType as Record<string, PortType>)[key] === value) {
        return true;
      }
    }
  }
  return false;
}

export function PortTypeFromJSON(json: any): PortType {
  return PortTypeFromJSONTyped(json, false);
}

export function PortTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortType {
  return json as PortType;
}

export function PortTypeToJSON(value?: PortType | null): any {
  return value as any;
}
