import {FC, useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Button, Flex} from '@chakra-ui/react';
import {getTimeAgo} from '@progress-fe/core';
import {Svg} from '@progress-fe/ui-kit';

interface IProps {
  isDisabled: boolean;
  lastSaveDate: Date | null;
  onSave: () => void;
}

const REFRESH_TIME_MS = 5 * 1000;

const SaveIconButtonFC: FC<IProps> = ({isDisabled, lastSaveDate, onSave}) => {
  const [timeAgo, setTimeAgo] = useState<string | null>(null);
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!lastSaveDate) {
      setTimeAgo(null);
      return;
    }

    setTimeAgo(getTimeAgo(lastSaveDate, true, true));

    intervalIdRef.current = setInterval(() => {
      setTimeAgo(getTimeAgo(lastSaveDate, true, true));
    }, REFRESH_TIME_MS);

    return () => {
      if (intervalIdRef.current) {
        setTimeAgo(null);
        clearInterval(intervalIdRef.current);
      }
    };
  }, [lastSaveDate]);

  return (
    <Button
      size="sm"
      aria-label=""
      variant="ghost"
      leftIcon={<Svg name="Save" />}
      isDisabled={isDisabled}
      onClick={onSave}
    >
      <Flex
        gap="1px"
        p="2px 0 0"
        flexDirection="column"
        w={!lastSaveDate ? '70px' : '100px'}
        textAlign={!lastSaveDate ? 'center' : 'left'}
      >
        <Box lineHeight="12px">Сохранено</Box>
        {!!lastSaveDate && (
          <Box
            fontSize="10px"
            fontWeight={400}
            lineHeight="10px"
            color="contrastIcon"
            className="cut-text"
          >
            {timeAgo}
          </Box>
        )}
      </Flex>
    </Button>
  );
};

export const SaveIconButton = observer(SaveIconButtonFC);
