import {FC, useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Flex, Text} from '@chakra-ui/react';
import {getDateTimeString} from '@progress-fe/core';
import {Dot} from '@progress-fe/ui-kit';

import {ECalculationStatus} from 'core/enums';
import {IJournalPoint} from 'core/interfaces';

interface IProps {
  points: IJournalPoint[];
  activeNumber: number;
  onSelectNumber: (newActiveNumber: number) => void;
}

const JournalPointListFC: FC<IProps> = ({points, activeNumber, onSelectNumber}) => {
  const getColor = useCallback((point: IJournalPoint) => {
    switch (point.status) {
      case ECalculationStatus.CalcResultSuccess:
        return 'success';
      case ECalculationStatus.CalcResultPartialSuccess:
        return 'darkOrange';
      case ECalculationStatus.CalcResultError:
        return 'darkRed';
      default:
        return 'black';
    }
  }, []);

  const hasWarningDot = useCallback((point: IJournalPoint) => {
    return point.status === ECalculationStatus.CalcResultSuccess && point.isWarning;
  }, []);

  return (
    <Box>
      {points.map((point) => (
        <Flex
          gap={'4px'}
          p={'2px 4px'}
          alignItems={'center'}
          color={getColor(point)}
          key={point.calculationNumber}
          bg={activeNumber === point.calculationNumber ? 'buttonBg' : 'transparent'}
          _hover={{bg: 'buttonBg', cursor: 'pointer'}}
          onClick={() => onSelectNumber(point.calculationNumber)}
        >
          <Dot variant={hasWarningDot(point) ? 'warning' : 'transparent'} />
          <Text as={'span'} fontSize={'12px'} fontWeight={600} color={'inherit'}>
            №{point.calculationNumber}:{' '}
          </Text>
          {getDateTimeString(point.runAt)}
        </Flex>
      ))}
    </Box>
  );
};

export const JournalPointList = observer(JournalPointListFC);
