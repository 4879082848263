import {types, flow} from 'mobx-state-tree';
import {toastUtils} from '@progress-fe/ui-kit';
import {i18n} from '@progress-fe/core';

import {ResponseError} from '../../../../api';
import {ERequestState} from '../../../enums';
import {UserManager} from '../../../services/oidc.service';

const UNAUTHORIZED_STATUS = 401;
const BAD_FIELD_STATUS = 400;

/**
 * This is utility model that responsible for:
 *
 * - fetching data
 * - showing errors
 * - keeping a request state
 * - adding auth details to every request
 * - handling auth errors
 */
const RequestModel = types
  .model('RequestModel', {
    showError: true,
    requestErrorCode: types.maybeNull(types.number),
    state: types.maybeNull(types.enumeration(Object.values(ERequestState)))
  })
  .volatile<{_cancelController: AbortController | null}>(() => ({
    _cancelController: null
  }))
  .actions((self) => ({
    cancel(message?: string) {
      self._cancelController?.abort(message ?? '[REQUEST]: Cancelled.');
    },
    handleApiError(error: ResponseError) {
      self.requestErrorCode = error.response?.status || null;

      if (self.showError) {
        const msgTitle = i18n.t('error.server');
        const msgDefault = i18n.t('error.serverCode', {code: self.requestErrorCode});

        error.response
          .json()
          .then((json) => {
            toastUtils.error({title: msgTitle, message: json?.error_msg ?? msgDefault});
          })
          .catch(() => {
            toastUtils.error({title: msgTitle, message: msgDefault});
          });
      }

      if (error?.response?.status === BAD_FIELD_STATUS) {
        return error?.response;
      }

      if (error?.response?.status === UNAUTHORIZED_STATUS) {
        UserManager.signinRedirect();
      }

      return undefined;
    }
  }))
  .actions((self) => ({
    send: flow(function* send<T, R>(
      action: (options: T, request?: RequestInit) => Promise<R>,
      options: T,
      request?: RequestInit
    ) {
      try {
        if (self.state === ERequestState.Pending) {
          self.cancel('[REQUEST]: Cancelled. The same api called multiple times.');
        }

        self.state = ERequestState.Pending;
        self._cancelController = new AbortController();

        const response: R = yield action(options, {
          ...request,
          ...{signal: self._cancelController.signal}
        });

        self.state = ERequestState.Done;
        return response;
      } catch (error) {
        console.error(error instanceof Error ? error.message : error);

        /** handle errors **/
        if (error instanceof ResponseError) {
          self.state = ERequestState.Error;
          return self.handleApiError(error);
        } else {
          return undefined;
        }
      }
    })
  }))
  .views((self) => ({
    get isPending() {
      return self.state === ERequestState.Pending;
    },
    get isDone() {
      return self.state === ERequestState.Done;
    },
    get isError() {
      return self.state === ERequestState.Error;
    },
    get errorCode() {
      return self.requestErrorCode;
    }
  }));

export {RequestModel};
