import {FC} from 'react';
import {Box} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {EStructureItem, useT} from '@progress-fe/core';

import {useStore} from 'core/hooks';
import {BaseJsForms, ErrorBoundary} from 'ui-kit';

import {SettingsForms} from './components';

const ProjectFormsFC: FC = () => {
  const {samplesAndBlendsStore} = useStore();
  const {projectSamples, projectBlends, uiState} = samplesAndBlendsStore;
  const {projectSamplesResults, projectBlendsResults} = samplesAndBlendsStore;
  const {entityType, entityId} = uiState;

  const {t} = useT();

  return (
    <ErrorBoundary msg={t('common.jsFormError')}>
      <Box position={'relative'} p={'12px 0'} h={'100%'}>
        {/* SETTINGS FORMS */}
        {entityType === EStructureItem.Settings && <SettingsForms />}

        {/* SAMPLES FORMS */}
        {entityType === EStructureItem.Sample && !!entityId && (
          <BaseJsForms
            uuid={entityId}
            name={projectSamples.findSample(entityId)?.name}
            jsonSchemas={projectSamples.jsonForm?.jsonSchemas}
            additionalTabs={projectSamples.jsonForm?.additionalTabs}
            isLoading={projectSamples.isFormLoading}
            isUpdating={projectSamples.isFormDataUpdating}
            onUpdateFormData={samplesAndBlendsStore.updateSampleFormData}
          />
        )}

        {/* SAMPLES RESULT FORMS */}
        {entityType === EStructureItem.SampleResult && !!entityId && (
          <BaseJsForms
            uuid={entityId}
            name={projectSamplesResults.findSampleResult(entityId)?.name}
            jsonSchemas={projectSamplesResults.jsonForm?.jsonSchemas}
            additionalTabs={projectSamplesResults.jsonForm?.additionalTabs}
            isLoading={projectSamplesResults.isFormLoading}
            isUpdating={projectSamplesResults.isFormDataUpdating}
            onUpdateFormData={samplesAndBlendsStore.updateSampleResultFormData}
          />
        )}

        {/* BLENDS FORMS */}
        {entityType === EStructureItem.Blend && !!entityId && (
          <BaseJsForms
            uuid={entityId}
            name={projectBlends.findBlend(entityId)?.name}
            jsonSchemas={projectBlends.jsonForm?.jsonSchemas}
            additionalTabs={projectBlends.jsonForm?.additionalTabs}
            isLoading={projectBlends.isFormLoading}
            isUpdating={projectBlends.isFormDataUpdating}
            onUpdateFormData={samplesAndBlendsStore.updateBlendFormData}
          />
        )}

        {/* BLENDS RESULT FORMS */}
        {entityType === EStructureItem.BlendResult && !!entityId && (
          <BaseJsForms
            uuid={entityId}
            name={projectBlendsResults.findBlendResult(entityId)?.name}
            jsonSchemas={projectBlendsResults.jsonForm?.jsonSchemas}
            additionalTabs={projectBlendsResults.jsonForm?.additionalTabs}
            isLoading={projectBlendsResults.isFormLoading}
            isUpdating={projectBlendsResults.isFormDataUpdating}
            onUpdateFormData={samplesAndBlendsStore.updateBlendResultFormData}
          />
        )}
      </Box>
    </ErrorBoundary>
  );
};

export const ProjectForms = observer(ProjectFormsFC);
