import {FC} from 'react';
import {Grid} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {FlexDivider} from '@progress-fe/ui-kit';
import {EStructureItem} from '@progress-fe/core';
import {Panel, PanelGroup, PanelResizeHandle} from 'react-resizable-panels';

import {useStore} from 'core';

import {
  BlendsList,
  BlendsResultsList,
  SamplesList,
  SamplesResultsList,
  Settings
} from './components';

const ProjectStructureFC: FC = () => {
  const {samplesAndBlendsStore} = useStore();
  const {projectSamples, projectBlends, checkpointUuid} = samplesAndBlendsStore;
  const {projectBlendsResults, projectSamplesResults} = samplesAndBlendsStore;
  const {uiState, selectEntityByType} = samplesAndBlendsStore;
  const {entityId, entityType} = uiState;

  return (
    <Grid gridTemplateRows={'32px 8px 1fr'} h={'var(--height-layout)'}>
      {/* PROJECT SETTINGS */}
      <Settings
        isSelected={entityType === EStructureItem.Settings}
        onSelect={() => selectEntityByType(EStructureItem.Settings)}
      />

      <FlexDivider withDots />

      <PanelGroup direction="vertical" autoSaveId={checkpointUuid}>
        {/* PROJECT SAMPLES */}
        <Panel minSize={11}>
          <SamplesList
            isLoading={projectSamples.isLoading}
            isCreating={projectSamples.isCreating}
            samples={projectSamples.samples}
            selectedId={entityType === EStructureItem.Sample ? entityId : null}
            onSelect={(uuid) => selectEntityByType(EStructureItem.Sample, uuid)}
            onCreate={samplesAndBlendsStore.createSample}
            onDelete={samplesAndBlendsStore.deleteSample}
          />
        </Panel>
        <PanelResizeHandle>
          <FlexDivider withDots />
        </PanelResizeHandle>
        {/* PROJECT SAMPLES RESULTS */}
        <Panel minSize={11}>
          <SamplesResultsList
            isLoading={projectSamplesResults.isLoading}
            sampleResults={projectSamplesResults.sampleResults}
            selectedId={entityType === EStructureItem.SampleResult ? entityId : null}
            onSelect={(uuid) => selectEntityByType(EStructureItem.SampleResult, uuid)}
          />
        </Panel>
        <PanelResizeHandle>
          <FlexDivider withDots />
        </PanelResizeHandle>

        {/* PROJECT BLENDS */}
        <Panel minSize={11}>
          <BlendsList
            isLoading={projectBlends.isLoading}
            isCreating={projectBlends.isCreating}
            blends={projectBlends.blends}
            selectedId={entityType === EStructureItem.Blend ? entityId : null}
            onSelect={(uuid) => selectEntityByType(EStructureItem.Blend, uuid)}
            onCreate={samplesAndBlendsStore.createBlend}
            onDelete={samplesAndBlendsStore.deleteBlend}
            onExport={projectBlends.exportBlend}
          />
        </Panel>

        <PanelResizeHandle>
          <FlexDivider withDots />
        </PanelResizeHandle>

        {/* PROJECT BLENDS RESULTS */}
        <Panel minSize={11}>
          <BlendsResultsList
            isLoading={projectBlendsResults.isLoading}
            blendResults={projectBlendsResults.blendResults}
            selectedId={entityType === EStructureItem.BlendResult ? entityId : null}
            onSelect={(uuid) => selectEntityByType(EStructureItem.BlendResult, uuid)}
          />
        </Panel>
      </PanelGroup>
    </Grid>
  );
};

export const ProjectStructure = observer(ProjectStructureFC);
