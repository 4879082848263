/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
/**
 *
 * @export
 * @interface AdditionalTabCode
 */
export interface AdditionalTabCode {
  /**
   *
   * @type {string}
   * @memberof AdditionalTabCode
   */
  uniqueCode: string;
}

/**
 * Check if a given object implements the AdditionalTabCode interface.
 */
export function instanceOfAdditionalTabCode(value: object): value is AdditionalTabCode {
  if (!('uniqueCode' in value) || value['uniqueCode'] === undefined) return false;
  return true;
}

export function AdditionalTabCodeFromJSON(json: any): AdditionalTabCode {
  return AdditionalTabCodeFromJSONTyped(json, false);
}

export function AdditionalTabCodeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AdditionalTabCode {
  if (json == null) {
    return json;
  }
  return {
    uniqueCode: json['unique_code']
  };
}

export function AdditionalTabCodeToJSON(value?: AdditionalTabCode | null): any {
  if (value == null) {
    return value;
  }
  return {
    unique_code: value['uniqueCode']
  };
}
