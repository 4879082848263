import {flow, getSnapshot, Instance, types} from 'mobx-state-tree';
import {IComponent, IPureComponent} from '@progress-fe/core';

import {ResetModel} from 'core/models';

import {ModelsCatalog, ElementsCatalog, PureComponentsCatalog} from './models';

const CatalogsStore = types
  .compose(
    ResetModel,
    types.model('CatalogsStore', {
      modelsCatalog: types.optional(ModelsCatalog, {}),
      elementsCatalog: types.optional(ElementsCatalog, {}),
      pureComponentsCatalog: types.optional(PureComponentsCatalog, {})
    })
  )
  .actions((self) => ({
    load: flow(function* () {
      yield Promise.allSettled([
        self.modelsCatalog.load(),
        self.elementsCatalog.load(),
        self.pureComponentsCatalog.load()
      ]);
    })
  }))
  .views((self) => ({
    get pureComponents(): IPureComponent[] {
      return getSnapshot(self.pureComponentsCatalog.components);
    }
  }))
  .views((self) => ({
    get componentsDictionary(): Record<string, IComponent> {
      return self.pureComponents.reduce((acc: Record<string, IComponent>, cur: IComponent) => {
        acc[cur.uuid] = cur;
        return acc;
      }, {});
    }
  }));

export type TCatalogsStoreModel = Instance<typeof CatalogsStore>;

export {CatalogsStore};
