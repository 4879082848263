/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
/**
 *
 * @export
 * @interface HandleQualifier
 */
export interface HandleQualifier {
  /**
   *
   * @type {string}
   * @memberof HandleQualifier
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof HandleQualifier
   */
  name: string;
}

/**
 * Check if a given object implements the HandleQualifier interface.
 */
export function instanceOfHandleQualifier(value: object): value is HandleQualifier {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  return true;
}

export function HandleQualifierFromJSON(json: any): HandleQualifier {
  return HandleQualifierFromJSONTyped(json, false);
}

export function HandleQualifierFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): HandleQualifier {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name']
  };
}

export function HandleQualifierToJSON(value?: HandleQualifier | null): any {
  if (value == null) {
    return value;
  }
  return {
    id: value['id'],
    name: value['name']
  };
}
