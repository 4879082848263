import {Instance, types} from 'mobx-state-tree';
import {ELogicalElement} from '@progress-fe/core';

import {EntityBaseInfo} from 'core/models';

const LogicalElementInfo = types.compose(
  EntityBaseInfo,
  types.model('LogicalElementInfo', {
    logicalType: types.maybeNull(types.enumeration(Object.values(ELogicalElement)))
  })
);

export type TLogicalElementInfoModel = Instance<typeof LogicalElementInfo>;

export {LogicalElementInfo};
