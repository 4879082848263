/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
/**
 *
 * @export
 * @interface ElementsToDeleteIn
 */
export interface ElementsToDeleteIn {
  /**
   *
   * @type {Array<string>}
   * @memberof ElementsToDeleteIn
   */
  elements: Array<string>;
}

/**
 * Check if a given object implements the ElementsToDeleteIn interface.
 */
export function instanceOfElementsToDeleteIn(value: object): value is ElementsToDeleteIn {
  if (!('elements' in value) || value['elements'] === undefined) return false;
  return true;
}

export function ElementsToDeleteInFromJSON(json: any): ElementsToDeleteIn {
  return ElementsToDeleteInFromJSONTyped(json, false);
}

export function ElementsToDeleteInFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ElementsToDeleteIn {
  if (json == null) {
    return json;
  }
  return {
    elements: json['elements']
  };
}

export function ElementsToDeleteInToJSON(value?: ElementsToDeleteIn | null): any {
  if (value == null) {
    return value;
  }
  return {
    elements: value['elements']
  };
}
