import {Instance, SnapshotIn, types} from 'mobx-state-tree';

import {EntityBaseInfo} from 'core/models';

const ModelInfo = types.compose(
  EntityBaseInfo,
  types.model('ModelInfo', {
    subModels: types.optional(types.array(EntityBaseInfo), [])
  })
);

export type TModelInfoModel = Instance<typeof ModelInfo>;
export type TModelInfoSnapshotIn = SnapshotIn<typeof ModelInfo>;

export {ModelInfo};
