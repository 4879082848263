/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const WidgetTypeEnum = {
  LineChart: 'lineChart',
  ComplexTable: 'complexTable'
} as const;
export type WidgetTypeEnum = (typeof WidgetTypeEnum)[keyof typeof WidgetTypeEnum];

export function instanceOfWidgetTypeEnum(value: any): boolean {
  for (const key in WidgetTypeEnum) {
    if (Object.prototype.hasOwnProperty.call(WidgetTypeEnum, key)) {
      if ((WidgetTypeEnum as Record<string, WidgetTypeEnum>)[key] === value) {
        return true;
      }
    }
  }
  return false;
}

export function WidgetTypeEnumFromJSON(json: any): WidgetTypeEnum {
  return WidgetTypeEnumFromJSONTyped(json, false);
}

export function WidgetTypeEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): WidgetTypeEnum {
  return json as WidgetTypeEnum;
}

export function WidgetTypeEnumToJSON(value?: WidgetTypeEnum | null): any {
  return value as any;
}
