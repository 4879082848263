import {cast, getSnapshot, Instance, SnapshotIn, types} from 'mobx-state-tree';
import {TRFEdgeDataConfig, TRFWorkZone, TRFWorkZoneDataConfig} from '@progress-fe/rf-core';
import {Edge, Node} from '@xyflow/react';

import {RequestModel} from 'core/models';

const Workzone = types
  .model('Workzone', {
    lastUpdate: new Date(),
    parentUuid: types.maybeNull(types.string),
    nodes: types.optional(types.array(types.frozen<Node<TRFWorkZoneDataConfig>>()), []),
    edges: types.optional(types.array(types.frozen<Edge<TRFEdgeDataConfig>>()), []),
    fetchRequest: types.optional(RequestModel, {})
  })
  .actions((self) => ({
    refreshLastUpdate(): void {
      self.lastUpdate = new Date();
    },
    setNodesAndEdges(workzone: TRFWorkZone) {
      self.nodes = cast(workzone.nodes);
      self.edges = cast(workzone.edges);
      this.refreshLastUpdate();
    },
    clear(): void {
      self.nodes = cast([]);
      self.edges = cast([]);
      this.refreshLastUpdate();
    }
  }))
  .views((self) => ({
    get nodeList() {
      return getSnapshot(self.nodes);
    },
    get edgeList() {
      return getSnapshot(self.edges);
    }
  }));

export type TWorkzoneModel = Instance<typeof Workzone>;
export type TWorkzoneSnapshotIn = SnapshotIn<typeof Workzone>;

export {Workzone};
