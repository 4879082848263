/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  ActionsInner,
  AdditionalTabsInfo,
  AppProjectsTechprocessSchemasBlendOut,
  AppProjectsTechprocessSchemasUpdateOut,
  BodyTechProcessAddConnection,
  BodyTechProcessRemoveConnection,
  CalculationResultUpdateOut,
  CalculationTaskRunStatus,
  CallbackRunRequest,
  CallbackRunResponse,
  CreateElementActionResult,
  CreateLogicalElementActionResult,
  CreateModelActionResult,
  DeleteModelPossibility,
  ElementUpdateOut,
  ElementsOut,
  ElementsToDeleteIn,
  FullElementSchemas,
  GraphZone,
  HTTPValidationError,
  LogicalElementIn,
  LogicalElementsOut,
  LogicalUpdateOut,
  ModelsOut,
  NewElement,
  NewModel,
  PositionInfo,
  PositionsInfo,
  ProfilerMode,
  RJSFSchemas,
  ResponseStatusListUUID,
  TechProcessActionResult,
  TechprocessCalculationResult,
  TechprocessCalculationTaskOut,
  TechprocessCalculationTaskUpdate,
  WorkZone
} from '../models/index';
import {
  ActionsInnerFromJSON,
  ActionsInnerToJSON,
  AdditionalTabsInfoFromJSON,
  AdditionalTabsInfoToJSON,
  AppProjectsTechprocessSchemasBlendOutFromJSON,
  AppProjectsTechprocessSchemasBlendOutToJSON,
  AppProjectsTechprocessSchemasUpdateOutFromJSON,
  AppProjectsTechprocessSchemasUpdateOutToJSON,
  BodyTechProcessAddConnectionFromJSON,
  BodyTechProcessAddConnectionToJSON,
  BodyTechProcessRemoveConnectionFromJSON,
  BodyTechProcessRemoveConnectionToJSON,
  CalculationResultUpdateOutFromJSON,
  CalculationResultUpdateOutToJSON,
  CalculationTaskRunStatusFromJSON,
  CalculationTaskRunStatusToJSON,
  CallbackRunRequestFromJSON,
  CallbackRunRequestToJSON,
  CallbackRunResponseFromJSON,
  CallbackRunResponseToJSON,
  CreateElementActionResultFromJSON,
  CreateElementActionResultToJSON,
  CreateLogicalElementActionResultFromJSON,
  CreateLogicalElementActionResultToJSON,
  CreateModelActionResultFromJSON,
  CreateModelActionResultToJSON,
  DeleteModelPossibilityFromJSON,
  DeleteModelPossibilityToJSON,
  ElementUpdateOutFromJSON,
  ElementUpdateOutToJSON,
  ElementsOutFromJSON,
  ElementsOutToJSON,
  ElementsToDeleteInFromJSON,
  ElementsToDeleteInToJSON,
  FullElementSchemasFromJSON,
  FullElementSchemasToJSON,
  GraphZoneFromJSON,
  GraphZoneToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  LogicalElementInFromJSON,
  LogicalElementInToJSON,
  LogicalElementsOutFromJSON,
  LogicalElementsOutToJSON,
  LogicalUpdateOutFromJSON,
  LogicalUpdateOutToJSON,
  ModelsOutFromJSON,
  ModelsOutToJSON,
  NewElementFromJSON,
  NewElementToJSON,
  NewModelFromJSON,
  NewModelToJSON,
  PositionInfoFromJSON,
  PositionInfoToJSON,
  PositionsInfoFromJSON,
  PositionsInfoToJSON,
  ProfilerModeFromJSON,
  ProfilerModeToJSON,
  RJSFSchemasFromJSON,
  RJSFSchemasToJSON,
  ResponseStatusListUUIDFromJSON,
  ResponseStatusListUUIDToJSON,
  TechProcessActionResultFromJSON,
  TechProcessActionResultToJSON,
  TechprocessCalculationResultFromJSON,
  TechprocessCalculationResultToJSON,
  TechprocessCalculationTaskOutFromJSON,
  TechprocessCalculationTaskOutToJSON,
  TechprocessCalculationTaskUpdateFromJSON,
  TechprocessCalculationTaskUpdateToJSON,
  WorkZoneFromJSON,
  WorkZoneToJSON
} from '../models/index';

export interface TechProcessAddConnectionRequest {
  checkpointUuid: string;
  projectUuid: string;
  bodyTechProcessAddConnection: BodyTechProcessAddConnection;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessApplyActionsRequest {
  projectUuid: string;
  checkpointUuid: string;
  idempotencyKey: string;
  actionsInner: Array<ActionsInner>;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessCheckModelInstanceDeleteableRequest {
  modelInstanceUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessCreateElementRequest {
  checkpointUuid: string;
  projectUuid: string;
  idempotencyKey: string;
  newElement: NewElement;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessCreateLogicalElementRequest {
  checkpointUuid: string;
  projectUuid: string;
  idempotencyKey: string;
  logicalElementIn: LogicalElementIn;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessCreateModelRequest {
  checkpointUuid: string;
  projectUuid: string;
  idempotencyKey: string;
  newModel: NewModel;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessDeleteElementRequest {
  elementUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessDeleteElementsGroupRequest {
  checkpointUuid: string;
  projectUuid: string;
  elementsToDeleteIn: ElementsToDeleteIn;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessDeleteLogicalElementRequest {
  logicalElementUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessDeleteModelInstanceRequest {
  modelInstanceUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessDuplicateElementRequest {
  elementUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  idempotencyKey: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessDuplicateLogicalElementRequest {
  elementUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  idempotencyKey: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessDuplicateModelInstanceRequest {
  modelInstanceUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  idempotencyKey: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessGetCompGraphRequest {
  projectUuid: string;
  checkpointUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessGetElementSchemasRequest {
  elementUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessGetElementsRequest {
  checkpointUuid: string;
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessGetFavoritePureComponentsRequest {
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessGetLogicalElementSchemasRequest {
  logicalElementUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessGetLogicalElementsRequest {
  checkpointUuid: string;
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessGetModelSchemasRequest {
  modelInstanceUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessGetModelsRequest {
  checkpointUuid: string;
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessGetProjectBlendsRequest {
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessGetSubWorkZoneRequest {
  elementUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessGetTechprocessCalculationResultSchemasRequest {
  calculationResultUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessGetTechprocessCalculationResultsRequest {
  checkpointUuid: string;
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessGetTechprocessCalculationTasksRequest {
  checkpointUuid: string;
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessGetWorkZoneRequest {
  projectUuid: string;
  checkpointUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessImportBlendsRequest {
  projectUuid: string;
  prefix: string;
  file: Blob;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessRemoveConnectionRequest {
  checkpointUuid: string;
  projectUuid: string;
  bodyTechProcessRemoveConnection: BodyTechProcessRemoveConnection;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessRunElementCallbackRequest {
  elementUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  callbackRunRequest: CallbackRunRequest;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessRunLogicalElementCallbackRequest {
  logicalElementUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  callbackRunRequest: CallbackRunRequest;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessRunModelCallbackRequest {
  modelInstanceUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  callbackRunRequest: CallbackRunRequest;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessRunTechprocessCalculationTaskRequest {
  checkpointUuid: string;
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessSetElementAdditionalTabsRequest {
  elementUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  additionalTabsInfo: AdditionalTabsInfo;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessSetFavoritePureComponentsRequest {
  projectUuid: string;
  requestBody: Array<string>;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessSetTechprocessCalculationTaskRequest {
  checkpointUuid: string;
  projectUuid: string;
  techprocessCalculationTaskUpdate: TechprocessCalculationTaskUpdate;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessUpdateElementInstanceRequest {
  elementUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  body: object;
  tabCode?: string | null;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessUpdateElementInstancePositionRequest {
  elementUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  positionInfo: PositionInfo;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessUpdateElementInstancesGroupPositionRequest {
  checkpointUuid: string;
  projectUuid: string;
  positionsInfo: PositionsInfo;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessUpdateLogicalElementInstanceRequest {
  logicalElementUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  body: object;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessUpdateModelInstanceRequest {
  modelInstanceUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  body: object;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessUpdateTechprocessCalculationResultRequest {
  calculationResultUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  body: object;
  xProfilerMode?: ProfilerMode;
}

/**
 *
 */
export class TechProcessApi extends runtime.BaseAPI {
  /**
   * Add Connection
   */
  async techProcessAddConnectionRaw(
    requestParameters: TechProcessAddConnectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessAddConnection().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessAddConnection().'
      );
    }

    if (requestParameters['bodyTechProcessAddConnection'] == null) {
      throw new runtime.RequiredError(
        'bodyTechProcessAddConnection',
        'Required parameter "bodyTechProcessAddConnection" was null or undefined when calling techProcessAddConnection().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/connect`
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BodyTechProcessAddConnectionToJSON(requestParameters['bodyTechProcessAddConnection'])
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Add Connection
   */
  async techProcessAddConnection(
    requestParameters: TechProcessAddConnectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.techProcessAddConnectionRaw(requestParameters, initOverrides);
  }

  /**
   * Apply Actions
   */
  async techProcessApplyActionsRaw(
    requestParameters: TechProcessApplyActionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<TechProcessActionResult>>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessApplyActions().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessApplyActions().'
      );
    }

    if (requestParameters['idempotencyKey'] == null) {
      throw new runtime.RequiredError(
        'idempotencyKey',
        'Required parameter "idempotencyKey" was null or undefined when calling techProcessApplyActions().'
      );
    }

    if (requestParameters['actionsInner'] == null) {
      throw new runtime.RequiredError(
        'actionsInner',
        'Required parameter "actionsInner" was null or undefined when calling techProcessApplyActions().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(requestParameters['idempotencyKey']);
    }

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/actions`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters['actionsInner']!.map(ActionsInnerToJSON)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(TechProcessActionResultFromJSON)
    );
  }

  /**
   * Apply Actions
   */
  async techProcessApplyActions(
    requestParameters: TechProcessApplyActionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<TechProcessActionResult>> {
    const response = await this.techProcessApplyActionsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Check Model Instance Deleteable
   */
  async techProcessCheckModelInstanceDeleteableRaw(
    requestParameters: TechProcessCheckModelInstanceDeleteableRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<DeleteModelPossibility>> {
    if (requestParameters['modelInstanceUuid'] == null) {
      throw new runtime.RequiredError(
        'modelInstanceUuid',
        'Required parameter "modelInstanceUuid" was null or undefined when calling techProcessCheckModelInstanceDeleteable().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessCheckModelInstanceDeleteable().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessCheckModelInstanceDeleteable().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/models/{model_instance_uuid}/deleteable`
          .replace(
            `{${'model_instance_uuid'}}`,
            encodeURIComponent(String(requestParameters['modelInstanceUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DeleteModelPossibilityFromJSON(jsonValue)
    );
  }

  /**
   * Check Model Instance Deleteable
   */
  async techProcessCheckModelInstanceDeleteable(
    requestParameters: TechProcessCheckModelInstanceDeleteableRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<DeleteModelPossibility> {
    const response = await this.techProcessCheckModelInstanceDeleteableRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Create Element
   */
  async techProcessCreateElementRaw(
    requestParameters: TechProcessCreateElementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CreateElementActionResult>> {
    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessCreateElement().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessCreateElement().'
      );
    }

    if (requestParameters['idempotencyKey'] == null) {
      throw new runtime.RequiredError(
        'idempotencyKey',
        'Required parameter "idempotencyKey" was null or undefined when calling techProcessCreateElement().'
      );
    }

    if (requestParameters['newElement'] == null) {
      throw new runtime.RequiredError(
        'newElement',
        'Required parameter "newElement" was null or undefined when calling techProcessCreateElement().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(requestParameters['idempotencyKey']);
    }

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/elements`
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: NewElementToJSON(requestParameters['newElement'])
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CreateElementActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Create Element
   */
  async techProcessCreateElement(
    requestParameters: TechProcessCreateElementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CreateElementActionResult> {
    const response = await this.techProcessCreateElementRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Create Logical Element
   */
  async techProcessCreateLogicalElementRaw(
    requestParameters: TechProcessCreateLogicalElementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CreateLogicalElementActionResult>> {
    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessCreateLogicalElement().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessCreateLogicalElement().'
      );
    }

    if (requestParameters['idempotencyKey'] == null) {
      throw new runtime.RequiredError(
        'idempotencyKey',
        'Required parameter "idempotencyKey" was null or undefined when calling techProcessCreateLogicalElement().'
      );
    }

    if (requestParameters['logicalElementIn'] == null) {
      throw new runtime.RequiredError(
        'logicalElementIn',
        'Required parameter "logicalElementIn" was null or undefined when calling techProcessCreateLogicalElement().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(requestParameters['idempotencyKey']);
    }

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/logical_elements`
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: LogicalElementInToJSON(requestParameters['logicalElementIn'])
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CreateLogicalElementActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Create Logical Element
   */
  async techProcessCreateLogicalElement(
    requestParameters: TechProcessCreateLogicalElementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CreateLogicalElementActionResult> {
    const response = await this.techProcessCreateLogicalElementRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Create Model
   */
  async techProcessCreateModelRaw(
    requestParameters: TechProcessCreateModelRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TechProcessActionResult>> {
    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessCreateModel().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessCreateModel().'
      );
    }

    if (requestParameters['idempotencyKey'] == null) {
      throw new runtime.RequiredError(
        'idempotencyKey',
        'Required parameter "idempotencyKey" was null or undefined when calling techProcessCreateModel().'
      );
    }

    if (requestParameters['newModel'] == null) {
      throw new runtime.RequiredError(
        'newModel',
        'Required parameter "newModel" was null or undefined when calling techProcessCreateModel().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(requestParameters['idempotencyKey']);
    }

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/models`
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: NewModelToJSON(requestParameters['newModel'])
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TechProcessActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Create Model
   */
  async techProcessCreateModel(
    requestParameters: TechProcessCreateModelRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TechProcessActionResult> {
    const response = await this.techProcessCreateModelRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete Element
   */
  async techProcessDeleteElementRaw(
    requestParameters: TechProcessDeleteElementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['elementUuid'] == null) {
      throw new runtime.RequiredError(
        'elementUuid',
        'Required parameter "elementUuid" was null or undefined when calling techProcessDeleteElement().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessDeleteElement().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessDeleteElement().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/elements/{element_uuid}`
          .replace(
            `{${'element_uuid'}}`,
            encodeURIComponent(String(requestParameters['elementUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete Element
   */
  async techProcessDeleteElement(
    requestParameters: TechProcessDeleteElementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.techProcessDeleteElementRaw(requestParameters, initOverrides);
  }

  /**
   * Delete Elements Group
   */
  async techProcessDeleteElementsGroupRaw(
    requestParameters: TechProcessDeleteElementsGroupRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessDeleteElementsGroup().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessDeleteElementsGroup().'
      );
    }

    if (requestParameters['elementsToDeleteIn'] == null) {
      throw new runtime.RequiredError(
        'elementsToDeleteIn',
        'Required parameter "elementsToDeleteIn" was null or undefined when calling techProcessDeleteElementsGroup().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/elements_group/delete`
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ElementsToDeleteInToJSON(requestParameters['elementsToDeleteIn'])
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete Elements Group
   */
  async techProcessDeleteElementsGroup(
    requestParameters: TechProcessDeleteElementsGroupRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.techProcessDeleteElementsGroupRaw(requestParameters, initOverrides);
  }

  /**
   * Delete Logical Element
   */
  async techProcessDeleteLogicalElementRaw(
    requestParameters: TechProcessDeleteLogicalElementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['logicalElementUuid'] == null) {
      throw new runtime.RequiredError(
        'logicalElementUuid',
        'Required parameter "logicalElementUuid" was null or undefined when calling techProcessDeleteLogicalElement().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessDeleteLogicalElement().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessDeleteLogicalElement().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/logical_elements/{logical_element_uuid}`
          .replace(
            `{${'logical_element_uuid'}}`,
            encodeURIComponent(String(requestParameters['logicalElementUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete Logical Element
   */
  async techProcessDeleteLogicalElement(
    requestParameters: TechProcessDeleteLogicalElementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.techProcessDeleteLogicalElementRaw(requestParameters, initOverrides);
  }

  /**
   * Delete Model Instance
   */
  async techProcessDeleteModelInstanceRaw(
    requestParameters: TechProcessDeleteModelInstanceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters['modelInstanceUuid'] == null) {
      throw new runtime.RequiredError(
        'modelInstanceUuid',
        'Required parameter "modelInstanceUuid" was null or undefined when calling techProcessDeleteModelInstance().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessDeleteModelInstance().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessDeleteModelInstance().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/models/{model_instance_uuid}`
          .replace(
            `{${'model_instance_uuid'}}`,
            encodeURIComponent(String(requestParameters['modelInstanceUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<any>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Delete Model Instance
   */
  async techProcessDeleteModelInstance(
    requestParameters: TechProcessDeleteModelInstanceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<any> {
    const response = await this.techProcessDeleteModelInstanceRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Duplicate Element
   */
  async techProcessDuplicateElementRaw(
    requestParameters: TechProcessDuplicateElementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CreateElementActionResult>> {
    if (requestParameters['elementUuid'] == null) {
      throw new runtime.RequiredError(
        'elementUuid',
        'Required parameter "elementUuid" was null or undefined when calling techProcessDuplicateElement().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessDuplicateElement().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessDuplicateElement().'
      );
    }

    if (requestParameters['idempotencyKey'] == null) {
      throw new runtime.RequiredError(
        'idempotencyKey',
        'Required parameter "idempotencyKey" was null or undefined when calling techProcessDuplicateElement().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(requestParameters['idempotencyKey']);
    }

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/elements/{element_uuid}/duplicate`
          .replace(
            `{${'element_uuid'}}`,
            encodeURIComponent(String(requestParameters['elementUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CreateElementActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Duplicate Element
   */
  async techProcessDuplicateElement(
    requestParameters: TechProcessDuplicateElementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CreateElementActionResult> {
    const response = await this.techProcessDuplicateElementRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Duplicate Logical Element
   */
  async techProcessDuplicateLogicalElementRaw(
    requestParameters: TechProcessDuplicateLogicalElementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CreateLogicalElementActionResult>> {
    if (requestParameters['elementUuid'] == null) {
      throw new runtime.RequiredError(
        'elementUuid',
        'Required parameter "elementUuid" was null or undefined when calling techProcessDuplicateLogicalElement().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessDuplicateLogicalElement().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessDuplicateLogicalElement().'
      );
    }

    if (requestParameters['idempotencyKey'] == null) {
      throw new runtime.RequiredError(
        'idempotencyKey',
        'Required parameter "idempotencyKey" was null or undefined when calling techProcessDuplicateLogicalElement().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(requestParameters['idempotencyKey']);
    }

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/logical_elements/{element_uuid}/duplicate`
          .replace(
            `{${'element_uuid'}}`,
            encodeURIComponent(String(requestParameters['elementUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CreateLogicalElementActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Duplicate Logical Element
   */
  async techProcessDuplicateLogicalElement(
    requestParameters: TechProcessDuplicateLogicalElementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CreateLogicalElementActionResult> {
    const response = await this.techProcessDuplicateLogicalElementRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Duplicate Model Instance
   */
  async techProcessDuplicateModelInstanceRaw(
    requestParameters: TechProcessDuplicateModelInstanceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CreateModelActionResult>> {
    if (requestParameters['modelInstanceUuid'] == null) {
      throw new runtime.RequiredError(
        'modelInstanceUuid',
        'Required parameter "modelInstanceUuid" was null or undefined when calling techProcessDuplicateModelInstance().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessDuplicateModelInstance().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessDuplicateModelInstance().'
      );
    }

    if (requestParameters['idempotencyKey'] == null) {
      throw new runtime.RequiredError(
        'idempotencyKey',
        'Required parameter "idempotencyKey" was null or undefined when calling techProcessDuplicateModelInstance().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(requestParameters['idempotencyKey']);
    }

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/models/{model_instance_uuid}/duplicate`
          .replace(
            `{${'model_instance_uuid'}}`,
            encodeURIComponent(String(requestParameters['modelInstanceUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CreateModelActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Duplicate Model Instance
   */
  async techProcessDuplicateModelInstance(
    requestParameters: TechProcessDuplicateModelInstanceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CreateModelActionResult> {
    const response = await this.techProcessDuplicateModelInstanceRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get Comp Graph
   */
  async techProcessGetCompGraphRaw(
    requestParameters: TechProcessGetCompGraphRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GraphZone>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetCompGraph().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetCompGraph().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/comp_graph`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GraphZoneFromJSON(jsonValue));
  }

  /**
   * Get Comp Graph
   */
  async techProcessGetCompGraph(
    requestParameters: TechProcessGetCompGraphRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GraphZone> {
    const response = await this.techProcessGetCompGraphRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Element Schemas
   */
  async techProcessGetElementSchemasRaw(
    requestParameters: TechProcessGetElementSchemasRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<FullElementSchemas>> {
    if (requestParameters['elementUuid'] == null) {
      throw new runtime.RequiredError(
        'elementUuid',
        'Required parameter "elementUuid" was null or undefined when calling techProcessGetElementSchemas().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetElementSchemas().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetElementSchemas().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/elements/{element_uuid}`
          .replace(
            `{${'element_uuid'}}`,
            encodeURIComponent(String(requestParameters['elementUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FullElementSchemasFromJSON(jsonValue)
    );
  }

  /**
   * Get Element Schemas
   */
  async techProcessGetElementSchemas(
    requestParameters: TechProcessGetElementSchemasRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<FullElementSchemas> {
    const response = await this.techProcessGetElementSchemasRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Elements
   */
  async techProcessGetElementsRaw(
    requestParameters: TechProcessGetElementsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ElementsOut>> {
    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetElements().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetElements().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/elements`
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ElementsOutFromJSON(jsonValue));
  }

  /**
   * Get Elements
   */
  async techProcessGetElements(
    requestParameters: TechProcessGetElementsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ElementsOut> {
    const response = await this.techProcessGetElementsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Получение списка избранных компонентов
   * Get Favorite Pure Components
   */
  async techProcessGetFavoritePureComponentsRaw(
    requestParameters: TechProcessGetFavoritePureComponentsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<string>>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetFavoritePureComponents().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/techprocess/favorite_pure_components`.replace(
          `{${'project_uuid'}}`,
          encodeURIComponent(String(requestParameters['projectUuid']))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Получение списка избранных компонентов
   * Get Favorite Pure Components
   */
  async techProcessGetFavoritePureComponents(
    requestParameters: TechProcessGetFavoritePureComponentsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<string>> {
    const response = await this.techProcessGetFavoritePureComponentsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get Logical Element Schemas
   */
  async techProcessGetLogicalElementSchemasRaw(
    requestParameters: TechProcessGetLogicalElementSchemasRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<{[key: string]: RJSFSchemas}>> {
    if (requestParameters['logicalElementUuid'] == null) {
      throw new runtime.RequiredError(
        'logicalElementUuid',
        'Required parameter "logicalElementUuid" was null or undefined when calling techProcessGetLogicalElementSchemas().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetLogicalElementSchemas().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetLogicalElementSchemas().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/logical_elements/{logical_element_uuid}`
          .replace(
            `{${'logical_element_uuid'}}`,
            encodeURIComponent(String(requestParameters['logicalElementUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      runtime.mapValues(jsonValue, RJSFSchemasFromJSON)
    );
  }

  /**
   * Get Logical Element Schemas
   */
  async techProcessGetLogicalElementSchemas(
    requestParameters: TechProcessGetLogicalElementSchemasRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<{[key: string]: RJSFSchemas}> {
    const response = await this.techProcessGetLogicalElementSchemasRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get Logical Elements
   */
  async techProcessGetLogicalElementsRaw(
    requestParameters: TechProcessGetLogicalElementsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<LogicalElementsOut>> {
    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetLogicalElements().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetLogicalElements().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/logical_elements`
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LogicalElementsOutFromJSON(jsonValue)
    );
  }

  /**
   * Get Logical Elements
   */
  async techProcessGetLogicalElements(
    requestParameters: TechProcessGetLogicalElementsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<LogicalElementsOut> {
    const response = await this.techProcessGetLogicalElementsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Model Schemas
   */
  async techProcessGetModelSchemasRaw(
    requestParameters: TechProcessGetModelSchemasRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<{[key: string]: RJSFSchemas}>> {
    if (requestParameters['modelInstanceUuid'] == null) {
      throw new runtime.RequiredError(
        'modelInstanceUuid',
        'Required parameter "modelInstanceUuid" was null or undefined when calling techProcessGetModelSchemas().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetModelSchemas().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetModelSchemas().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/models/{model_instance_uuid}`
          .replace(
            `{${'model_instance_uuid'}}`,
            encodeURIComponent(String(requestParameters['modelInstanceUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      runtime.mapValues(jsonValue, RJSFSchemasFromJSON)
    );
  }

  /**
   * Get Model Schemas
   */
  async techProcessGetModelSchemas(
    requestParameters: TechProcessGetModelSchemasRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<{[key: string]: RJSFSchemas}> {
    const response = await this.techProcessGetModelSchemasRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Models
   */
  async techProcessGetModelsRaw(
    requestParameters: TechProcessGetModelsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ModelsOut>> {
    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetModels().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetModels().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/models`
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ModelsOutFromJSON(jsonValue));
  }

  /**
   * Get Models
   */
  async techProcessGetModels(
    requestParameters: TechProcessGetModelsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ModelsOut> {
    const response = await this.techProcessGetModelsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Project Blends
   */
  async techProcessGetProjectBlendsRaw(
    requestParameters: TechProcessGetProjectBlendsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<AppProjectsTechprocessSchemasBlendOut>>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetProjectBlends().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/blends`.replace(
          `{${'project_uuid'}}`,
          encodeURIComponent(String(requestParameters['projectUuid']))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(AppProjectsTechprocessSchemasBlendOutFromJSON)
    );
  }

  /**
   * Get Project Blends
   */
  async techProcessGetProjectBlends(
    requestParameters: TechProcessGetProjectBlendsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<AppProjectsTechprocessSchemasBlendOut>> {
    const response = await this.techProcessGetProjectBlendsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Sub Work Zone
   */
  async techProcessGetSubWorkZoneRaw(
    requestParameters: TechProcessGetSubWorkZoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<WorkZone>> {
    if (requestParameters['elementUuid'] == null) {
      throw new runtime.RequiredError(
        'elementUuid',
        'Required parameter "elementUuid" was null or undefined when calling techProcessGetSubWorkZone().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetSubWorkZone().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetSubWorkZone().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/elements/{element_uuid}/work_zone`
          .replace(
            `{${'element_uuid'}}`,
            encodeURIComponent(String(requestParameters['elementUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => WorkZoneFromJSON(jsonValue));
  }

  /**
   * Get Sub Work Zone
   */
  async techProcessGetSubWorkZone(
    requestParameters: TechProcessGetSubWorkZoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<WorkZone> {
    const response = await this.techProcessGetSubWorkZoneRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Получение схем результата расчётов
   * Get Techprocess Calculation Result Schemas
   */
  async techProcessGetTechprocessCalculationResultSchemasRaw(
    requestParameters: TechProcessGetTechprocessCalculationResultSchemasRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<{[key: string]: RJSFSchemas}>> {
    if (requestParameters['calculationResultUuid'] == null) {
      throw new runtime.RequiredError(
        'calculationResultUuid',
        'Required parameter "calculationResultUuid" was null or undefined when calling techProcessGetTechprocessCalculationResultSchemas().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetTechprocessCalculationResultSchemas().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetTechprocessCalculationResultSchemas().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/techprocess/calculation_results/{calculation_result_uuid}`
          .replace(
            `{${'calculation_result_uuid'}}`,
            encodeURIComponent(String(requestParameters['calculationResultUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      runtime.mapValues(jsonValue, RJSFSchemasFromJSON)
    );
  }

  /**
   * Получение схем результата расчётов
   * Get Techprocess Calculation Result Schemas
   */
  async techProcessGetTechprocessCalculationResultSchemas(
    requestParameters: TechProcessGetTechprocessCalculationResultSchemasRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<{[key: string]: RJSFSchemas}> {
    const response = await this.techProcessGetTechprocessCalculationResultSchemasRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Получение результатов расчёта с их виджетами
   * Get Techprocess Calculation Results
   */
  async techProcessGetTechprocessCalculationResultsRaw(
    requestParameters: TechProcessGetTechprocessCalculationResultsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<TechprocessCalculationResult>>> {
    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetTechprocessCalculationResults().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetTechprocessCalculationResults().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/techprocess/calculation_results`
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(TechprocessCalculationResultFromJSON)
    );
  }

  /**
   * Получение результатов расчёта с их виджетами
   * Get Techprocess Calculation Results
   */
  async techProcessGetTechprocessCalculationResults(
    requestParameters: TechProcessGetTechprocessCalculationResultsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<TechprocessCalculationResult>> {
    const response = await this.techProcessGetTechprocessCalculationResultsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Получение заданий на расчёт
   * Get Techprocess Calculation Tasks
   */
  async techProcessGetTechprocessCalculationTasksRaw(
    requestParameters: TechProcessGetTechprocessCalculationTasksRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TechprocessCalculationTaskOut>> {
    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetTechprocessCalculationTasks().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetTechprocessCalculationTasks().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/techprocess/calculation_task`
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TechprocessCalculationTaskOutFromJSON(jsonValue)
    );
  }

  /**
   * Получение заданий на расчёт
   * Get Techprocess Calculation Tasks
   */
  async techProcessGetTechprocessCalculationTasks(
    requestParameters: TechProcessGetTechprocessCalculationTasksRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TechprocessCalculationTaskOut> {
    const response = await this.techProcessGetTechprocessCalculationTasksRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get Work Zone
   */
  async techProcessGetWorkZoneRaw(
    requestParameters: TechProcessGetWorkZoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<WorkZone>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetWorkZone().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetWorkZone().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/work_zone`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => WorkZoneFromJSON(jsonValue));
  }

  /**
   * Get Work Zone
   */
  async techProcessGetWorkZone(
    requestParameters: TechProcessGetWorkZoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<WorkZone> {
    const response = await this.techProcessGetWorkZoneRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Import Blends
   */
  async techProcessImportBlendsRaw(
    requestParameters: TechProcessImportBlendsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessImportBlends().'
      );
    }

    if (requestParameters['prefix'] == null) {
      throw new runtime.RequiredError(
        'prefix',
        'Required parameter "prefix" was null or undefined when calling techProcessImportBlends().'
      );
    }

    if (requestParameters['file'] == null) {
      throw new runtime.RequiredError(
        'file',
        'Required parameter "file" was null or undefined when calling techProcessImportBlends().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['prefix'] != null) {
      queryParameters['prefix'] = requestParameters['prefix'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const consumes: runtime.Consume[] = [{contentType: 'multipart/form-data'}];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: {append(param: string, value: any): any};
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters['file'] != null) {
      formParams.append('file', requestParameters['file'] as any);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/blends/import`.replace(
          `{${'project_uuid'}}`,
          encodeURIComponent(String(requestParameters['projectUuid']))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Import Blends
   */
  async techProcessImportBlends(
    requestParameters: TechProcessImportBlendsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.techProcessImportBlendsRaw(requestParameters, initOverrides);
  }

  /**
   * Remove Connection
   */
  async techProcessRemoveConnectionRaw(
    requestParameters: TechProcessRemoveConnectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessRemoveConnection().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessRemoveConnection().'
      );
    }

    if (requestParameters['bodyTechProcessRemoveConnection'] == null) {
      throw new runtime.RequiredError(
        'bodyTechProcessRemoveConnection',
        'Required parameter "bodyTechProcessRemoveConnection" was null or undefined when calling techProcessRemoveConnection().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/connect`
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
        body: BodyTechProcessRemoveConnectionToJSON(
          requestParameters['bodyTechProcessRemoveConnection']
        )
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Remove Connection
   */
  async techProcessRemoveConnection(
    requestParameters: TechProcessRemoveConnectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.techProcessRemoveConnectionRaw(requestParameters, initOverrides);
  }

  /**
   * Run Element Callback
   */
  async techProcessRunElementCallbackRaw(
    requestParameters: TechProcessRunElementCallbackRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CallbackRunResponse>> {
    if (requestParameters['elementUuid'] == null) {
      throw new runtime.RequiredError(
        'elementUuid',
        'Required parameter "elementUuid" was null or undefined when calling techProcessRunElementCallback().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessRunElementCallback().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessRunElementCallback().'
      );
    }

    if (requestParameters['callbackRunRequest'] == null) {
      throw new runtime.RequiredError(
        'callbackRunRequest',
        'Required parameter "callbackRunRequest" was null or undefined when calling techProcessRunElementCallback().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/elements/{element_uuid}/run_callback`
          .replace(
            `{${'element_uuid'}}`,
            encodeURIComponent(String(requestParameters['elementUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CallbackRunRequestToJSON(requestParameters['callbackRunRequest'])
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CallbackRunResponseFromJSON(jsonValue)
    );
  }

  /**
   * Run Element Callback
   */
  async techProcessRunElementCallback(
    requestParameters: TechProcessRunElementCallbackRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CallbackRunResponse> {
    const response = await this.techProcessRunElementCallbackRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Run Logical Element Callback
   */
  async techProcessRunLogicalElementCallbackRaw(
    requestParameters: TechProcessRunLogicalElementCallbackRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CallbackRunResponse>> {
    if (requestParameters['logicalElementUuid'] == null) {
      throw new runtime.RequiredError(
        'logicalElementUuid',
        'Required parameter "logicalElementUuid" was null or undefined when calling techProcessRunLogicalElementCallback().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessRunLogicalElementCallback().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessRunLogicalElementCallback().'
      );
    }

    if (requestParameters['callbackRunRequest'] == null) {
      throw new runtime.RequiredError(
        'callbackRunRequest',
        'Required parameter "callbackRunRequest" was null or undefined when calling techProcessRunLogicalElementCallback().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/logical_elements/{logical_element_uuid}/run_callback`
          .replace(
            `{${'logical_element_uuid'}}`,
            encodeURIComponent(String(requestParameters['logicalElementUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CallbackRunRequestToJSON(requestParameters['callbackRunRequest'])
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CallbackRunResponseFromJSON(jsonValue)
    );
  }

  /**
   * Run Logical Element Callback
   */
  async techProcessRunLogicalElementCallback(
    requestParameters: TechProcessRunLogicalElementCallbackRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CallbackRunResponse> {
    const response = await this.techProcessRunLogicalElementCallbackRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Run Model Callback
   */
  async techProcessRunModelCallbackRaw(
    requestParameters: TechProcessRunModelCallbackRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CallbackRunResponse>> {
    if (requestParameters['modelInstanceUuid'] == null) {
      throw new runtime.RequiredError(
        'modelInstanceUuid',
        'Required parameter "modelInstanceUuid" was null or undefined when calling techProcessRunModelCallback().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessRunModelCallback().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessRunModelCallback().'
      );
    }

    if (requestParameters['callbackRunRequest'] == null) {
      throw new runtime.RequiredError(
        'callbackRunRequest',
        'Required parameter "callbackRunRequest" was null or undefined when calling techProcessRunModelCallback().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/models/{model_instance_uuid}/run_callback`
          .replace(
            `{${'model_instance_uuid'}}`,
            encodeURIComponent(String(requestParameters['modelInstanceUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CallbackRunRequestToJSON(requestParameters['callbackRunRequest'])
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CallbackRunResponseFromJSON(jsonValue)
    );
  }

  /**
   * Run Model Callback
   */
  async techProcessRunModelCallback(
    requestParameters: TechProcessRunModelCallbackRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CallbackRunResponse> {
    const response = await this.techProcessRunModelCallbackRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Запуск задания на расчёт
   * Run Techprocess Calculation Task
   */
  async techProcessRunTechprocessCalculationTaskRaw(
    requestParameters: TechProcessRunTechprocessCalculationTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CalculationTaskRunStatus>> {
    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessRunTechprocessCalculationTask().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessRunTechprocessCalculationTask().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/techprocess/calculation_tasks/run`
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CalculationTaskRunStatusFromJSON(jsonValue)
    );
  }

  /**
   * Запуск задания на расчёт
   * Run Techprocess Calculation Task
   */
  async techProcessRunTechprocessCalculationTask(
    requestParameters: TechProcessRunTechprocessCalculationTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CalculationTaskRunStatus> {
    const response = await this.techProcessRunTechprocessCalculationTaskRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Обновление дополнительных параметров потока
   * Set Element Additional Tabs
   */
  async techProcessSetElementAdditionalTabsRaw(
    requestParameters: TechProcessSetElementAdditionalTabsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<{[key: string]: RJSFSchemas}>> {
    if (requestParameters['elementUuid'] == null) {
      throw new runtime.RequiredError(
        'elementUuid',
        'Required parameter "elementUuid" was null or undefined when calling techProcessSetElementAdditionalTabs().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessSetElementAdditionalTabs().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessSetElementAdditionalTabs().'
      );
    }

    if (requestParameters['additionalTabsInfo'] == null) {
      throw new runtime.RequiredError(
        'additionalTabsInfo',
        'Required parameter "additionalTabsInfo" was null or undefined when calling techProcessSetElementAdditionalTabs().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/elements/{element_uuid}/tabs`
          .replace(
            `{${'element_uuid'}}`,
            encodeURIComponent(String(requestParameters['elementUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: AdditionalTabsInfoToJSON(requestParameters['additionalTabsInfo'])
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      runtime.mapValues(jsonValue, RJSFSchemasFromJSON)
    );
  }

  /**
   * Обновление дополнительных параметров потока
   * Set Element Additional Tabs
   */
  async techProcessSetElementAdditionalTabs(
    requestParameters: TechProcessSetElementAdditionalTabsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<{[key: string]: RJSFSchemas}> {
    const response = await this.techProcessSetElementAdditionalTabsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Задание списка избранных компонентов
   * Set Favorite Pure Components
   */
  async techProcessSetFavoritePureComponentsRaw(
    requestParameters: TechProcessSetFavoritePureComponentsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ResponseStatusListUUID>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessSetFavoritePureComponents().'
      );
    }

    if (requestParameters['requestBody'] == null) {
      throw new runtime.RequiredError(
        'requestBody',
        'Required parameter "requestBody" was null or undefined when calling techProcessSetFavoritePureComponents().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/techprocess/favorite_pure_components`.replace(
          `{${'project_uuid'}}`,
          encodeURIComponent(String(requestParameters['projectUuid']))
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters['requestBody']
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResponseStatusListUUIDFromJSON(jsonValue)
    );
  }

  /**
   * Задание списка избранных компонентов
   * Set Favorite Pure Components
   */
  async techProcessSetFavoritePureComponents(
    requestParameters: TechProcessSetFavoritePureComponentsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ResponseStatusListUUID> {
    const response = await this.techProcessSetFavoritePureComponentsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Обновление задания на расчёт
   * Set Techprocess Calculation Task
   */
  async techProcessSetTechprocessCalculationTaskRaw(
    requestParameters: TechProcessSetTechprocessCalculationTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TechprocessCalculationTaskOut>> {
    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessSetTechprocessCalculationTask().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessSetTechprocessCalculationTask().'
      );
    }

    if (requestParameters['techprocessCalculationTaskUpdate'] == null) {
      throw new runtime.RequiredError(
        'techprocessCalculationTaskUpdate',
        'Required parameter "techprocessCalculationTaskUpdate" was null or undefined when calling techProcessSetTechprocessCalculationTask().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/techprocess/calculation_task`
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: TechprocessCalculationTaskUpdateToJSON(
          requestParameters['techprocessCalculationTaskUpdate']
        )
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TechprocessCalculationTaskOutFromJSON(jsonValue)
    );
  }

  /**
   * Обновление задания на расчёт
   * Set Techprocess Calculation Task
   */
  async techProcessSetTechprocessCalculationTask(
    requestParameters: TechProcessSetTechprocessCalculationTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TechprocessCalculationTaskOut> {
    const response = await this.techProcessSetTechprocessCalculationTaskRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update Element Instance
   */
  async techProcessUpdateElementInstanceRaw(
    requestParameters: TechProcessUpdateElementInstanceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ElementUpdateOut>> {
    if (requestParameters['elementUuid'] == null) {
      throw new runtime.RequiredError(
        'elementUuid',
        'Required parameter "elementUuid" was null or undefined when calling techProcessUpdateElementInstance().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessUpdateElementInstance().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessUpdateElementInstance().'
      );
    }

    if (requestParameters['body'] == null) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter "body" was null or undefined when calling techProcessUpdateElementInstance().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['tabCode'] != null) {
      queryParameters['tab_code'] = requestParameters['tabCode'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/elements/{element_uuid}`
          .replace(
            `{${'element_uuid'}}`,
            encodeURIComponent(String(requestParameters['elementUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters['body'] as any
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ElementUpdateOutFromJSON(jsonValue)
    );
  }

  /**
   * Update Element Instance
   */
  async techProcessUpdateElementInstance(
    requestParameters: TechProcessUpdateElementInstanceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ElementUpdateOut> {
    const response = await this.techProcessUpdateElementInstanceRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update Element Instance Position
   */
  async techProcessUpdateElementInstancePositionRaw(
    requestParameters: TechProcessUpdateElementInstancePositionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['elementUuid'] == null) {
      throw new runtime.RequiredError(
        'elementUuid',
        'Required parameter "elementUuid" was null or undefined when calling techProcessUpdateElementInstancePosition().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessUpdateElementInstancePosition().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessUpdateElementInstancePosition().'
      );
    }

    if (requestParameters['positionInfo'] == null) {
      throw new runtime.RequiredError(
        'positionInfo',
        'Required parameter "positionInfo" was null or undefined when calling techProcessUpdateElementInstancePosition().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/elements/{element_uuid}/position`
          .replace(
            `{${'element_uuid'}}`,
            encodeURIComponent(String(requestParameters['elementUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PositionInfoToJSON(requestParameters['positionInfo'])
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Update Element Instance Position
   */
  async techProcessUpdateElementInstancePosition(
    requestParameters: TechProcessUpdateElementInstancePositionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.techProcessUpdateElementInstancePositionRaw(requestParameters, initOverrides);
  }

  /**
   * Update Element Instances Group Position
   */
  async techProcessUpdateElementInstancesGroupPositionRaw(
    requestParameters: TechProcessUpdateElementInstancesGroupPositionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessUpdateElementInstancesGroupPosition().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessUpdateElementInstancesGroupPosition().'
      );
    }

    if (requestParameters['positionsInfo'] == null) {
      throw new runtime.RequiredError(
        'positionsInfo',
        'Required parameter "positionsInfo" was null or undefined when calling techProcessUpdateElementInstancesGroupPosition().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/elements_group/positions`
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PositionsInfoToJSON(requestParameters['positionsInfo'])
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Update Element Instances Group Position
   */
  async techProcessUpdateElementInstancesGroupPosition(
    requestParameters: TechProcessUpdateElementInstancesGroupPositionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.techProcessUpdateElementInstancesGroupPositionRaw(requestParameters, initOverrides);
  }

  /**
   * Update Logical Element Instance
   */
  async techProcessUpdateLogicalElementInstanceRaw(
    requestParameters: TechProcessUpdateLogicalElementInstanceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<LogicalUpdateOut>> {
    if (requestParameters['logicalElementUuid'] == null) {
      throw new runtime.RequiredError(
        'logicalElementUuid',
        'Required parameter "logicalElementUuid" was null or undefined when calling techProcessUpdateLogicalElementInstance().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessUpdateLogicalElementInstance().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessUpdateLogicalElementInstance().'
      );
    }

    if (requestParameters['body'] == null) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter "body" was null or undefined when calling techProcessUpdateLogicalElementInstance().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/logical_elements/{logical_element_uuid}`
          .replace(
            `{${'logical_element_uuid'}}`,
            encodeURIComponent(String(requestParameters['logicalElementUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters['body'] as any
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LogicalUpdateOutFromJSON(jsonValue)
    );
  }

  /**
   * Update Logical Element Instance
   */
  async techProcessUpdateLogicalElementInstance(
    requestParameters: TechProcessUpdateLogicalElementInstanceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<LogicalUpdateOut> {
    const response = await this.techProcessUpdateLogicalElementInstanceRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update Model Instance
   */
  async techProcessUpdateModelInstanceRaw(
    requestParameters: TechProcessUpdateModelInstanceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AppProjectsTechprocessSchemasUpdateOut>> {
    if (requestParameters['modelInstanceUuid'] == null) {
      throw new runtime.RequiredError(
        'modelInstanceUuid',
        'Required parameter "modelInstanceUuid" was null or undefined when calling techProcessUpdateModelInstance().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessUpdateModelInstance().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessUpdateModelInstance().'
      );
    }

    if (requestParameters['body'] == null) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter "body" was null or undefined when calling techProcessUpdateModelInstance().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/models/{model_instance_uuid}`
          .replace(
            `{${'model_instance_uuid'}}`,
            encodeURIComponent(String(requestParameters['modelInstanceUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters['body'] as any
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AppProjectsTechprocessSchemasUpdateOutFromJSON(jsonValue)
    );
  }

  /**
   * Update Model Instance
   */
  async techProcessUpdateModelInstance(
    requestParameters: TechProcessUpdateModelInstanceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AppProjectsTechprocessSchemasUpdateOut> {
    const response = await this.techProcessUpdateModelInstanceRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Изменение параметров отображения результатов расчёта
   * Update Techprocess Calculation Result
   */
  async techProcessUpdateTechprocessCalculationResultRaw(
    requestParameters: TechProcessUpdateTechprocessCalculationResultRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CalculationResultUpdateOut>> {
    if (requestParameters['calculationResultUuid'] == null) {
      throw new runtime.RequiredError(
        'calculationResultUuid',
        'Required parameter "calculationResultUuid" was null or undefined when calling techProcessUpdateTechprocessCalculationResult().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessUpdateTechprocessCalculationResult().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessUpdateTechprocessCalculationResult().'
      );
    }

    if (requestParameters['body'] == null) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter "body" was null or undefined when calling techProcessUpdateTechprocessCalculationResult().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/techprocess/calculation_results/{calculation_result_uuid}`
          .replace(
            `{${'calculation_result_uuid'}}`,
            encodeURIComponent(String(requestParameters['calculationResultUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters['body'] as any
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CalculationResultUpdateOutFromJSON(jsonValue)
    );
  }

  /**
   * Изменение параметров отображения результатов расчёта
   * Update Techprocess Calculation Result
   */
  async techProcessUpdateTechprocessCalculationResult(
    requestParameters: TechProcessUpdateTechprocessCalculationResultRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CalculationResultUpdateOut> {
    const response = await this.techProcessUpdateTechprocessCalculationResultRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
