/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {BaseModelOut} from './BaseModelOut';
import {BaseModelOutFromJSON, BaseModelOutFromJSONTyped, BaseModelOutToJSON} from './BaseModelOut';
import type {CreateElementActionResultData} from './CreateElementActionResultData';
import {
  CreateElementActionResultDataFromJSON,
  CreateElementActionResultDataFromJSONTyped,
  CreateElementActionResultDataToJSON
} from './CreateElementActionResultData';
import type {ModelOut} from './ModelOut';
import {ModelOutFromJSON, ModelOutFromJSONTyped, ModelOutToJSON} from './ModelOut';
import type {ElementOut} from './ElementOut';
import {ElementOutFromJSON, ElementOutFromJSONTyped, ElementOutToJSON} from './ElementOut';
import type {ElementClassName} from './ElementClassName';
import {
  ElementClassNameFromJSON,
  ElementClassNameFromJSONTyped,
  ElementClassNameToJSON
} from './ElementClassName';

/**
 *
 * @export
 * @interface Data1
 */
export interface Data1 {
  /**
   *
   * @type {boolean}
   * @memberof Data1
   */
  modelsChanged: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Data1
   */
  elementsChanged: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Data1
   */
  logicalElementsChanged: boolean;
  /**
   *
   * @type {ElementOut}
   * @memberof Data1
   */
  element: ElementOut;
  /**
   *
   * @type {string}
   * @memberof Data1
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof Data1
   */
  name: string;
  /**
   *
   * @type {ElementClassName}
   * @memberof Data1
   */
  type: ElementClassName;
  /**
   *
   * @type {string}
   * @memberof Data1
   */
  typeUuid: string;
  /**
   *
   * @type {boolean}
   * @memberof Data1
   */
  deletable?: boolean;
  /**
   *
   * @type {Array<BaseModelOut>}
   * @memberof Data1
   */
  subModels?: Array<BaseModelOut> | null;
}

/**
 * Check if a given object implements the Data1 interface.
 */
export function instanceOfData1(value: object): value is Data1 {
  if (!('modelsChanged' in value) || value['modelsChanged'] === undefined) return false;
  if (!('elementsChanged' in value) || value['elementsChanged'] === undefined) return false;
  if (!('logicalElementsChanged' in value) || value['logicalElementsChanged'] === undefined)
    return false;
  if (!('element' in value) || value['element'] === undefined) return false;
  if (!('uuid' in value) || value['uuid'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('type' in value) || value['type'] === undefined) return false;
  if (!('typeUuid' in value) || value['typeUuid'] === undefined) return false;
  return true;
}

export function Data1FromJSON(json: any): Data1 {
  return Data1FromJSONTyped(json, false);
}

export function Data1FromJSONTyped(json: any, ignoreDiscriminator: boolean): Data1 {
  if (json == null) {
    return json;
  }
  return {
    modelsChanged: json['models_changed'],
    elementsChanged: json['elements_changed'],
    logicalElementsChanged: json['logical_elements_changed'],
    element: ElementOutFromJSON(json['element']),
    uuid: json['uuid'],
    name: json['name'],
    type: ElementClassNameFromJSON(json['type']),
    typeUuid: json['type_uuid'],
    deletable: json['deletable'] == null ? undefined : json['deletable'],
    subModels:
      json['sub_models'] == null
        ? undefined
        : (json['sub_models'] as Array<any>).map(BaseModelOutFromJSON)
  };
}

export function Data1ToJSON(value?: Data1 | null): any {
  if (value == null) {
    return value;
  }
  return {
    models_changed: value['modelsChanged'],
    elements_changed: value['elementsChanged'],
    logical_elements_changed: value['logicalElementsChanged'],
    element: ElementOutToJSON(value['element']),
    uuid: value['uuid'],
    name: value['name'],
    type: ElementClassNameToJSON(value['type']),
    type_uuid: value['typeUuid'],
    deletable: value['deletable'],
    sub_models:
      value['subModels'] == null
        ? undefined
        : (value['subModels'] as Array<any>).map(BaseModelOutToJSON)
  };
}
