/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {ElementClassName} from './ElementClassName';
import {
  ElementClassNameFromJSON,
  ElementClassNameFromJSONTyped,
  ElementClassNameToJSON
} from './ElementClassName';

/**
 *
 * @export
 * @interface BaseModelOut
 */
export interface BaseModelOut {
  /**
   *
   * @type {string}
   * @memberof BaseModelOut
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof BaseModelOut
   */
  name: string;
  /**
   *
   * @type {ElementClassName}
   * @memberof BaseModelOut
   */
  type: ElementClassName;
  /**
   *
   * @type {string}
   * @memberof BaseModelOut
   */
  typeUuid: string;
  /**
   *
   * @type {boolean}
   * @memberof BaseModelOut
   */
  deletable?: boolean;
}

/**
 * Check if a given object implements the BaseModelOut interface.
 */
export function instanceOfBaseModelOut(value: object): value is BaseModelOut {
  if (!('uuid' in value) || value['uuid'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('type' in value) || value['type'] === undefined) return false;
  if (!('typeUuid' in value) || value['typeUuid'] === undefined) return false;
  return true;
}

export function BaseModelOutFromJSON(json: any): BaseModelOut {
  return BaseModelOutFromJSONTyped(json, false);
}

export function BaseModelOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseModelOut {
  if (json == null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    name: json['name'],
    type: ElementClassNameFromJSON(json['type']),
    typeUuid: json['type_uuid'],
    deletable: json['deletable'] == null ? undefined : json['deletable']
  };
}

export function BaseModelOutToJSON(value?: BaseModelOut | null): any {
  if (value == null) {
    return value;
  }
  return {
    uuid: value['uuid'],
    name: value['name'],
    type: ElementClassNameToJSON(value['type']),
    type_uuid: value['typeUuid'],
    deletable: value['deletable']
  };
}
