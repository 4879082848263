/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {CreateElementActionResultData} from './CreateElementActionResultData';
import {
  CreateElementActionResultDataFromJSON,
  CreateElementActionResultDataFromJSONTyped,
  CreateElementActionResultDataToJSON
} from './CreateElementActionResultData';

/**
 *
 * @export
 * @interface CreateElementActionResult
 */
export interface CreateElementActionResult {
  /**
   *
   * @type {boolean}
   * @memberof CreateElementActionResult
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateElementActionResult
   */
  errorMsg?: string | null;
  /**
   *
   * @type {CreateElementActionResultData}
   * @memberof CreateElementActionResult
   */
  data: CreateElementActionResultData;
}

/**
 * Check if a given object implements the CreateElementActionResult interface.
 */
export function instanceOfCreateElementActionResult(
  value: object
): value is CreateElementActionResult {
  if (!('success' in value) || value['success'] === undefined) return false;
  if (!('data' in value) || value['data'] === undefined) return false;
  return true;
}

export function CreateElementActionResultFromJSON(json: any): CreateElementActionResult {
  return CreateElementActionResultFromJSONTyped(json, false);
}

export function CreateElementActionResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateElementActionResult {
  if (json == null) {
    return json;
  }
  return {
    success: json['success'],
    errorMsg: json['error_msg'] == null ? undefined : json['error_msg'],
    data: CreateElementActionResultDataFromJSON(json['data'])
  };
}

export function CreateElementActionResultToJSON(value?: CreateElementActionResult | null): any {
  if (value == null) {
    return value;
  }
  return {
    success: value['success'],
    error_msg: value['errorMsg'],
    data: CreateElementActionResultDataToJSON(value['data'])
  };
}
