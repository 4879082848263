import {observer} from 'mobx-react-lite';
import {Box, Button, Flex, Text} from '@chakra-ui/react';
import {DropzoneContainer, Input, Svg} from '@progress-fe/ui-kit';
import {useT} from '@progress-fe/core';
import {FC} from 'react';

interface IProps {
  searchQuery: string;
  onCreate: () => void;
  onImport: (file: File) => Promise<void>;
  onSearch: (searchQuery: string) => void;
}

const ProjectsActionsFC: FC<IProps> = ({searchQuery, onCreate, onSearch, onImport}) => {
  const {t} = useT();

  return (
    <Flex gap="12px">
      {/* SEARCH */}
      <Box width="240px">
        <Input
          size="md"
          variant="outline"
          placeholder={t('actions.search')}
          value={searchQuery}
          leftElement={<Svg name={'Search'} />}
          onChange={onSearch}
        />
      </Box>

      {/* IMPORT */}
      <DropzoneContainer onSelectFile={onImport}>
        <Button variant="ghost" size="md">
          <Svg name={'Import'} />
          <Text>{t('actions.upload')}</Text>
        </Button>
      </DropzoneContainer>

      {/* CREATE A NEW */}
      <Button variant="solid" size="md" onClick={onCreate}>
        <Box color={'white'}>
          <Svg name={'Plus'} />
        </Box>
        <Text color={'white'}>{t('projects.create')}</Text>
      </Button>
    </Flex>
  );
};

export const ProjectsActions = observer(ProjectsActionsFC);
