import {FC, DragEvent} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Center, useToken} from '@chakra-ui/react';
import {Tooltip} from 'react-tooltip';
import {v4 as uuidv4} from 'uuid';
import {ERFElement, IRFMenuItem, RFSvg} from '@progress-fe/rf-core';

interface IProps {
  item: IRFMenuItem;
  isDragging: boolean;
  updateIsDragging: (isDragging: boolean) => void;
  onDragStart?: (event: DragEvent<HTMLDivElement>, type: ERFElement, template: string) => void;
  onClick?: () => void;
}

const RFMenuItemFC: FC<IProps> = ({item, isDragging, updateIsDragging, onDragStart, onClick}) => {
  const [borderHex, blackHex, bgHex] = useToken('colors', ['border', 'black', 'bg']);

  const tooltipId = uuidv4();

  return (
    <Box>
      <Center
        width="40px"
        height="40px"
        bg="buttonBg"
        borderRadius="4px"
        data-tooltip-id={tooltipId}
        data-tooltip-place="right"
        draggable={item.isDraggable}
        opacity={item.isDisabled ? '0.5' : '1'}
        _hover={{cursor: item.isDraggable ? 'grab' : 'pointer'}}
        _before={
          item.items
            ? {
                content: '" "',
                position: 'absolute',
                borderStyle: 'solid',
                borderWidth: '0 0 6px 6px',
                borderColor: `transparent transparent ${blackHex} transparent`,
                bottom: 0,
                right: 0,
                width: 0,
                height: 0
              }
            : {}
        }
        _after={
          item.items
            ? {
                content: '" "',
                position: 'absolute',
                borderStyle: 'solid',
                borderWidth: '0 0 1px 1px',
                borderColor: `transparent transparent ${bgHex} transparent`,
                bottom: 0,
                right: 0,
                width: 0,
                height: 0
              }
            : {}
        }
        onDragStart={(event) => {
          if (item.isDraggable && item.type) {
            onDragStart?.(event, item.type, item.templateCode || '');
            updateIsDragging(true);
          }
        }}
        onClick={!item.isDisabled ? onClick || item.onClick : undefined}
        onDragEnd={() => updateIsDragging(false)}
        className={item.isDraggable ? 'dndnode' : ''}
      >
        <RFSvg name={item.icon} size={item.iconSize || 's32'} />
      </Center>

      {!!item.name && !isDragging && (
        <Tooltip
          id={tooltipId}
          delayShow={250}
          positionStrategy="absolute"
          border={`1px solid ${borderHex}`}
          closeEvents={{click: true, mouseleave: true}}
          style={{padding: '0', backgroundColor: 'white', zIndex: '1'}}
          opacity="1"
        >
          <Box color="bodyText" p="6px 10px" fontSize="12px">
            {item.name}
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};

export const RFMenuItem = observer(RFMenuItemFC);
