import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {useT} from '@progress-fe/core';
import {Box, Flex, IconButton} from '@chakra-ui/react';
import {ISelectOption, SelectTabs, Svg} from '@progress-fe/ui-kit';

import {EJournalItemType} from 'core/enums';

interface IProps {
  filterType: EJournalItemType | null;
  onSelectFilterType: (filterType: EJournalItemType | null) => void;
  onClose: () => void;
}

const JournalTabsFC: FC<IProps> = ({filterType, onSelectFilterType, onClose}) => {
  const {t} = useT();

  const options: ISelectOption<EJournalItemType | null>[] = [
    {value: null, label: t('common.all')},
    {value: EJournalItemType.Warning, label: t(`enum.journalItemType.${EJournalItemType.Warning}`)},
    {value: EJournalItemType.Error, label: t(`enum.journalItemType.${EJournalItemType.Error}`)}
  ];

  return (
    <Flex
      p={'4px'}
      alignItems={'center'}
      justifyContent={'space-between'}
      borderBottom={'1px solid'}
      borderColor={'border'}
    >
      <Box width="240px">
        <SelectTabs options={options} value={filterType} onChange={onSelectFilterType} />
      </Box>

      <IconButton
        aria-label=""
        size="smSquare"
        variant="tr"
        icon={<Svg size="s12" name="Cross" />}
        onClick={onClose}
      />
    </Flex>
  );
};

export const JournalTabs = observer(JournalTabsFC);
