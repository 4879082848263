/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {FieldValue} from './FieldValue';
import {FieldValueFromJSON, FieldValueFromJSONTyped, FieldValueToJSON} from './FieldValue';
import type {RJSFSchemas} from './RJSFSchemas';
import {RJSFSchemasFromJSON, RJSFSchemasFromJSONTyped, RJSFSchemasToJSON} from './RJSFSchemas';

/**
 *
 * @export
 * @interface CallbackRunResponse
 */
export interface CallbackRunResponse {
  /**
   *
   * @type {boolean}
   * @memberof CallbackRunResponse
   */
  success: boolean;
  /**
   *
   * @type {FieldValue}
   * @memberof CallbackRunResponse
   */
  fieldValue?: FieldValue | null;
  /**
   *
   * @type {{ [key: string]: RJSFSchemas; }}
   * @memberof CallbackRunResponse
   */
  schemas?: {[key: string]: RJSFSchemas} | null;
  /**
   *
   * @type {Array<object>}
   * @memberof CallbackRunResponse
   */
  errors?: Array<object> | null;
}

/**
 * Check if a given object implements the CallbackRunResponse interface.
 */
export function instanceOfCallbackRunResponse(value: object): value is CallbackRunResponse {
  if (!('success' in value) || value['success'] === undefined) return false;
  return true;
}

export function CallbackRunResponseFromJSON(json: any): CallbackRunResponse {
  return CallbackRunResponseFromJSONTyped(json, false);
}

export function CallbackRunResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CallbackRunResponse {
  if (json == null) {
    return json;
  }
  return {
    success: json['success'],
    fieldValue: json['field_value'] == null ? undefined : FieldValueFromJSON(json['field_value']),
    schemas: json['schemas'] == null ? undefined : mapValues(json['schemas'], RJSFSchemasFromJSON),
    errors: json['errors'] == null ? undefined : json['errors']
  };
}

export function CallbackRunResponseToJSON(value?: CallbackRunResponse | null): any {
  if (value == null) {
    return value;
  }
  return {
    success: value['success'],
    field_value: FieldValueToJSON(value['fieldValue']),
    schemas: value['schemas'] == null ? undefined : mapValues(value['schemas'], RJSFSchemasToJSON),
    errors: value['errors']
  };
}
