/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {BaseModelOut} from './BaseModelOut';
import {BaseModelOutFromJSON, BaseModelOutFromJSONTyped, BaseModelOutToJSON} from './BaseModelOut';
import type {ElementClassName} from './ElementClassName';
import {
  ElementClassNameFromJSON,
  ElementClassNameFromJSONTyped,
  ElementClassNameToJSON
} from './ElementClassName';

/**
 *
 * @export
 * @interface ModelOut
 */
export interface ModelOut {
  /**
   *
   * @type {string}
   * @memberof ModelOut
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof ModelOut
   */
  name: string;
  /**
   *
   * @type {ElementClassName}
   * @memberof ModelOut
   */
  type: ElementClassName;
  /**
   *
   * @type {string}
   * @memberof ModelOut
   */
  typeUuid: string;
  /**
   *
   * @type {boolean}
   * @memberof ModelOut
   */
  deletable?: boolean;
  /**
   *
   * @type {Array<BaseModelOut>}
   * @memberof ModelOut
   */
  subModels?: Array<BaseModelOut> | null;
}

/**
 * Check if a given object implements the ModelOut interface.
 */
export function instanceOfModelOut(value: object): value is ModelOut {
  if (!('uuid' in value) || value['uuid'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('type' in value) || value['type'] === undefined) return false;
  if (!('typeUuid' in value) || value['typeUuid'] === undefined) return false;
  return true;
}

export function ModelOutFromJSON(json: any): ModelOut {
  return ModelOutFromJSONTyped(json, false);
}

export function ModelOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelOut {
  if (json == null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    name: json['name'],
    type: ElementClassNameFromJSON(json['type']),
    typeUuid: json['type_uuid'],
    deletable: json['deletable'] == null ? undefined : json['deletable'],
    subModels:
      json['sub_models'] == null
        ? undefined
        : (json['sub_models'] as Array<any>).map(BaseModelOutFromJSON)
  };
}

export function ModelOutToJSON(value?: ModelOut | null): any {
  if (value == null) {
    return value;
  }
  return {
    uuid: value['uuid'],
    name: value['name'],
    type: ElementClassNameToJSON(value['type']),
    type_uuid: value['typeUuid'],
    deletable: value['deletable'],
    sub_models:
      value['subModels'] == null
        ? undefined
        : (value['subModels'] as Array<any>).map(BaseModelOutToJSON)
  };
}
