import {applySnapshot, cast, flow, getSnapshot, Instance, types} from 'mobx-state-tree';
import {TRFEdgeDataConfig, TRFGraphDataConfig, TRFGraphZone} from '@progress-fe/rf-core';
import {ELogicalElement, i18n} from '@progress-fe/core';
import {ISvgMenuItem} from '@progress-fe/ui-kit';
import {Edge, Node} from '@xyflow/react';
import {v4 as uuidv4} from 'uuid';

import {GRAPH_LIST} from 'core/mocks/projects/Separators/graph.mocks';
import {ETaskType} from 'core/enums';
import {
  ResetModel,
  RequestModel,
  JsonSchemaForm,
  LogicalElementInfo,
  TLogicalElementInfoModel
} from 'core/models';
import {
  GraphZone,
  RJSFSchemas,
  TechProcessApi,
  LogicalUpdateOut,
  LogicalElementsOut,
  TechprocessCalculationTaskOut,
  CreateLogicalElementActionResult
} from 'api';

const ProjectTask = types
  .compose(
    ResetModel,
    types.model('ProjectTask', {
      projectUuid: '',
      checkpointUuid: '',
      graphzoneLastUpdate: new Date(),

      description: types.optional(types.string, ''),
      type: types.optional(types.enumeration(Object.values(ETaskType)), ETaskType.Base),
      logicalElements: types.optional(types.array(LogicalElementInfo), []),
      jsonForm: types.maybeNull(JsonSchemaForm),

      nodes: types.optional(types.array(types.frozen<Node<TRFGraphDataConfig>>()), []),
      edges: types.optional(types.array(types.frozen<Edge<TRFEdgeDataConfig>>()), []),

      createRequest: types.optional(RequestModel, {}),
      duplicateRequest: types.optional(RequestModel, {}),
      deleteRequest: types.optional(RequestModel, {}),
      graphFetchRequest: types.optional(RequestModel, {}),
      logicalFetchRequest: types.optional(RequestModel, {}),
      taskFetchRequest: types.optional(RequestModel, {}),
      taskFormDataRequest: types.optional(RequestModel, {})
    })
  )
  .actions((self) => ({
    _clearJsonForm() {
      self.jsonForm?.clear();
      self.jsonForm = null;
    },
    _reloadJsonForm: flow(function* (uuid: string) {
      self.jsonForm = JsonSchemaForm.create({entityUuid: uuid});
      const response: {[key: string]: RJSFSchemas} = yield self.jsonForm.fetchRequest.send(
        TechProcessApi.techProcessGetLogicalElementSchemas.bind(TechProcessApi),
        {
          logicalElementUuid: uuid,
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (response) {
        self.jsonForm = JsonSchemaForm.create({entityUuid: uuid});
        self.jsonForm.setJsonSchemas(response);
      }
    })
  }))
  .actions((self) => ({
    add: flow(function* (type: ELogicalElement) {
      const response: CreateLogicalElementActionResult = yield self.createRequest.send(
        TechProcessApi.techProcessCreateLogicalElement.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          idempotencyKey: uuidv4(),
          logicalElementIn: {
            type: type
          }
        }
      );

      return self.createRequest.isDone && !!response
        ? LogicalElementInfo.create({
            uuid: response.data.uuid,
            name: response.data.name,
            lastUpdated: new Date(),
            deletable: response.data.deletable,
            logicalType: response.data.type as ELogicalElement
          })
        : null;
    }),
    duplicate: flow(function* (uuid: string) {
      const response: CreateLogicalElementActionResult = yield self.duplicateRequest.send(
        TechProcessApi.techProcessDuplicateLogicalElement.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          idempotencyKey: uuidv4(),
          elementUuid: uuid
        }
      );

      return response?.data.uuid;
    }),
    remove: flow(function* (uuid: string) {
      yield self.deleteRequest.send(
        TechProcessApi.techProcessDeleteLogicalElement.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          logicalElementUuid: uuid
        }
      );
      return self.deleteRequest.isDone;
    })
  }))
  .actions((self) => ({
    _updateLogicalElementName(uuid: string, name: string) {
      const element = self.logicalElements.find((n) => n.uuid === uuid);
      element?.setName(name);
    }
  }))
  .actions((self) => ({
    _updateLogicalElementFormData: flow(function* (uuid: string, schemaId: string, data: unknown) {
      const jsonSchema = self.jsonForm?.jsonSchemas.find((js) => js.id === schemaId);
      if (!self.jsonForm || !jsonSchema) {
        return;
      }

      jsonSchema.updateFormData(data);
      const response: LogicalUpdateOut = yield self.jsonForm.updateRequest.send(
        TechProcessApi.techProcessUpdateLogicalElementInstance.bind(TechProcessApi),
        {
          logicalElementUuid: uuid,
          body: data as object,
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (!response) {
        return false;
      }

      if (response.name) {
        self._updateLogicalElementName(uuid, response.name);
      }
      if (response.schemas) {
        self.jsonForm?.setJsonSchemas(response.schemas);
      }

      return response.workzoneChanged || false;
    })
  }))
  .actions((self) => ({
    _loadLogicalElements: flow(function* () {
      const response: LogicalElementsOut = yield self.logicalFetchRequest.send(
        TechProcessApi.techProcessGetLogicalElements.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      const responseLogicalElements = response.logicalElements.map((el) => ({
        uuid: el.uuid,
        name: el.name,
        lastUpdated: new Date(),
        deletable: el.deletable,
        logicalType: el.type as ELogicalElement
      }));

      applySnapshot(self.logicalElements, responseLogicalElements);
    }),
    _loadTaskDetails: flow(function* () {
      const response: TechprocessCalculationTaskOut = yield self.taskFetchRequest.send(
        TechProcessApi.techProcessGetTechprocessCalculationTasks.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );
      self.description = response?.description || '';
    }),
    _loadGraphZone: flow(function* () {
      const mockGraphZone = GRAPH_LIST.find((d) => d.projectId === self.projectUuid);
      if (mockGraphZone) {
        self.nodes = cast(mockGraphZone.nodes);
        self.edges = cast(mockGraphZone.edges);
        return;
      }

      const response: GraphZone | null = yield self.graphFetchRequest.send(
        TechProcessApi.techProcessGetCompGraph.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (response) {
        self.nodes = cast((response as TRFGraphZone).nodes);
        self.edges = cast((response as TRFGraphZone).edges);
        self.graphzoneLastUpdate = new Date();
      } else {
        self.nodes = cast([]);
        self.edges = cast([]);
        self.graphzoneLastUpdate = new Date();
      }
    }),
    _updateDescription: flow(function* () {
      yield self.taskFormDataRequest.send(
        TechProcessApi.techProcessSetTechprocessCalculationTask.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          techprocessCalculationTaskUpdate: {
            type: self.type,
            description: self.description
          }
        }
      );
    })
  }))
  .actions((self) => ({
    _reload: flow(function* () {
      yield self._loadTaskDetails();
      yield self._loadLogicalElements();
      yield self._loadGraphZone();
    })
  }))
  .actions((self) => ({
    init: flow(function* (projectUuid: string, checkpointUuid: string) {
      self.resetModel();
      self.projectUuid = projectUuid;
      self.checkpointUuid = checkpointUuid;
      yield self._reload();
    })
  }))
  .actions((self) => ({
    setType(type: ETaskType): void {
      self.type = type;
    },
    setDescription: flow(function* (description: string) {
      self.description = description;
      yield self._updateDescription();
    }),
    hasLogicalElement(uuid: string) {
      return self.logicalElements.some((m) => m.uuid === uuid);
    },
    findLogicalElement(uuid: string): TLogicalElementInfoModel | undefined {
      return self.logicalElements.find((e) => e.uuid === uuid);
    }
  }))
  .views((self) => ({
    get nodeList() {
      return getSnapshot(self.nodes);
    },
    get edgeList() {
      return getSnapshot(self.edges);
    }
  }))
  .views((self) => ({
    get isLoading(): boolean {
      return (
        self.logicalFetchRequest.isPending ||
        self.createRequest.isPending ||
        self.duplicateRequest.isPending ||
        self.deleteRequest.isPending
      );
    },
    get isFormLoading(): boolean {
      return self.jsonForm?.isFormLoading ?? false;
    },
    get isFormDataUpdating(): boolean {
      return self.jsonForm?.isFormDataUpdating ?? false;
    },
    get isTaskFormDataUpdating(): boolean {
      return self.taskFormDataRequest.isPending;
    },
    get logicalMenuItems(): ISvgMenuItem<ELogicalElement>[] {
      return [
        {
          id: ELogicalElement.Recycle,
          name: i18n.t(`enum.logicalElement.${ELogicalElement.Recycle}`)
        },
        {
          id: ELogicalElement.Set,
          name: i18n.t(`enum.logicalElement.${ELogicalElement.Set}`),
          isDisabled: true
        },
        {
          id: ELogicalElement.Adjustment,
          name: i18n.t(`enum.logicalElement.${ELogicalElement.Adjustment}`),
          isDisabled: true
        },
        {
          id: ELogicalElement.Cutter,
          name: i18n.t(`enum.logicalElement.${ELogicalElement.Cutter}`),
          isDisabled: true
        }
      ];
    }
  }));

export type TProjectTaskModel = Instance<typeof ProjectTask>;

export {ProjectTask};
