import {Edge, Node} from '@xyflow/react';
import {ERFElement, TRFEdgeDataConfig, TRFWorkZoneDataConfig} from '@progress-fe/rf-core';

export const ELEMENTS_SAMPLE_WORKZONE_LIST: {
  projectId: string;
  projectName: string;
  nodes: Node<TRFWorkZoneDataConfig, ERFElement>[];
  edges: Edge<TRFEdgeDataConfig>[];
}[] = [
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000000',
    projectName: 'Элементы (моковый проект)',
    nodes: [
      {
        id: '196C89E0-1111-0000-0000-000000000001', // CORER-100
        type: ERFElement.Coker,
        data: {
          elementName: 'CORER-100',
          templateCode: 'universal-template_mixers_reactorblock_heater_fractionator'
        },
        position: {x: 41, y: -104},
        connectable: true,
        deletable: true
      },
      {
        id: '196C89E0-1111-0000-0000-000000000002', // PUMP-100
        type: ERFElement.Pump,
        data: {
          elementName: 'PUMP-100',
          templateCode: 'default'
        },
        position: {x: 250, y: 0},
        connectable: true,
        deletable: true
      }
    ],
    edges: []
  }
];
