import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Center, Text} from '@chakra-ui/react';
import {ISvgMenuItem, ItemListHeader, Loader, useDialog} from '@progress-fe/ui-kit';
import {useT} from '@progress-fe/core';

import {StructureItem} from 'ui-kit';
import {EActionType} from 'core/enums';
import {TBlendInfoModel} from 'core/models';

interface IProps {
  isLoading: boolean;
  isCreating: boolean;
  selectedId: string | null;
  blends: TBlendInfoModel[];
  onSelect: (uuid: string) => void;
  onCreate: () => Promise<void>;
  onDelete: (uuid: string) => Promise<void>;
  onExport: (uuid: string) => Promise<void>;
}

const BlendsListFC: FC<IProps> = ({
  isLoading,
  isCreating,
  blends,
  selectedId,
  onSelect,
  onCreate,
  onDelete,
  onExport
}) => {
  const {t} = useT();

  const DeleteDialog = useDialog({title: t('blends.delete')});

  const blendMenuItems: ISvgMenuItem<EActionType>[] = [
    {id: EActionType.Download, name: t('actions.download')},
    {id: EActionType.Duplicate, name: t('actions.duplicate'), isDisabled: true},
    {id: EActionType.Delete, name: t('actions.delete')}
  ];

  const handleOnMenuAction = (uuid: string, action: EActionType) => {
    const blend = blends.find((el) => el.uuid === uuid);
    switch (action) {
      case EActionType.Delete: {
        DeleteDialog.open(t('blends.deleteSureMsg', {name: blend?.name}), {
          apply: {title: t('actions.delete'), isDanger: true, onClick: () => onDelete(uuid)},
          close: {title: t('actions.cancel')}
        });
        break;
      }
      case EActionType.Download: {
        onExport(blend?.uuid ?? '').then();
      }
    }
  };

  return (
    <Box overflowY="hidden" height="100%">
      <DeleteDialog.Render />
      <ItemListHeader title={t('blends.blends')} isMenuDisabled={isCreating} onCreate={onCreate} />

      <Box overflowY="auto" h="calc(100% - 40px)">
        {isLoading ? (
          <Center height="100%">
            <Loader size={'32px'} thickness={'5px'} />
          </Center>
        ) : (
          <>
            {blends.map((m) => (
              <StructureItem
                key={m.uuid}
                menuItems={blendMenuItems}
                item={{
                  uuid: m.uuid,
                  name: m.name,
                  icon: 'Blend',
                  isDeletable: m.deletable,
                  isActive: selectedId === m.uuid
                }}
                onMenuAction={(action) => handleOnMenuAction(m.uuid, action)}
                onClick={onSelect}
              />
            ))}

            {blends.length === 0 && (
              <Center height="100%">
                <Text>{t('blends.noBlends')}</Text>
              </Center>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export const BlendsList = observer(BlendsListFC);
