import {applySnapshot, flow, Instance, types} from 'mobx-state-tree';

import {EWidgetType} from 'core/enums';
import {OilApi, RJSFSchemas, BlendCalculationResult, CalculationResultUpdateOut} from 'api';
import {
  ResetModel,
  RequestModel,
  JsonSchemaForm,
  BlendResultInfo,
  TBlendResultInfoModel
} from 'core/models';

const ProjectBlendsResults = types
  .compose(
    ResetModel,
    types.model('ProjectBlendsResults', {
      projectUuid: '',
      checkpointUuid: '',

      blendResults: types.optional(types.array(BlendResultInfo), []),
      jsonForm: types.maybeNull(JsonSchemaForm),

      fetchRequest: types.optional(RequestModel, {})
    })
  )
  .actions((self) => ({
    _clearJsonForm() {
      self.jsonForm?.clear();
      self.jsonForm = null;
    },
    _reloadJsonForm: flow(function* (uuid: string) {
      self.jsonForm = JsonSchemaForm.create({entityUuid: uuid});
      const response: {[key: string]: RJSFSchemas} = yield self.jsonForm.fetchRequest.send(
        OilApi.oilGetOilBlendsCalculationResultWidgetSchemas.bind(OilApi),
        {
          resultWidgetUuid: uuid,
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (response) {
        self.jsonForm = JsonSchemaForm.create({entityUuid: uuid});
        self.jsonForm.setJsonSchemas(response);
      }
    })
  }))
  .actions((self) => ({
    _reload: flow(function* () {
      const response: BlendCalculationResult[] = yield self.fetchRequest.send(
        OilApi.oilGetOilBlendsCalculationResults.bind(OilApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      const responseResults = response.map((result) => ({
        uuid: result.uuid,
        name: result.name,
        blendUuid: result.blendUuid,
        lastUpdated: new Date(),
        deletable: false,
        widget: {
          uuid: result.widget.uuid,
          name: result.widget.name,
          data: result.widget.data,
          widgetType: result.widget.widgetType as EWidgetType
        }
      }));

      applySnapshot(self.blendResults, responseResults);
    })
  }))
  .actions((self) => ({
    _updateBlendResultName(uuid: string, name: string) {
      const result = self.blendResults.find((n) => n.uuid === uuid);
      result?.setName(name);
    }
  }))
  .actions((self) => ({
    _updateBlendResultFormData: flow(function* (uuid: string, schemaId: string, data: unknown) {
      const jsonSchema = self.jsonForm?.jsonSchemas.find((js) => js.id === schemaId);
      if (!self.jsonForm || !jsonSchema) {
        return;
      }

      jsonSchema.updateFormData(data);
      const response: CalculationResultUpdateOut = yield self.jsonForm.updateRequest.send(
        OilApi.oilUpdateOilBlendCalculationResult.bind(OilApi),
        {
          resultWidgetUuid: uuid,
          body: data as object,
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (response?.name) {
        self._updateBlendResultName(uuid, response.name);
      }
    })
  }))
  .actions((self) => ({
    init: flow(function* (projectUuid: string, checkpointUuid: string) {
      self.projectUuid = projectUuid;
      self.checkpointUuid = checkpointUuid;
      yield self._reload();
    })
  }))
  .actions((self) => ({
    hasBlendResult(uuid: string) {
      return self.blendResults.some((r) => r.uuid === uuid);
    },
    findBlendResult(uuid: string): TBlendResultInfoModel | undefined {
      return self.blendResults.find((e) => e.uuid === uuid);
    }
  }))
  .views((self) => ({
    get isLoading(): boolean {
      return self.fetchRequest.isPending;
    },
    get isFormLoading(): boolean {
      return self.jsonForm?.isFormLoading ?? false;
    },
    get isFormDataUpdating(): boolean {
      return self.jsonForm?.isFormDataUpdating ?? false;
    }
  }));

export type TProjectBlendsResultsModel = Instance<typeof ProjectBlendsResults>;

export {ProjectBlendsResults};
