/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {ElementOut} from './ElementOut';
import {ElementOutFromJSON, ElementOutFromJSONTyped, ElementOutToJSON} from './ElementOut';

/**
 *
 * @export
 * @interface CreateElementActionResultData
 */
export interface CreateElementActionResultData {
  /**
   *
   * @type {boolean}
   * @memberof CreateElementActionResultData
   */
  modelsChanged: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreateElementActionResultData
   */
  elementsChanged: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreateElementActionResultData
   */
  logicalElementsChanged: boolean;
  /**
   *
   * @type {ElementOut}
   * @memberof CreateElementActionResultData
   */
  element: ElementOut;
}

/**
 * Check if a given object implements the CreateElementActionResultData interface.
 */
export function instanceOfCreateElementActionResultData(
  value: object
): value is CreateElementActionResultData {
  if (!('modelsChanged' in value) || value['modelsChanged'] === undefined) return false;
  if (!('elementsChanged' in value) || value['elementsChanged'] === undefined) return false;
  if (!('logicalElementsChanged' in value) || value['logicalElementsChanged'] === undefined)
    return false;
  if (!('element' in value) || value['element'] === undefined) return false;
  return true;
}

export function CreateElementActionResultDataFromJSON(json: any): CreateElementActionResultData {
  return CreateElementActionResultDataFromJSONTyped(json, false);
}

export function CreateElementActionResultDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateElementActionResultData {
  if (json == null) {
    return json;
  }
  return {
    modelsChanged: json['models_changed'],
    elementsChanged: json['elements_changed'],
    logicalElementsChanged: json['logical_elements_changed'],
    element: ElementOutFromJSON(json['element'])
  };
}

export function CreateElementActionResultDataToJSON(
  value?: CreateElementActionResultData | null
): any {
  if (value == null) {
    return value;
  }
  return {
    models_changed: value['modelsChanged'],
    elements_changed: value['elementsChanged'],
    logical_elements_changed: value['logicalElementsChanged'],
    element: ElementOutToJSON(value['element'])
  };
}
