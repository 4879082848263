import {ProjectOut, ProjectTypeEnum} from 'api';

/* MODELS */
import {CAT_CRACKING_2A_MODELS_LIST} from './CatCracking_2A/models.mocks';
/* ELEMENTS */
import {SEPARATOR_ELEMENTS_LIST} from './Separators/elements.mocks';
import {ELEMENTS_SAMPLE_ELEMENTS_LIST} from './ElementsSample/elements.mocks';
import {CAT_CRACKING_1_ELEMENTS_LIST} from './CatCracking_1/elements.mocks';
import {CAT_CRACKING_2A_ELEMENTS_LIST} from './CatCracking_2A/elements.mocks';
import {CAT_CRACKING_2B_ELEMENTS_LIST} from './CatCracking_2B/elements.mocks';
import {CAT_CRACKING_3_ELEMENTS_LIST} from './CatCracking_3/elements.mocks';
import {CAT_CRACKING_4A_ELEMENTS_LIST} from './CatCracking_4A/elements.mocks';
import {CAT_CRACKING_4B_ELEMENTS_LIST} from './CatCracking_4B/elements.mocks';
/* WORKZONES */
import {SEPARATOR_WORKZONE_LIST} from './Separators/workzone.mocks';
import {ELEMENTS_SAMPLE_WORKZONE_LIST} from './ElementsSample/workzone.mocks';
import {CAT_CRACKING_1_WORKZONE_LIST} from './CatCracking_1/workzone.mocks';
import {CAT_CRACKING_2A_WORKZONE_LIST} from './CatCracking_2A/workzone.mocks';
import {CAT_CRACKING_2B_WORKZONE_LIST} from './CatCracking_2B/workzone.mocks';
import {CAT_CRACKING_3_WORKZONE_LIST} from './CatCracking_3/workzone.mocks';
import {CAT_CRACKING_4A_WORKZONE_LIST} from './CatCracking_4A/workzone.mocks';
import {CAT_CRACKING_4B_WORKZONE_LIST} from './CatCracking_4B/workzone.mocks';
/* SUB WORKZONES */
import {CAT_CRACKING_1_SUB_WORK_ZONE} from './CatCracking_1/workzone.sub.mocks';
import {CAT_CRACKING_2A_SUB_WORK_ZONE} from './CatCracking_2A/workzone.sub.mocks';
import {CAT_CRACKING_2B_SUB_WORK_ZONE} from './CatCracking_2B/workzone.sub.mocks';
import {CAT_CRACKING_3_SUB_WORK_ZONE} from './CatCracking_3/workzone.sub.mocks';
import {CAT_CRACKING_4A_SUB_WORK_ZONE} from './CatCracking_4A/workzone.sub.mocks';
import {CAT_CRACKING_4B_SUB_WORK_ZONE} from './CatCracking_4B/workzone.sub.mocks';

export const PROJECT_LIST: ProjectOut[] = [
  {
    uuid: '47CB2F0C-0000-0000-0000-000000000000',
    name: 'Элементы',
    type: ProjectTypeEnum.TechProcess,
    authorId: 'Неизвестен',
    updatedAt: new Date(),
    createdAt: new Date(),
    isFavorite: false,
    deleted: false,
    checkpoints: [
      {
        uuid: 'c9f0c5e6-4ec5-4edf-8ed1-9b66341b1eb3',
        name: 'Поинт 1',
        lastSavingDt: new Date()
      }
    ]
  },
  {
    uuid: '47CB2F0C-0000-0000-0000-000000000001',
    name: 'Схема с сепараторами',
    type: ProjectTypeEnum.TechProcess,
    authorId: 'Неизвестен',
    updatedAt: new Date(),
    createdAt: new Date(),
    isFavorite: false,
    deleted: false,
    checkpoints: [
      {
        uuid: 'c9f0c5e6-4ec5-4edf-8ed1-9b66341b1eb3',
        name: 'Поинт 1',
        lastSavingDt: new Date()
      }
    ]
  },
  {
    uuid: '47CB2F0C-0000-0000-0000-000000000002',
    name: 'Кат. крекинг 1',
    type: ProjectTypeEnum.TechProcess,
    authorId: 'Неизвестен',
    updatedAt: new Date(),
    createdAt: new Date(),
    isFavorite: false,
    deleted: false,
    checkpoints: [
      {
        uuid: 'c9f0c5e6-4ec5-4edf-8ed1-9b66341b1eb4',
        name: 'Поинт 1',
        lastSavingDt: new Date()
      }
    ]
  },
  {
    uuid: '47CB2F0C-0000-0000-0000-000000000003',
    name: 'Кат. крекинг 2A',
    type: ProjectTypeEnum.TechProcess,
    authorId: 'Неизвестен',
    updatedAt: new Date(),
    createdAt: new Date(),
    isFavorite: false,
    deleted: false,
    checkpoints: [
      {
        uuid: 'c9f0c5e6-4ec5-4edf-8ed1-9b66341b1eb4',
        name: 'Поинт 1',
        lastSavingDt: new Date()
      }
    ]
  },
  {
    uuid: '47CB2F0C-0000-0000-0000-000000000004',
    name: 'Кат. крекинг 2B',
    type: ProjectTypeEnum.TechProcess,
    authorId: 'Неизвестен',
    updatedAt: new Date(),
    createdAt: new Date(),
    isFavorite: false,
    deleted: false,
    checkpoints: [
      {
        uuid: 'c9f0c5e6-4ec5-4edf-8ed1-9b66341b1eb4',
        name: 'Поинт 1',
        lastSavingDt: new Date()
      }
    ]
  },
  {
    uuid: '47CB2F0C-0000-0000-0000-000000000005',
    name: 'Кат. крекинг 3',
    type: ProjectTypeEnum.TechProcess,
    authorId: 'Неизвестен',
    updatedAt: new Date(),
    createdAt: new Date(),
    isFavorite: false,
    deleted: false,
    checkpoints: [
      {
        uuid: 'c9f0c5e6-4ec5-4edf-8ed1-9b66341b1eb4',
        name: 'Поинт 1',
        lastSavingDt: new Date()
      }
    ]
  },
  {
    uuid: '47CB2F0C-0000-0000-0000-000000000006',
    name: 'Кат. крекинг 4A',
    type: ProjectTypeEnum.TechProcess,
    authorId: 'Неизвестен',
    updatedAt: new Date(),
    createdAt: new Date(),
    isFavorite: false,
    deleted: false,
    checkpoints: [
      {
        uuid: 'c9f0c5e6-4ec5-4edf-8ed1-9b66341b1eb4',
        name: 'Поинт 1',
        lastSavingDt: new Date()
      }
    ]
  },
  {
    uuid: '47CB2F0C-0000-0000-0000-000000000007',
    name: 'Кат. крекинг 4B',
    type: ProjectTypeEnum.TechProcess,
    authorId: 'Неизвестен',
    updatedAt: new Date(),
    createdAt: new Date(),
    isFavorite: false,
    deleted: false,
    checkpoints: [
      {
        uuid: 'c9f0c5e6-4ec5-4edf-8ed1-9b66341b1eb4',
        name: 'Поинт 1',
        lastSavingDt: new Date()
      }
    ]
  }
];

export const MODELS_LIST = [...CAT_CRACKING_2A_MODELS_LIST];

export const ELEMENTS_LIST = [
  ...ELEMENTS_SAMPLE_ELEMENTS_LIST,
  ...SEPARATOR_ELEMENTS_LIST,
  ...CAT_CRACKING_1_ELEMENTS_LIST,
  ...CAT_CRACKING_2A_ELEMENTS_LIST,
  ...CAT_CRACKING_2B_ELEMENTS_LIST,
  ...CAT_CRACKING_3_ELEMENTS_LIST,
  ...CAT_CRACKING_4A_ELEMENTS_LIST,
  ...CAT_CRACKING_4B_ELEMENTS_LIST
];

export const WORKZONES_LIST = [
  ...ELEMENTS_SAMPLE_WORKZONE_LIST,
  ...SEPARATOR_WORKZONE_LIST,
  ...CAT_CRACKING_1_WORKZONE_LIST,
  ...CAT_CRACKING_2A_WORKZONE_LIST,
  ...CAT_CRACKING_2B_WORKZONE_LIST,
  ...CAT_CRACKING_3_WORKZONE_LIST,
  ...CAT_CRACKING_4A_WORKZONE_LIST,
  ...CAT_CRACKING_4B_WORKZONE_LIST
];

export const SUB_WORKZONES_LIST = [
  ...CAT_CRACKING_1_SUB_WORK_ZONE,
  ...CAT_CRACKING_2A_SUB_WORK_ZONE,
  ...CAT_CRACKING_2B_SUB_WORK_ZONE,
  ...CAT_CRACKING_3_SUB_WORK_ZONE,
  ...CAT_CRACKING_4A_SUB_WORK_ZONE,
  ...CAT_CRACKING_4B_SUB_WORK_ZONE
];
