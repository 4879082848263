import {applySnapshot, flow, Instance, types} from 'mobx-state-tree';

import {EWidgetType} from 'core/enums';
import {OilApi, RJSFSchemas, SampleCalculationResult, CalculationResultUpdateOut} from 'api';
import {
  ResetModel,
  RequestModel,
  JsonSchemaForm,
  SampleResultInfo,
  TSampleResultInfoModel
} from 'core/models';

const ProjectSamplesResults = types
  .compose(
    ResetModel,
    types.model('ProjectSamplesResults', {
      projectUuid: '',
      checkpointUuid: '',

      sampleResults: types.optional(types.array(SampleResultInfo), []),
      jsonForm: types.maybeNull(JsonSchemaForm),

      fetchRequest: types.optional(RequestModel, {})
    })
  )
  .actions((self) => ({
    _clearJsonForm() {
      self.jsonForm?.clear();
      self.jsonForm = null;
    },
    _reloadJsonForm: flow(function* (uuid: string) {
      self.jsonForm = JsonSchemaForm.create({entityUuid: uuid});
      const response: {[key: string]: RJSFSchemas} = yield self.jsonForm.fetchRequest.send(
        OilApi.oilGetOilSamplesCalculationResultWidgetSchemas.bind(OilApi),
        {
          resultWidgetUuid: uuid,
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (response) {
        self.jsonForm = JsonSchemaForm.create({entityUuid: uuid});
        self.jsonForm.setJsonSchemas(response);
      }
    })
  }))
  .actions((self) => ({
    _reload: flow(function* () {
      const response: SampleCalculationResult[] = yield self.fetchRequest.send(
        OilApi.oilGetOilSamplesCalculationResults.bind(OilApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      const responseResults = response.map((result) => ({
        uuid: result.uuid,
        name: result.name,
        sampleUuid: result.sampleUuid,
        lastUpdated: new Date(),
        deletable: false,
        widget: {
          uuid: result.widget.uuid,
          name: result.widget.name,
          data: result.widget.data,
          widgetType: result.widget.widgetType as EWidgetType
        }
      }));

      applySnapshot(self.sampleResults, responseResults);
    })
  }))
  .actions((self) => ({
    _updateSampleResultName(uuid: string, name: string) {
      const result = self.sampleResults.find((n) => n.uuid === uuid);
      result?.setName(name);
    }
  }))
  .actions((self) => ({
    _updateSampleResultFormData: flow(function* (uuid: string, schemaId: string, data: unknown) {
      const jsonSchema = self.jsonForm?.jsonSchemas.find((js) => js.id === schemaId);
      if (!self.jsonForm || !jsonSchema) {
        return;
      }

      jsonSchema.updateFormData(data);
      const response: CalculationResultUpdateOut = yield self.jsonForm.updateRequest.send(
        OilApi.oilUpdateOilSampleCalculationResult.bind(OilApi),
        {
          resultWidgetUuid: uuid,
          body: data as object,
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (response?.name) {
        self._updateSampleResultName(uuid, response.name);
      }
    })
  }))
  .actions((self) => ({
    init: flow(function* (projectUuid: string, checkpointUuid: string) {
      self.projectUuid = projectUuid;
      self.checkpointUuid = checkpointUuid;
      yield self._reload();
    })
  }))
  .actions((self) => ({
    hasSampleResult(uuid: string) {
      return self.sampleResults.some((r) => r.uuid === uuid);
    },
    findSampleResult(uuid: string): TSampleResultInfoModel | undefined {
      return self.sampleResults.find((e) => e.uuid === uuid);
    }
  }))
  .views((self) => ({
    get isLoading(): boolean {
      return self.fetchRequest.isPending;
    },
    get isFormLoading(): boolean {
      return self.jsonForm?.isFormLoading ?? false;
    },
    get isFormDataUpdating(): boolean {
      return self.jsonForm?.isFormDataUpdating ?? false;
    }
  }));

export type TProjectSamplesResultsModel = Instance<typeof ProjectSamplesResults>;

export {ProjectSamplesResults};
