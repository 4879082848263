import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Center, Text} from '@chakra-ui/react';
import {ItemListHeader, Loader} from '@progress-fe/ui-kit';
import {useT} from '@progress-fe/core';

import {StructureItem} from 'ui-kit';
import {TBlendResultInfoModel} from 'core/models';
import {EWidgetType} from 'core/enums';

interface IProps {
  isLoading: boolean;
  selectedId: string | null;
  blendResults: TBlendResultInfoModel[];
  onSelect: (resultId: string) => void;
}

const BlendsResultsListFC: FC<IProps> = ({isLoading, blendResults, selectedId, onSelect}) => {
  const {t} = useT();

  return (
    <Box overflowY="hidden" height="100%">
      <ItemListHeader title={t('blends.results')} />

      <Box overflowY="auto" h="calc(100% - 40px)">
        {isLoading ? (
          <Center height="100%">
            <Loader size={'32px'} thickness={'5px'} />
          </Center>
        ) : (
          <>
            {blendResults.map((item) => (
              <StructureItem
                key={item.uuid}
                item={{
                  uuid: item.uuid,
                  name: item.name,
                  isDeletable: item.deletable,
                  isActive: selectedId === item.uuid,
                  icon: item.widget?.widgetType === EWidgetType.ComplexTable ? 'Table' : 'Plot'
                }}
                onClick={onSelect}
              />
            ))}

            {blendResults.length === 0 && (
              <Center height="100%">
                <Text>{t('common.noResults')}</Text>
              </Center>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export const BlendsResultsList = observer(BlendsResultsListFC);
