import {FC, useEffect, useRef, useState, DragEvent} from 'react';
import {Box, Center, Grid} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {EElement, EventEmitter, TFlowType, useT} from '@progress-fe/core';
import {ISelectOption, Loader, useDialog} from '@progress-fe/ui-kit';
import {
  Node,
  Edge,
  XYPosition,
  EdgeChange,
  NodeChange,
  Connection,
  ReactFlowInstance,
  FinalConnectionState,
  useEdgesState,
  useNodesState,
  useStoreApi
} from '@xyflow/react';
import {
  ERFElement,
  IRFMenuItem,
  IRFNodePort,
  TRFEdgeDataConfig,
  TRFWorkZoneDataConfig,
  RFRender,
  isFlowNode,
  isEnergyPort,
  createPortCode,
  isConnectionValid,
  ElementByRFElement,
  RF_DRAG_NODE_TYPE,
  RF_FIT_VIEW_MAX_ZOOM
} from '@progress-fe/rf-core';

import {RFMenu} from 'ui-kit';
import {INodeXYPosition} from 'core/interfaces';
import {TElementInfoModel} from 'core/models';

import {QualifierDialog} from './components';

interface IProps {
  width: number;
  height: number;
  menuItems: IRFMenuItem[];
  isLoading: boolean;
  initialNodes: Node<TRFWorkZoneDataConfig>[];
  initialEdges: Edge<TRFEdgeDataConfig>[];
  isSubWorkzone: boolean;
  parentNodeId?: string | null;
  selectedNodeId: string | null;
  selectedSubNodeId: string | null;
  onCreateNode: (type: EElement, position: XYPosition) => Promise<TElementInfoModel | null>;
  onDeleteNodes: (uuids: string[]) => Promise<void>;
  onChangeNodesPositions: (positions: INodeXYPosition[]) => Promise<void>;
  onChangeSubNodesPositions: (parentUuid: string, positions: INodeXYPosition[]) => Promise<void>;
  onConnectNodes: (from: IRFNodePort, to: IRFNodePort, refresh: boolean) => Promise<void>;
  onDisconnectNodes: (from: IRFNodePort, to: IRFNodePort) => Promise<void>;
}

type TQueueItem = {a: IRFNodePort; b: IRFNodePort};

const RFWorkZoneFC: FC<IProps> = ({
  width,
  height,
  menuItems,
  isLoading,
  initialNodes,
  initialEdges,
  isSubWorkzone,
  parentNodeId,
  selectedNodeId,
  selectedSubNodeId,
  onCreateNode,
  onDeleteNodes,
  onConnectNodes,
  onDisconnectNodes,
  onChangeNodesPositions,
  onChangeSubNodesPositions
}) => {
  const [isQueueProcessing, setIsQueueProcessing] = useState(false);
  const [isQualifierDialog, setIsQualifierDialog] = useState<{
    nodeA: string;
    nodeB: string;
    isPortA: boolean;
    queueLeft: Array<TQueueItem>;
    qualifiers: Array<ISelectOption<string>>;
  } | null>();

  const pickedNodeId = useRef<string | null>(null);

  const [isMultiSelectionActive, setIsMultiSelectionActive] = useState(false);
  const [instance, setInstance] = useState<ReactFlowInstance<
    Node<TRFWorkZoneDataConfig>,
    Edge<TRFEdgeDataConfig>
  > | null>(null);

  const [nodes, setNodes, onNodesChange] = useNodesState([] as Node<TRFWorkZoneDataConfig>[]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([] as Edge<TRFEdgeDataConfig>[]);

  const {getState} = useStoreApi();
  const {addSelectedNodes, resetSelectedElements} = getState();

  const {t} = useT();

  const DeleteNodeDialog = useDialog({title: t('elements.delete')});
  const DeleteEdgeDialog = useDialog({title: t('connections.delete')});

  useEffect(() => {
    setNodes(initialNodes);
  }, [initialNodes, initialNodes.length, setNodes]);

  useEffect(() => {
    setEdges(initialEdges);
  }, [initialEdges, initialEdges.length, setEdges]);

  useEffect(() => {
    instance?.fitView({maxZoom: RF_FIT_VIEW_MAX_ZOOM});
  }, [width, instance]);

  useEffect(() => {
    if (nodes.length === 1) {
      window.requestAnimationFrame(() => {
        instance?.fitView({nodes: [{id: nodes[0].id}], maxZoom: RF_FIT_VIEW_MAX_ZOOM});
      });
    }
  }, [instance, nodes, nodes.length]);

  useEffect(() => {
    const pickEntity = (id: string) => {
      pickedNodeId.current = id;
      const foundNode = nodes.find((n) => n.id === id);
      if (foundNode) {
        addSelectedNodes([foundNode.id]);
      }
    };

    EventEmitter.on('PickItem', pickEntity);

    return () => {
      EventEmitter.off('PickItem', pickEntity);
    };
  }, [addSelectedNodes, nodes]);

  useEffect(() => {
    setTimeout(() => {
      if (selectedSubNodeId) {
        addSelectedNodes([selectedSubNodeId]);
      } else if (selectedNodeId) {
        addSelectedNodes([selectedNodeId]);
      } else {
        resetSelectedElements();
      }
    }, 50);
    // FYI: Should be only 1 dep
    // eslint-disable-next-line
  }, [selectedNodeId, selectedSubNodeId]);

  const doCreateElement = async (type: EElement, position: XYPosition) => {
    setIsQueueProcessing(true);
    const uuid = await onCreateNode(type, position);
    setIsQueueProcessing(false);
    return uuid;
  };

  const doProcessConnectQueue = async (input: TQueueItem[], qualifier?: string) => {
    setIsQueueProcessing(true);
    const queue = structuredClone(input);
    const queueItem = queue.shift();

    if (queueItem) {
      const nodeA = nodes.find((n) => n.id === queueItem.a.uuid);
      const nodeB = nodes.find((n) => n.id === queueItem.b.uuid);

      const qualifiersA = nodeA?.data?.handleQualifiers;
      const qualifiersB = nodeB?.data?.handleQualifiers;

      const portQualifiersA = qualifiersA?.find((h) => h.handle === queueItem.a.port);
      const portQualifiersB = qualifiersB?.find((h) => h.handle === queueItem.b.port);

      const isQualifierA = (portQualifiersA?.qualifiers?.length || 0) > 0;
      const isQualifierB = (portQualifiersB?.qualifiers?.length || 0) > 0;
      const portQualifiers = portQualifiersA?.qualifiers || portQualifiersB?.qualifiers || [];

      const isLast = queue.length === 0;

      // 1. There are qualifiers. It is needed to select one. Show dialog
      if (portQualifiers.length > 0 && !qualifier) {
        setIsQualifierDialog({
          isPortA: isQualifierA,
          queueLeft: input, // whole queue
          nodeA: queueItem.a.name || nodeA?.data.elementName || '',
          nodeB: queueItem.b.name || nodeB?.data.elementName || '',
          qualifiers: portQualifiers.map((q) => ({value: q.id, label: q.name}))
        });
      }
      // 2. There are qualifiers. Qualifier was selected. Do connect.
      else if (portQualifiers.length > 0 && !!qualifier) {
        const a: IRFNodePort = isQualifierA ? {...queueItem.a, qualifier} : queueItem.a;
        const b: IRFNodePort = isQualifierB ? {...queueItem.b, qualifier} : queueItem.b;
        await onConnectNodes(a, b, isLast);
        await doProcessConnectQueue(queue);
      }
      // 3. No qualifiers for this connection. Do connect.
      else {
        await onConnectNodes(queueItem.a, queueItem.b, isLast);
        await doProcessConnectQueue(queue);
      }
    } else {
      setIsQueueProcessing(false);
    }
  };

  const handleSelectNode = (nodeId: string) => {
    // Don't emit event when node was selected by the Pick button
    if (nodeId !== pickedNodeId.current) {
      const node = nodes.find((n) => n.id === nodeId);
      if (!node?.data.isNotElement && !!node?.data.isSubElement && !!parentNodeId) {
        EventEmitter.emit('SelectItem', parentNodeId, nodeId);
      } else if (!node?.data.isNotElement) {
        EventEmitter.emit('SelectItem', nodeId);
      }
    }
    // Just clear node which was selected by the Pick button
    if (nodeId === pickedNodeId.current) {
      pickedNodeId.current = null;
    }
  };

  const handleNodeChanges = async (changes: NodeChange<Node<TRFWorkZoneDataConfig>>[]) => {
    if (changes.every((c) => c.type === 'dimensions')) {
      onNodesChange(changes);
    } else if (changes.every((c) => c.type === 'select')) {
      onNodesChange(changes);
      const targetChanges = changes.filter((n) => n.selected);
      if (targetChanges.length === 1 && !isMultiSelectionActive) {
        handleSelectNode(targetChanges[0].id);
      }
    } else if (changes.every((c) => c.type === 'position')) {
      onNodesChange(changes);
      const targetChanges = changes.filter((n) => n.dragging === false);
      if (targetChanges.length > 0) {
        const positions: INodeXYPosition[] = targetChanges.map((c) => ({
          position: c.position ?? {x: 0, y: 0},
          uuid: c.id
        }));

        if (isSubWorkzone && !!parentNodeId) {
          await onChangeSubNodesPositions(parentNodeId, positions);
        } else {
          await onChangeNodesPositions(positions);
        }
      }
    }
  };

  const handleEdgeChanges = (changes: EdgeChange<Edge<TRFEdgeDataConfig>>[]) => {
    if (changes.every((c) => c.type === 'select')) {
      onEdgesChange(changes);
    }
  };

  /* Remove nodes and/or edges */
  const handleOnBeforeDelete = (
    nodesForRemove: Node<TRFWorkZoneDataConfig>[],
    edgesForRemove: Edge<TRFEdgeDataConfig>[]
  ) => {
    if (nodesForRemove.length > 0) {
      const names = nodesForRemove.map((n) => n.data?.elementName);
      DeleteNodeDialog.open(t('elements.deleteSureMsg', {name: names.join(', ')}), {
        close: {
          title: t('actions.cancel')
        },
        apply: {
          title: t('actions.delete'),
          isDanger: true,
          onClick: async () => {
            await onDeleteNodes(nodesForRemove.map((n) => n.id));
          }
        }
      });
      return;
    }

    if (edgesForRemove.length === 1) {
      DeleteNodeDialog.open(t('connections.deleteSureMsg'), {
        close: {
          title: t('actions.cancel')
        },
        apply: {
          title: t('actions.delete'),
          isDanger: true,
          onClick: async () => {
            const edge = edgesForRemove[0];
            await onDisconnectNodes(
              {
                uuid: edge.source,
                port: edge.sourceHandle || '',
                qualifier: edge.data?.sourceHandleQualifier
              },
              {
                uuid: edge.target,
                port: edge.targetHandle || '',
                qualifier: edge.data?.targetHandleQualifier
              }
            );
          }
        }
      });
    }
  };

  /* Connect two elements */
  const handleOnConnect = async (connection: Connection) => {
    if (isConnectionValid(connection, nodes)) {
      const {source, target, sourceHandle, targetHandle} = connection;

      const sourceNode = nodes.find((n) => n.id === source);
      const targetNode = nodes.find((n) => n.id === target);

      const isEnergy = isEnergyPort(sourceHandle);

      // One of nodes must be as NON-flow node
      if (isFlowNode(sourceNode?.type) || isFlowNode(targetNode?.type)) {
        const from = {uuid: sourceNode?.id || '', port: sourceHandle || ''};
        const to = {uuid: targetNode?.id || '', port: targetHandle || ''};

        await doProcessConnectQueue([{a: from, b: to}]);
      }
      // Create flow node between nodes
      else if (sourceNode?.position && targetNode?.position) {
        const betweenX = (sourceNode.position.x + targetNode.position.x) / 2;
        const betweenY = (sourceNode.position.y + targetNode.position.y) / 2;

        const betweenPosition: XYPosition = {x: betweenX, y: betweenY};
        const elementType = isEnergy ? EElement.EnergyFlowElement : EElement.MaterialFlowElement;

        const flowNode = await doCreateElement(elementType, betweenPosition);
        if (flowNode && sourceHandle && targetHandle) {
          const flowType: TFlowType = isEnergy ? 'energy' : 'material';

          const fromA: IRFNodePort = {
            uuid: source,
            port: sourceHandle
          };
          const toA: IRFNodePort = {
            uuid: flowNode.uuid,
            name: flowNode.name,
            port: createPortCode('target', flowType)
          };
          const fromB = {
            uuid: flowNode.uuid,
            name: flowNode.name,
            port: createPortCode('source', flowType)
          };
          const toB = {
            uuid: target,
            port: targetHandle
          };

          await doProcessConnectQueue([
            {a: fromA, b: toA},
            {a: fromB, b: toB}
          ]);
        }
      }
    }
  };

  /* Reconnect two elements */
  const handleOnReconnect = async (oldEdge: Edge<TRFEdgeDataConfig>, newConnection: Connection) => {
    setIsQueueProcessing(true);

    await onDisconnectNodes(
      {
        uuid: oldEdge.source,
        port: oldEdge.sourceHandle || '',
        qualifier: oldEdge.data?.sourceHandleQualifier
      },
      {
        uuid: oldEdge.target,
        port: oldEdge.targetHandle || '',
        qualifier: oldEdge.data?.targetHandleQualifier
      }
    );

    setIsQueueProcessing(false);
    await handleOnConnect(newConnection);
  };

  /* User drops connect line without valid connection */
  const handleOnConnectDrop = async (
    event: MouseEvent | TouchEvent,
    state: FinalConnectionState
  ) => {
    const {isValid, fromNode, fromHandle} = state;

    // It should be processed when isValid is false only.
    if (!isValid && !!fromHandle && !!fromNode && !isFlowNode(fromNode.type)) {
      const isEnergy = isEnergyPort(fromHandle.id);
      const flowType: TFlowType = isEnergy ? 'energy' : 'material';
      const elementType = isEnergy ? EElement.EnergyFlowElement : EElement.MaterialFlowElement;

      const {clientX, clientY} = 'changedTouches' in event ? event.changedTouches[0] : event;
      const position = instance?.screenToFlowPosition({x: clientX, y: clientY});
      const flowNode = await doCreateElement(elementType, position ?? {x: 0, y: 0});

      if (flowNode) {
        const isSource = fromHandle.type === 'source';

        const from: IRFNodePort = isSource
          ? {uuid: fromHandle.nodeId, port: fromHandle.id || ''}
          : {uuid: flowNode.uuid, name: flowNode.name, port: createPortCode('source', flowType)};

        const to = isSource
          ? {uuid: flowNode.uuid, name: flowNode.name, port: createPortCode('target', flowType)}
          : {uuid: fromHandle.nodeId, port: fromHandle.id || ''};

        await doProcessConnectQueue([{a: from, b: to}]);
      }
    }
  };

  /* Create a new element */
  const handleOnCreate = async (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const rfNodeType = event.dataTransfer.getData(RF_DRAG_NODE_TYPE);
    const position = instance?.screenToFlowPosition({x: event.clientX, y: event.clientY});

    if (position && rfNodeType) {
      const elementType = ElementByRFElement[rfNodeType as ERFElement];
      if (elementType) {
        await doCreateElement(elementType, position);
      }
    }
  };

  const activateIsMultiSelection = () => setIsMultiSelectionActive(true);
  const deactivateIsMultiSelection = () => setIsMultiSelectionActive(false);

  return (
    <Grid width="100%" gridTemplateColumns="48px 1fr" height={height}>
      <DeleteNodeDialog.Render />
      <DeleteEdgeDialog.Render />

      <RFMenu isDisabled={isSubWorkzone} menuItems={menuItems} height={height} />
      <Box width="100%" height={height}>
        {(isQueueProcessing || isLoading) && (
          <Center
            position="absolute"
            width="100%"
            height="100%"
            bg="rgba(255, 255, 255, 0.5)"
            zIndex={1}
          >
            <Loader size={'32px'} thickness={'3px'} />
          </Center>
        )}

        {isQualifierDialog && (
          <QualifierDialog
            nodeA={isQualifierDialog.nodeA}
            nodeB={isQualifierDialog.nodeB}
            isPortA={isQualifierDialog.isPortA}
            options={isQualifierDialog.qualifiers}
            onSelect={async (qualifier) => {
              const queueLeft = structuredClone(isQualifierDialog.queueLeft);
              setIsQualifierDialog(null);
              await doProcessConnectQueue(queueLeft, qualifier);
            }}
            onClose={async () => {
              const queueLeft = structuredClone(isQualifierDialog.queueLeft);
              queueLeft.shift();
              setIsQualifierDialog(null);
              await doProcessConnectQueue(queueLeft);
            }}
          />
        )}

        <RFRender
          isMinimap
          nodes={nodes}
          edges={edges}
          onInit={setInstance}
          onNodesChange={handleNodeChanges}
          onEdgesChange={handleEdgeChanges}
          onConnect={handleOnConnect}
          onConnectEnd={handleOnConnectDrop}
          onBeforeDelete={handleOnBeforeDelete}
          onReconnect={handleOnReconnect}
          onSelectNode={handleSelectNode}
          onSelectionStart={activateIsMultiSelection}
          onSelectionEnd={deactivateIsMultiSelection}
          onDrop={handleOnCreate}
        />
      </Box>
    </Grid>
  );
};

export const RFWorkZone = observer(RFWorkZoneFC);
