import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Center, Text} from '@chakra-ui/react';
import {ItemListHeader, Loader} from '@progress-fe/ui-kit';
import {useT} from '@progress-fe/core';

import {StructureItem} from 'ui-kit';
import {TElementResultInfoModel} from 'core/models';
import {EWidgetType} from 'core/enums';

interface IProps {
  isLoading: boolean;
  selectedId: string | null;
  elementResults: TElementResultInfoModel[];
  onSelect: (resultId: string) => void;
}

const ElementsResultsListFC: FC<IProps> = ({selectedId, elementResults, isLoading, onSelect}) => {
  const {t} = useT();

  return (
    <Box overflowY="hidden" height="100%">
      <ItemListHeader title={t('common.results')} />

      <Box overflowY="auto" h="calc(100% - 40px)">
        {isLoading ? (
          <Center height="100%">
            <Loader size={'32px'} thickness={'5px'} />
          </Center>
        ) : (
          <>
            {elementResults.map((el) => {
              return (
                <StructureItem
                  key={el.uuid}
                  item={{
                    uuid: el.uuid,
                    name: el.name,
                    isDeletable: el.deletable,
                    isActive: selectedId === el.uuid,
                    icon: el.widget?.widgetType === EWidgetType.ComplexTable ? 'Table' : 'Plot'
                  }}
                  onClick={onSelect}
                />
              );
            })}

            {elementResults.length === 0 && (
              <Center height="100%">
                <Text>{t('common.noResults')}</Text>
              </Center>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export const ElementsResultsList = observer(ElementsResultsListFC);
