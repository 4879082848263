import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Svg} from '@progress-fe/ui-kit';
import {useT} from '@progress-fe/core';
import {Box, Menu, MenuButton, Button, MenuList, MenuItem, Center} from '@chakra-ui/react';

import {TBlendInfoModel} from 'core/models';

interface IProps {
  blends: TBlendInfoModel[];
  onExport?: (uuid: string) => Promise<void>;
}

const ProjectBarActionsFC: FC<IProps> = ({blends, onExport}) => {
  const {t} = useT();

  const handleOnExport = async (uuid: string) => {
    await onExport?.(uuid);
  };

  return (
    <Box>
      <Menu matchWidth offset={[0, 3]} variant={'outline'}>
        <MenuButton
          as={Button}
          size={'sm'}
          width={'100%'}
          variant={'ghost'}
          isDisabled={blends.length === 0}
        >
          <Center gridTemplateColumns={'16px 1fr 12px'} gap={'10px'}>
            <Svg name={'Export'} />
            <Box pt={'1px'}>{t('blends.export')}</Box>
            <Svg name={'ArrowDown'} size={'s12'} />
          </Center>
        </MenuButton>

        {/* AVAILABLE BLEND LIST */}
        <MenuList motionProps={{animate: false}} minW={'0'} borderRadius={'4px'}>
          {blends.map((blend) => (
            <MenuItem key={blend.uuid} onClick={() => handleOnExport(blend.uuid)}>
              {blend.name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export const ProjectBarActions = observer(ProjectBarActionsFC);
