/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {AdditionalTabCode} from './AdditionalTabCode';
import {
  AdditionalTabCodeFromJSON,
  AdditionalTabCodeFromJSONTyped,
  AdditionalTabCodeToJSON
} from './AdditionalTabCode';

/**
 *
 * @export
 * @interface AdditionalTabsInfo
 */
export interface AdditionalTabsInfo {
  /**
   *
   * @type {Array<AdditionalTabCode>}
   * @memberof AdditionalTabsInfo
   */
  tabs: Array<AdditionalTabCode>;
}

/**
 * Check if a given object implements the AdditionalTabsInfo interface.
 */
export function instanceOfAdditionalTabsInfo(value: object): value is AdditionalTabsInfo {
  if (!('tabs' in value) || value['tabs'] === undefined) return false;
  return true;
}

export function AdditionalTabsInfoFromJSON(json: any): AdditionalTabsInfo {
  return AdditionalTabsInfoFromJSONTyped(json, false);
}

export function AdditionalTabsInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AdditionalTabsInfo {
  if (json == null) {
    return json;
  }
  return {
    tabs: (json['tabs'] as Array<any>).map(AdditionalTabCodeFromJSON)
  };
}

export function AdditionalTabsInfoToJSON(value?: AdditionalTabsInfo | null): any {
  if (value == null) {
    return value;
  }
  return {
    tabs: (value['tabs'] as Array<any>).map(AdditionalTabCodeToJSON)
  };
}
