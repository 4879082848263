/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {ErrorInfoInner} from './ErrorInfoInner';
import {
  ErrorInfoInnerFromJSON,
  ErrorInfoInnerFromJSONTyped,
  ErrorInfoInnerToJSON
} from './ErrorInfoInner';
import type {CalculationStatusEnum} from './CalculationStatusEnum';
import {
  CalculationStatusEnumFromJSON,
  CalculationStatusEnumFromJSONTyped,
  CalculationStatusEnumToJSON
} from './CalculationStatusEnum';

/**
 *
 * @export
 * @interface CalculationTaskRunStatus
 */
export interface CalculationTaskRunStatus {
  /**
   *
   * @type {CalculationStatusEnum}
   * @memberof CalculationTaskRunStatus
   */
  statusCode: CalculationStatusEnum;
  /**
   *
   * @type {Array<ErrorInfoInner>}
   * @memberof CalculationTaskRunStatus
   */
  errorInfo: Array<ErrorInfoInner>;
}

/**
 * Check if a given object implements the CalculationTaskRunStatus interface.
 */
export function instanceOfCalculationTaskRunStatus(
  value: object
): value is CalculationTaskRunStatus {
  if (!('statusCode' in value) || value['statusCode'] === undefined) return false;
  if (!('errorInfo' in value) || value['errorInfo'] === undefined) return false;
  return true;
}

export function CalculationTaskRunStatusFromJSON(json: any): CalculationTaskRunStatus {
  return CalculationTaskRunStatusFromJSONTyped(json, false);
}

export function CalculationTaskRunStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CalculationTaskRunStatus {
  if (json == null) {
    return json;
  }
  return {
    statusCode: CalculationStatusEnumFromJSON(json['status_code']),
    errorInfo: (json['error_info'] as Array<any>).map(ErrorInfoInnerFromJSON)
  };
}

export function CalculationTaskRunStatusToJSON(value?: CalculationTaskRunStatus | null): any {
  if (value == null) {
    return value;
  }
  return {
    status_code: CalculationStatusEnumToJSON(value['statusCode']),
    error_info: (value['errorInfo'] as Array<any>).map(ErrorInfoInnerToJSON)
  };
}
