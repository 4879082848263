/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {AdditionalTabRow} from './AdditionalTabRow';
import {
  AdditionalTabRowFromJSON,
  AdditionalTabRowFromJSONTyped,
  AdditionalTabRowToJSON
} from './AdditionalTabRow';
import type {RJSFSchemas} from './RJSFSchemas';
import {RJSFSchemasFromJSON, RJSFSchemasFromJSONTyped, RJSFSchemasToJSON} from './RJSFSchemas';

/**
 *
 * @export
 * @interface FullElementSchemas
 */
export interface FullElementSchemas {
  /**
   *
   * @type {{ [key: string]: RJSFSchemas; }}
   * @memberof FullElementSchemas
   */
  tabs: {[key: string]: RJSFSchemas};
  /**
   *
   * @type {Array<AdditionalTabRow>}
   * @memberof FullElementSchemas
   */
  additionalTabs: Array<AdditionalTabRow>;
}

/**
 * Check if a given object implements the FullElementSchemas interface.
 */
export function instanceOfFullElementSchemas(value: object): value is FullElementSchemas {
  if (!('tabs' in value) || value['tabs'] === undefined) return false;
  if (!('additionalTabs' in value) || value['additionalTabs'] === undefined) return false;
  return true;
}

export function FullElementSchemasFromJSON(json: any): FullElementSchemas {
  return FullElementSchemasFromJSONTyped(json, false);
}

export function FullElementSchemasFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FullElementSchemas {
  if (json == null) {
    return json;
  }
  return {
    tabs: mapValues(json['tabs'], RJSFSchemasFromJSON),
    additionalTabs: (json['additional_tabs'] as Array<any>).map(AdditionalTabRowFromJSON)
  };
}

export function FullElementSchemasToJSON(value?: FullElementSchemas | null): any {
  if (value == null) {
    return value;
  }
  return {
    tabs: mapValues(value['tabs'], RJSFSchemasToJSON),
    additional_tabs: (value['additionalTabs'] as Array<any>).map(AdditionalTabRowToJSON)
  };
}
