import {cast, flow, getSnapshot, Instance, types} from 'mobx-state-tree';
import {EComponentBaseType, IComponent, IImportedBlend} from '@progress-fe/core';

import {type AppProjectsTechprocessSchemasBlendOut, TechProcessApi} from 'api';
import {RequestModel, ResetModel} from 'core/models';

const ProjectSettings = types
  .compose(
    ResetModel,
    types.model('ProjectSettings', {
      projectUuid: '',
      favoritesComponents: types.optional(types.array(types.string), []),
      importedBlends: types.optional(types.array(types.frozen<IImportedBlend>()), []),

      favoritesRequest: types.optional(RequestModel, {}),
      importBlendRequest: types.optional(RequestModel, {}),
      blendsRequest: types.optional(RequestModel, {})
    })
  )
  .actions((self) => ({
    _loadFavorites: flow(function* () {
      const favorites: Array<string> = yield self.favoritesRequest.send(
        TechProcessApi.techProcessGetFavoritePureComponents.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid
        }
      );
      self.favoritesComponents = cast(favorites);
    }),
    _syncFavorites: flow(function* (projectId: string) {
      yield self.favoritesRequest.send(
        TechProcessApi.techProcessSetFavoritePureComponents.bind(TechProcessApi),
        {
          projectUuid: projectId,
          requestBody: getSnapshot(self.favoritesComponents)
        }
      );
    }),
    addToFavorites(projectId: string, componentsIds: string[]) {
      self.favoritesComponents.push(...componentsIds);
      self._syncFavorites(projectId).then();
    },
    removeFromFavorites(projectId: string, componentsIds: string[]) {
      const removeSet = new Set(componentsIds);
      const filtered = self.favoritesComponents.filter((item) => !removeSet.has(item));
      self.favoritesComponents = cast(filtered);
      self._syncFavorites(projectId).then();
    }
  }))
  .actions((self) => ({
    _loadBlends: flow(function* () {
      const response: Array<AppProjectsTechprocessSchemasBlendOut> = yield self.blendsRequest.send(
        TechProcessApi.techProcessGetProjectBlends.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid
        }
      );

      self.importedBlends = cast(response as IImportedBlend[]);
    }),
    importBlend: flow(function* (file: File, prefix: string) {
      yield self.importBlendRequest.send(
        TechProcessApi.techProcessImportBlends.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          prefix: prefix,
          file: file
        }
      );

      return self.importBlendRequest.isDone;
    })
  }))
  .actions((self) => ({
    init: flow(function* (projectUuid: string) {
      self.projectUuid = projectUuid;
      yield self._loadFavorites();
      yield self._loadBlends();
    })
  }))
  .views((self) => ({
    get favorites(): string[] {
      return self.favoritesComponents;
    },
    get pseudoComponents(): IComponent[] {
      return self.importedBlends
        .map((b) => b.pseudoComponents)
        .flat()
        .map((p) => ({
          uuid: p.uuid,
          name: p.name,
          structure: p.value,
          baseType: EComponentBaseType.PSEUDO
        }));
    },
    get isBlendImporting(): boolean {
      return self.importBlendRequest.isPending;
    }
  }));

export type TProjectSettingsModel = Instance<typeof ProjectSettings>;

export {ProjectSettings};
