import {EElement} from '@progress-fe/core';

import {TModelInfoSnapshotIn} from 'core/models';

export const CAT_CRACKING_2A_MODELS_LIST: {
  projectId: string;
  projectName: string;
  items: TModelInfoSnapshotIn[];
}[] = [
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000003',
    projectName: 'Кат. крекинг 2A',
    items: [
      {
        uuid: '7d6f307e-bfd7-4d55-82df-2ca8715d9c96',
        type: EElement.CatCrackingElement,
        name: 'Установка кат. крекинга-1',
        lastUpdated: new Date()
      },
      {
        uuid: '031CE246-0000-0000-0000-000000000001',
        type: EElement.MaterialFlowElement,
        name: 'Пенг-Робинсон-1',
        lastUpdated: new Date()
      }
    ]
  }
];
