/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {Position} from './Position';
import {PositionFromJSON, PositionFromJSONTyped, PositionToJSON} from './Position';

/**
 *
 * @export
 * @interface PositionInfo
 */
export interface PositionInfo {
  /**
   *
   * @type {Position}
   * @memberof PositionInfo
   */
  position: Position;
  /**
   *
   * @type {string}
   * @memberof PositionInfo
   */
  subSchemeUuid?: string | null;
}

/**
 * Check if a given object implements the PositionInfo interface.
 */
export function instanceOfPositionInfo(value: object): value is PositionInfo {
  if (!('position' in value) || value['position'] === undefined) return false;
  return true;
}

export function PositionInfoFromJSON(json: any): PositionInfo {
  return PositionInfoFromJSONTyped(json, false);
}

export function PositionInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PositionInfo {
  if (json == null) {
    return json;
  }
  return {
    position: PositionFromJSON(json['position']),
    subSchemeUuid: json['sub_scheme_uuid'] == null ? undefined : json['sub_scheme_uuid']
  };
}

export function PositionInfoToJSON(value?: PositionInfo | null): any {
  if (value == null) {
    return value;
  }
  return {
    position: PositionToJSON(value['position']),
    sub_scheme_uuid: value['subSchemeUuid']
  };
}
