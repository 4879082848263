import {FC, useCallback, useEffect, useState} from 'react';
import {Grid, GridItem} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {useParams} from 'react-router';

import {useStore} from 'core/hooks';
import {JournalViewer, ProjectLayout} from 'ui-kit';

import {ProjectStructure, ProjectForms, ProjectZones, ProjectBarActions} from './components';

const SamplesAndBlendsSceneFC: FC = () => {
  const {samplesAndBlendsStore, myProjectsStore} = useStore();
  const {journal, projectBlends} = samplesAndBlendsStore;
  const {blends, exportBlend} = projectBlends;

  const [isExpanded, setIsExpanded] = useState(false);

  const {id, checkpointId} = useParams();

  useEffect(() => {
    if (id && checkpointId) {
      samplesAndBlendsStore.initProject(id, checkpointId).then();
    }
    return () => {
      samplesAndBlendsStore.uninitialize();
    };
  }, [id, checkpointId, samplesAndBlendsStore]);

  const toggleIsExpanded = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  return (
    <ProjectLayout
      project={samplesAndBlendsStore}
      projectActions={<ProjectBarActions blends={blends} onExport={exportBlend} />}
      onRunProject={samplesAndBlendsStore.run}
      onExportProject={myProjectsStore.exportProject}
      onRenameProject={myProjectsStore.renameProject}
      onDeleteProject={myProjectsStore.deleteProject}
      onRestoreProject={myProjectsStore.restoreProject}
    >
      <Grid
        position="relative"
        h="var(--height-layout)"
        gridTemplateColumns={isExpanded ? '1fr' : '280px 440px 1fr'}
      >
        {!isExpanded && (
          <>
            {/* PROJECT ITEMS: SETTINGS, SAMPLES, BLENDS ... */}
            <GridItem bg="bg" borderRight="1px" borderColor="border">
              <ProjectStructure />
            </GridItem>

            {/* SELECTED PROJECT ITEM */}
            <GridItem bg="bg" borderRight="1px" borderColor="border" overflowY="auto">
              <ProjectForms />
            </GridItem>
          </>
        )}

        {/* SHOW RESULTS: CHARTS, TABLES */}
        <GridItem position={'relative'}>
          <ProjectZones isExpanded={isExpanded} toggleIsExpanded={toggleIsExpanded} />
          {journal.isOpen && (
            <JournalViewer journalPoints={journal.points} onClose={journal.toggleOpen} />
          )}
        </GridItem>
      </Grid>
    </ProjectLayout>
  );
};

export const SamplesAndBlendsScene = observer(SamplesAndBlendsSceneFC);
