import {useEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Tabs, TabList, Tab, TabPanels, TabPanel, Box} from '@chakra-ui/react';
import {Dot, IEntityErrorMessage, ITab, JsForm, JsFormName, SvgMenu} from '@progress-fe/ui-kit';

import {useStore} from 'core/hooks';
import {TAdditionalTabModel, TJsonSchemaModel} from 'core/models';

import {BackToForm} from './components';

export interface IJsonSchemaTab extends ITab {
  jsonSchema: TJsonSchemaModel;
}

interface IProps<T> {
  name: string | undefined;
  isUpdating?: boolean;
  tabs: IJsonSchemaTab[];
  parentFormName?: string | null;
  additionalTabs: Array<TAdditionalTabModel>;
  errors?: Array<IEntityErrorMessage>;
  onChange: (formData: T, tabIdx: number, changedFieldPath?: string) => Promise<void>;
  onToggleTab?: (uniqueCode: string) => Promise<void>;
  onBackToForm?: () => void;
}

const JsTabsFormFC = <T,>({
  name,
  tabs,
  errors,
  isUpdating,
  additionalTabs,
  parentFormName,
  onBackToForm,
  onToggleTab,
  onChange
}: IProps<T>) => {
  const {projectStore} = useStore();

  const [tabIndex, setTabIndex] = useState(0);

  const withTabs = useMemo(() => {
    return tabs.length > 1 || (tabs.length === 1 && additionalTabs.length > 0);
  }, [tabs.length, additionalTabs.length]);

  useEffect(() => {
    setTabIndex(projectStore?.uiState?.tabIndex || 0);
  }, [projectStore?.uiState?.tabIndex]);

  useEffect(() => {
    if (tabs.length > 0 && tabIndex > tabs.length - 1) {
      setTabIndex(0);
      projectStore?.selectTabIndex(0);
    }
  }, [tabIndex, tabs.length, projectStore]);

  const onSelectTab = (index: number) => {
    /* Skip click on the last "..." tab */
    if (additionalTabs.length === 0 || tabs.length - 1 >= index) {
      setTabIndex(index);
      projectStore?.selectTabIndex(index);
    }
  };

  return (
    <Tabs
      h={'100%'}
      index={tabIndex}
      variant="soft-rounded"
      onChange={onSelectTab}
      display={'flex'}
      flexDirection={'column'}
      overflowY={'hidden'}
    >
      <Box p={'0 8px 10px 8px'} justifySelf={'flex-start'} boxShadow={'shadow_4'}>
        {/* Back link to an entity from a sub entity */}
        {!!parentFormName && !!onBackToForm && (
          <BackToForm name={parentFormName} onBack={onBackToForm} />
        )}

        <JsFormName isUpdating={isUpdating ?? false} name={name ?? '?'} />

        {withTabs && (
          <TabList pt={'8px'}>
            {tabs.map((tab) => (
              <Tab key={tab.index} gap={'4px'} isDisabled={tab.isDisabled}>
                <span>{tab.name}</span>
                {errors?.some((e) => e.tabKey === tab.jsonSchema.id) && <Dot variant={'error'} />}
              </Tab>
            ))}
            {additionalTabs.length > 0 && (
              <Tab p={'4px 3px'} key={'AdditionalTab'} as={Box}>
                <SvgMenu
                  offset={[0, 8]}
                  menuIcon={'More'}
                  menuSize={'small'}
                  variant={'compactGray'}
                  items={additionalTabs.map((tab) => ({
                    name: tab.name,
                    id: tab.uniqueCode,
                    icon: tab.isActive ? 'Check' : undefined
                  }))}
                  onSelect={(value) => onToggleTab?.(value)}
                />
              </Tab>
            )}
          </TabList>
        )}
      </Box>

      <TabPanels p={'0 8px 4px 8px'} minH={'1px'} h={'0px'} flex={'1 1 auto'} overflowY={'scroll'}>
        {tabs.map(({jsonSchema}, index) => (
          <TabPanel key={jsonSchema.id}>
            <JsForm
              schema={jsonSchema.schema}
              uiSchema={jsonSchema.uiSchema}
              formData={jsonSchema.formData as T}
              onChange={async (formData, changedFieldPath) => {
                await onChange(formData, index, changedFieldPath);
              }}
            />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export const JsTabsForm = observer(JsTabsFormFC);
