import {ROUTES} from 'core/constants';
import {IRoute} from 'core/interfaces';

import MyProjectsScene from './MyProjectsScene/MyProjectsScene';
import SystemScene from './SystemScene/SystemScene';
import {SamplesAndBlendsScene} from './SamplesAndBlendsScene';
import {TechProcessScene} from './TechProcessScene';

export const MAIN_ROUTES: IRoute[] = [
  {
    path: ROUTES.MyProjects,
    element: <MyProjectsScene />
  },
  {
    path: ROUTES.TechProcess,
    element: <TechProcessScene />
  },
  {
    path: ROUTES.SamplesAndBlends,
    element: <SamplesAndBlendsScene />
  },
  {
    path: ROUTES.System,
    element: <SystemScene />
  }
];
