import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {SystemStyleObject} from '@chakra-ui/styled-system';
import {ISimpleTableColumn, ISimpleTableRow, Svg, VirtualTable} from '@progress-fe/ui-kit';
import {useT, IImportedBlend} from '@progress-fe/core';
import {Box} from '@chakra-ui/react';

interface IProps {
  blends: IImportedBlend[];
  tableSx?: SystemStyleObject;
  selectedBlendIdx?: number | null;
  onSelectBlendIdx?: (idx: number) => void;
}

const BlendListFC: FC<IProps> = ({blends, selectedBlendIdx, onSelectBlendIdx, tableSx}) => {
  const {t} = useT();

  const columns: ISimpleTableColumn[] = [
    {name: t('fields.prefix'), align: 'left'},
    {name: t('fields.name'), align: 'left'},
    {
      name: '',
      width: '24px',
      columnTemplate: '24px',
      render: () => (
        <Box opacity={0} _groupHover={{opacity: 1}}>
          <Svg size="s12" name="More" />
        </Box>
      )
    }
  ];

  const rows: ISimpleTableRow<string>[] = blends.map((blend) => {
    return {
      key: blend.uuid,
      values: [blend.prefix, blend.name, '']
    };
  });

  return (
    <VirtualTable
      isRowHover
      rows={rows}
      columns={columns}
      selectedRowIdx={selectedBlendIdx}
      noItemsTitle={t('blends.noBlends')}
      onRowClick={onSelectBlendIdx}
      tableSx={tableSx}
    />
  );
};

export const BlendList = observer(BlendListFC);
