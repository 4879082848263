import {Instance, types} from 'mobx-state-tree';
import {EElement} from '@progress-fe/core';

import {IWidgetData} from 'core/interfaces';
import {EntityBaseInfo, Widget} from 'core/models';

const ElementResultInfo = types
  .compose(
    EntityBaseInfo,
    types.model('ElementResultInfo', {
      elementUuid: types.string,
      elementType: types.enumeration(Object.values(EElement)),
      widget: Widget
    })
  )
  .views((self) => ({
    get widgetData(): IWidgetData {
      return {
        uuid: self.widget.uuid,
        name: self.widget.name,
        data: self.widget.data,
        type: self.widget.widgetType,
        entityUuid: self.uuid
      };
    }
  }));

export type TElementResultInfoModel = Instance<typeof ElementResultInfo>;

export {ElementResultInfo};
