import {applySnapshot, cast, Instance, types} from 'mobx-state-tree';

import {AdditionalTabRow, RJSFSchemas} from 'api';
import {RequestModel, ResetModel} from 'core/models/base';
import {JsonSchema, TJsonSchemaSnapshotIn} from 'core/models/base/JsonSchema';
import {AdditionalTab, TAdditionalTabSnapshotIn} from 'core/models/base/AdditionalTab';

const JsonSchemaForm = types.compose(
  ResetModel,
  types
    .model('JsonSchemaForm', {
      entityUuid: types.string,
      jsonSchemas: types.optional(types.array(JsonSchema), []),
      additionalTabs: types.optional(types.array(AdditionalTab), []),

      fetchRequest: types.optional(RequestModel, {}),
      updateRequest: types.optional(RequestModel, {}),
      changeTabRequest: types.optional(RequestModel, {})
    })
    .actions((self) => ({
      clear() {
        self.fetchRequest.cancel();
        self.jsonSchemas = cast([]);
        self.additionalTabs = cast([]);
      }
    }))
    .actions((self) => ({
      setJsonSchemas(inputJsonSchemas: {[key: string]: RJSFSchemas}) {
        const jsonSchemas: TJsonSchemaSnapshotIn[] = [];
        for (const [tabKey, tabSchema] of Object.entries(inputJsonSchemas)) {
          jsonSchemas.push({
            id: tabKey,
            name: tabSchema.tabName,
            tabCode: tabSchema.tabCode ?? undefined,
            uiSchema: tabSchema.ui,
            schema: tabSchema.jsonSchema,
            formData: tabSchema.data
          });
        }

        applySnapshot(self.jsonSchemas, jsonSchemas);
      },
      setAdditionalTabs(inputTabs: Array<AdditionalTabRow>) {
        const additionalTabs: TAdditionalTabSnapshotIn[] = inputTabs.map((tab) => ({
          name: tab.name,
          uniqueCode: tab.uniqueCode,
          isActive: tab.isActive
        }));

        applySnapshot(self.additionalTabs, additionalTabs);
      },
      toggleAdditionalTab(uniqueCode: string) {
        self.additionalTabs = cast(
          self.additionalTabs.map((tab) => ({
            ...tab,
            isActive: tab.uniqueCode === uniqueCode ? !tab.isActive : tab.isActive
          }))
        );
      }
    }))
    .views((self) => ({
      get isFormLoading(): boolean {
        return self.fetchRequest.isPending;
      },
      get isFormDataUpdating(): boolean {
        return self.updateRequest.isPending;
      },
      get isTabsChanging(): boolean {
        return self.changeTabRequest.isPending;
      }
    }))
);

export type TJsonSchemaFormModel = Instance<typeof JsonSchemaForm>;

export {JsonSchemaForm};
