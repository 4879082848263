import {Instance, SnapshotIn, types} from 'mobx-state-tree';

import {EntityBaseInfo} from 'core/models';

const ElementInfo = types.compose(
  EntityBaseInfo,
  types.model('ElementInfo', {
    subElements: types.optional(types.array(EntityBaseInfo), [])
  })
);

export type TElementInfoModel = Instance<typeof ElementInfo>;
export type TElementInfoSnapshotIn = SnapshotIn<typeof ElementInfo>;

export {ElementInfo};
