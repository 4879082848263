/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
/**
 *
 * @export
 * @interface Component
 */
export interface Component {
  /**
   *
   * @type {string}
   * @memberof Component
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof Component
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Component
   */
  formula?: string | null;
  /**
   *
   * @type {string}
   * @memberof Component
   */
  uNIFACStructure?: string | null;
  /**
   *
   * @type {string}
   * @memberof Component
   */
  _class?: string | null;
  /**
   *
   * @type {string}
   * @memberof Component
   */
  family?: string | null;
}

/**
 * Check if a given object implements the Component interface.
 */
export function instanceOfComponent(value: object): value is Component {
  if (!('uuid' in value) || value['uuid'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  return true;
}

export function ComponentFromJSON(json: any): Component {
  return ComponentFromJSONTyped(json, false);
}

export function ComponentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Component {
  if (json == null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    name: json['name'],
    formula: json['Formula'] == null ? undefined : json['Formula'],
    uNIFACStructure: json['UNIFACStructure'] == null ? undefined : json['UNIFACStructure'],
    _class: json['class_'] == null ? undefined : json['class_'],
    family: json['Family'] == null ? undefined : json['Family']
  };
}

export function ComponentToJSON(value?: Component | null): any {
  if (value == null) {
    return value;
  }
  return {
    uuid: value['uuid'],
    name: value['name'],
    Formula: value['formula'],
    UNIFACStructure: value['uNIFACStructure'],
    class_: value['_class'],
    Family: value['family']
  };
}
