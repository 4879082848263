/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  Component,
  ElementGroup,
  HTTPValidationError,
  Model,
  ProfilerMode,
  PureComponentClassEnum
} from '../models/index';
import {
  ComponentFromJSON,
  ComponentToJSON,
  ElementGroupFromJSON,
  ElementGroupToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  ModelFromJSON,
  ModelToJSON,
  ProfilerModeFromJSON,
  ProfilerModeToJSON,
  PureComponentClassEnumFromJSON,
  PureComponentClassEnumToJSON
} from '../models/index';

export interface CatalogsGetElementsCatalogRequest {
  xProfilerMode?: ProfilerMode;
}

export interface CatalogsGetInTouchModuleRequest {
  modelUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface CatalogsGetInTouchModulesRequest {
  xProfilerMode?: ProfilerMode;
}

export interface CatalogsGetModelsCatalogRequest {
  xProfilerMode?: ProfilerMode;
}

export interface CatalogsGetPureComponentsCatalogRequest {
  name?: string | null;
  _class?: PureComponentClassEnum | null;
  xProfilerMode?: ProfilerMode;
}

/**
 *
 */
export class CatalogsApi extends runtime.BaseAPI {
  /**
   * Get Elements Catalog
   */
  async catalogsGetElementsCatalogRaw(
    requestParameters: CatalogsGetElementsCatalogRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<ElementGroup>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/catalog/elements`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(ElementGroupFromJSON)
    );
  }

  /**
   * Get Elements Catalog
   */
  async catalogsGetElementsCatalog(
    requestParameters: CatalogsGetElementsCatalogRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<ElementGroup>> {
    const response = await this.catalogsGetElementsCatalogRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get In Touch Module
   */
  async catalogsGetInTouchModuleRaw(
    requestParameters: CatalogsGetInTouchModuleRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<boolean>> {
    if (requestParameters['modelUuid'] == null) {
      throw new runtime.RequiredError(
        'modelUuid',
        'Required parameter "modelUuid" was null or undefined when calling catalogsGetInTouchModule().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/catalog/_service/check_in_touch_modules/{model_uuid}`.replace(
          `{${'model_uuid'}}`,
          encodeURIComponent(String(requestParameters['modelUuid']))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<boolean>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Get In Touch Module
   */
  async catalogsGetInTouchModule(
    requestParameters: CatalogsGetInTouchModuleRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<boolean> {
    const response = await this.catalogsGetInTouchModuleRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get In Touch Modules
   */
  async catalogsGetInTouchModulesRaw(
    requestParameters: CatalogsGetInTouchModulesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<{[key: string]: Array<Model>}>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/catalog/_service/check_in_touch_modules`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Get In Touch Modules
   */
  async catalogsGetInTouchModules(
    requestParameters: CatalogsGetInTouchModulesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<{[key: string]: Array<Model>}> {
    const response = await this.catalogsGetInTouchModulesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Models Catalog
   */
  async catalogsGetModelsCatalogRaw(
    requestParameters: CatalogsGetModelsCatalogRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<Model>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/catalog/models`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ModelFromJSON));
  }

  /**
   * Get Models Catalog
   */
  async catalogsGetModelsCatalog(
    requestParameters: CatalogsGetModelsCatalogRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<Model>> {
    const response = await this.catalogsGetModelsCatalogRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Pure Components Catalog
   */
  async catalogsGetPureComponentsCatalogRaw(
    requestParameters: CatalogsGetPureComponentsCatalogRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<Component>>> {
    const queryParameters: any = {};

    if (requestParameters['name'] != null) {
      queryParameters['name'] = requestParameters['name'];
    }

    if (requestParameters['_class'] != null) {
      queryParameters['class_'] = requestParameters['_class'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/catalog/pure-components`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ComponentFromJSON));
  }

  /**
   * Get Pure Components Catalog
   */
  async catalogsGetPureComponentsCatalog(
    requestParameters: CatalogsGetPureComponentsCatalogRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<Component>> {
    const response = await this.catalogsGetPureComponentsCatalogRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
