/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {CalculationResultWidget} from './CalculationResultWidget';
import {
  CalculationResultWidgetFromJSON,
  CalculationResultWidgetFromJSONTyped,
  CalculationResultWidgetToJSON
} from './CalculationResultWidget';

/**
 *
 * @export
 * @interface BlendCalculationResult
 */
export interface BlendCalculationResult {
  /**
   *
   * @type {string}
   * @memberof BlendCalculationResult
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof BlendCalculationResult
   */
  blendUuid: string;
  /**
   *
   * @type {string}
   * @memberof BlendCalculationResult
   */
  name: string;
  /**
   *
   * @type {CalculationResultWidget}
   * @memberof BlendCalculationResult
   */
  widget: CalculationResultWidget;
}

/**
 * Check if a given object implements the BlendCalculationResult interface.
 */
export function instanceOfBlendCalculationResult(value: object): value is BlendCalculationResult {
  if (!('uuid' in value) || value['uuid'] === undefined) return false;
  if (!('blendUuid' in value) || value['blendUuid'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('widget' in value) || value['widget'] === undefined) return false;
  return true;
}

export function BlendCalculationResultFromJSON(json: any): BlendCalculationResult {
  return BlendCalculationResultFromJSONTyped(json, false);
}

export function BlendCalculationResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BlendCalculationResult {
  if (json == null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    blendUuid: json['blend_uuid'],
    name: json['name'],
    widget: CalculationResultWidgetFromJSON(json['widget'])
  };
}

export function BlendCalculationResultToJSON(value?: BlendCalculationResult | null): any {
  if (value == null) {
    return value;
  }
  return {
    uuid: value['uuid'],
    blend_uuid: value['blendUuid'],
    name: value['name'],
    widget: CalculationResultWidgetToJSON(value['widget'])
  };
}
