/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const LogicalElementType = {
  Recycle: 'recycle',
  Adjustment: 'adjustment',
  Cutter: 'cutter',
  Set: 'set',
  CalcFrame: 'calc_frame'
} as const;
export type LogicalElementType = (typeof LogicalElementType)[keyof typeof LogicalElementType];

export function instanceOfLogicalElementType(value: any): boolean {
  for (const key in LogicalElementType) {
    if (Object.prototype.hasOwnProperty.call(LogicalElementType, key)) {
      if ((LogicalElementType as Record<string, LogicalElementType>)[key] === value) {
        return true;
      }
    }
  }
  return false;
}

export function LogicalElementTypeFromJSON(json: any): LogicalElementType {
  return LogicalElementTypeFromJSONTyped(json, false);
}

export function LogicalElementTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LogicalElementType {
  return json as LogicalElementType;
}

export function LogicalElementTypeToJSON(value?: LogicalElementType | null): any {
  return value as any;
}
