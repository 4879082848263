/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const ElementClassName = {
  SingleParamChangeElement: 'SingleParamChangeElement',
  MaterialFlowElement: 'MaterialFlowElement',
  EnergyFlowElement: 'EnergyFlowElement',
  CoolerElement: 'CoolerElement',
  HeaterElement: 'HeaterElement',
  HeatExchangerElement: 'HeatExchangerElement',
  HeatExchangerSynthetic: 'HeatExchangerSynthetic',
  CompressorElement: 'CompressorElement',
  ValveElement: 'ValveElement',
  PumpElement: 'PumpElement',
  DetanderElement: 'DetanderElement',
  WaterJetPumpElement: 'WaterJetPumpElement',
  MixerElement: 'MixerElement',
  SplitterElement: 'SplitterElement',
  PipelineElement: 'PipelineElement',
  TwoPhaseSeparatorElement: 'TwoPhaseSeparatorElement',
  ThreePhaseSeparatorElement: 'ThreePhaseSeparatorElement',
  FractionatorElement: 'FractionatorElement',
  CatCrackingElement: 'CatCrackingElement',
  CatReformingElement: 'CatReformingElement',
  CatReformingReactorBlockElement: 'CatReformingReactorBlockElement',
  ConversionReactorElement: 'ConversionReactorElement',
  EquilibriumReactorElement: 'EquilibriumReactorElement',
  AbsorberElement: 'AbsorberElement',
  ComponentSplitterElement: 'ComponentSplitterElement',
  CatCrackingReactorRegeneratorBlockElement: 'CatCrackingReactorRegeneratorBlockElement',
  DistillationColumnWithReboilerAndCapacitorElement:
    'DistillationColumnWithReboilerAndCapacitorElement',
  DieselFuelHydrotreatingElement: 'DieselFuelHydrotreatingElement',
  DieselFuelHydrotreatingReactorBlockElement: 'DieselFuelHydrotreatingReactorBlockElement',
  CokerElement: 'CokerElement',
  CokerReactorBlockElement: 'CokerReactorBlockElement',
  MaterialDotElement: 'MaterialDotElement',
  EnergyDotElement: 'EnergyDotElement',
  Balancer: 'Balancer',
  MultiStepCoherent: 'MultiStepCoherent',
  MultiStepExternal: 'MultiStepExternal'
} as const;
export type ElementClassName = (typeof ElementClassName)[keyof typeof ElementClassName];

export function instanceOfElementClassName(value: any): boolean {
  for (const key in ElementClassName) {
    if (Object.prototype.hasOwnProperty.call(ElementClassName, key)) {
      if ((ElementClassName as Record<string, ElementClassName>)[key] === value) {
        return true;
      }
    }
  }
  return false;
}

export function ElementClassNameFromJSON(json: any): ElementClassName {
  return ElementClassNameFromJSONTyped(json, false);
}

export function ElementClassNameFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ElementClassName {
  return json as ElementClassName;
}

export function ElementClassNameToJSON(value?: ElementClassName | null): any {
  return value as any;
}
