/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
/**
 *
 * @export
 * @interface AdditionalTabRow
 */
export interface AdditionalTabRow {
  /**
   *
   * @type {string}
   * @memberof AdditionalTabRow
   */
  uniqueCode: string;
  /**
   *
   * @type {string}
   * @memberof AdditionalTabRow
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof AdditionalTabRow
   */
  isActive: boolean;
}

/**
 * Check if a given object implements the AdditionalTabRow interface.
 */
export function instanceOfAdditionalTabRow(value: object): value is AdditionalTabRow {
  if (!('uniqueCode' in value) || value['uniqueCode'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('isActive' in value) || value['isActive'] === undefined) return false;
  return true;
}

export function AdditionalTabRowFromJSON(json: any): AdditionalTabRow {
  return AdditionalTabRowFromJSONTyped(json, false);
}

export function AdditionalTabRowFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AdditionalTabRow {
  if (json == null) {
    return json;
  }
  return {
    uniqueCode: json['unique_code'],
    name: json['name'],
    isActive: json['is_active']
  };
}

export function AdditionalTabRowToJSON(value?: AdditionalTabRow | null): any {
  if (value == null) {
    return value;
  }
  return {
    unique_code: value['uniqueCode'],
    name: value['name'],
    is_active: value['isActive']
  };
}
