import {Instance, types} from 'mobx-state-tree';
import {EElement} from '@progress-fe/core';

const EntityBaseInfo = types
  .model('EntityBaseInfo', {
    uuid: types.string,
    name: types.string,
    lastUpdated: types.Date,
    deletable: types.maybe(types.boolean),
    type: types.maybeNull(types.enumeration(Object.values(EElement)))
  })
  .actions((self) => ({
    setName(name: string): void {
      self.name = name;
    }
  }));

export type TEntityBaseInfoModel = Instance<typeof EntityBaseInfo>;

export {EntityBaseInfo};
