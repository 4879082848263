import {FC, useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import AutoSizer from 'react-virtualized-auto-sizer';
import {Box, Button, Flex, Grid, Text} from '@chakra-ui/react';
import {Dialog, DropzoneContainer, Input} from '@progress-fe/ui-kit';
import {hasValue, useT} from '@progress-fe/core';

import {useStore} from 'core/hooks';

import {BlendList, SelectedBlend} from './components';

const BlendsCatalogFC: FC = () => {
  const {techProcessStore} = useStore();
  const {projectSettings} = techProcessStore;
  const {isBlendImporting, importedBlends} = projectSettings;

  const [query, setQuery] = useState('');
  const [prefix, setPrefix] = useState('');
  const [isDialog, setIsDialog] = useState(false);
  const [selectedBlendIdx, setSelectedBlendIdx] = useState<number | null>(null);

  const {t} = useT();

  const filteredImportedBlends = importedBlends.filter(
    (opt) =>
      opt.name.toLowerCase().includes(query.toLowerCase()) ||
      opt.prefix.toLowerCase().includes(query.toLowerCase())
  );

  const selectedBlend = useMemo(() => {
    return hasValue(selectedBlendIdx) ? importedBlends[selectedBlendIdx] : null;
  }, [importedBlends, selectedBlendIdx]);

  const handleOpenDialog = () => {
    setIsDialog(true);
  };

  const handleCloseDialog = () => {
    if (!isBlendImporting) {
      setIsDialog(false);
      setPrefix('');
    }
  };

  const handleSetPrefix = (value: string) => {
    setPrefix(value.toUpperCase());
  };

  const handleOnImport = async (file: File) => {
    if (!isBlendImporting) {
      await projectSettings.importBlend(file, prefix);
      projectSettings._loadBlends().then();
      handleCloseDialog();
    }
  };

  return (
    <Grid gridTemplateRows={'32px 24px 2fr 24px 32px 2fr'} h={'calc(100vh - 130px)'}>
      <Text pt={'8px'} pl={'1px'} fontSize={'12px'} fontWeight={500}>
        {t('common.blends')}
      </Text>

      <Grid gridTemplateColumns={'1fr 140px'} gap={'8px'}>
        <Input
          size={'xs'}
          value={query}
          variant={'outline'}
          placeholder={t('actions.search')}
          onChange={setQuery}
        />

        <Button size={'xs'} variant={'ghost'} w={'100%'} onClick={handleOpenDialog}>
          {t('actions.import')}
        </Button>
      </Grid>

      {/* BLENDS DICTIONARY */}
      <Box height={'100%'}>
        <AutoSizer disableWidth={false}>
          {({height, width}) => (
            <Box width={width} pt={'8px'}>
              <BlendList
                blends={filteredImportedBlends}
                tableSx={{height: height - 30}}
                selectedBlendIdx={selectedBlendIdx}
                onSelectBlendIdx={setSelectedBlendIdx}
              />
            </Box>
          )}
        </AutoSizer>
      </Box>

      <Text pl={'1px'} fontSize={'12px'} fontWeight={500}>
        {t('common.composition')}
      </Text>

      <Grid gridTemplateColumns={'auto 1fr'} gap={'8px'}>
        <Text pl={'1px'} pt={'3px'} fontSize={'12px'}>
          {t('fields.uom')}
        </Text>
        <Input
          disabled
          size={'xs'}
          variant={'outline'}
          value={selectedBlend ? t(`enum.uom.${selectedBlend.unitsOfMeasure}`) : ''}
        />
      </Grid>

      {/* SELECTED BLEND DETAILS */}
      <Box height={'100%'}>
        <AutoSizer disableWidth={false}>
          {({height, width}) => (
            <Box width={width}>
              <SelectedBlend blend={selectedBlend} tableSx={{height}} />
            </Box>
          )}
        </AutoSizer>
      </Box>

      {isDialog && (
        <Dialog title={t('blends.import')} onClose={handleCloseDialog}>
          <Box w={'250px'}>
            <Flex flexDirection={'column'} gap={'8px'}>
              <Box>{t('blends.prefixMsg')}</Box>

              <Grid gridTemplateColumns={'70px 1fr'} alignItems={'center'}>
                <Box>{t('common.prefix')}</Box>
                <Input size="xs" variant="outline" value={prefix} onChange={handleSetPrefix} />
              </Grid>

              <Grid p={'8px 0'} gridTemplateColumns={'1fr 1fr'} gap={'8px'}>
                <Button
                  w={'100%'}
                  size={'xs'}
                  variant={'ghost'}
                  isDisabled={isBlendImporting}
                  onClick={handleCloseDialog}
                >
                  {t('actions.cancel')}
                </Button>
                <DropzoneContainer onSelectFile={handleOnImport}>
                  <Button
                    w={'100%'}
                    size={'xs'}
                    variant={'solid'}
                    isDisabled={!prefix || isBlendImporting}
                  >
                    {t('actions.import')}
                  </Button>
                </DropzoneContainer>
              </Grid>
            </Flex>
          </Box>
        </Dialog>
      )}
    </Grid>
  );
};

export const BlendsCatalog = observer(BlendsCatalogFC);
