import {SnapshotIn, types} from 'mobx-state-tree';
import {EStructureItem} from '@progress-fe/core';

import {ResetModel} from 'core/models';

const UiState = types
  .compose(
    ResetModel,
    types.model('UiState', {
      tabIndex: 0,
      entityId: '',
      entityType: types.optional(
        types.enumeration(Object.values(EStructureItem)),
        EStructureItem.Settings
      ),
      subEntityId: types.maybeNull(types.string)
    })
  )
  .actions((self) => ({
    select(type: EStructureItem, uuid?: string | null, subUuid?: string | null): void {
      self.entityType = type;
      self.entityId = uuid ?? '';
      self.subEntityId = subUuid ?? null;
    }
  }))
  .actions((self) => ({
    _setTabIndex(index: number) {
      self.tabIndex = index;
    }
  }))
  .views((self) => ({
    get isSubEntitySelected(): boolean {
      return !!self.subEntityId;
    }
  }))
  .views((self) => ({
    get isElement(): boolean {
      return self.entityType === EStructureItem.Element;
    },
    get isModel(): boolean {
      return self.entityType === EStructureItem.Model;
    },
    get isSampleResult(): boolean {
      return self.entityType === EStructureItem.SampleResult;
    },
    get isBlendResult(): boolean {
      return self.entityType === EStructureItem.BlendResult;
    }
  }))
  .views((self) => ({
    get elementId(): string | null {
      return self.isElement ? self.entityId : null;
    },
    get subElementId(): string | null {
      return self.isElement && !!self.subEntityId ? self.subEntityId : null;
    },
    get modelId(): string | null {
      return self.isModel ? self.entityId : null;
    }
  }));

export type TUiStateSnapshotIn = SnapshotIn<typeof UiState>;

export {UiState};
