import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Tag, TagLabel, useToken} from '@chakra-ui/react';
import {i18n} from '@progress-fe/core';
import {hexToRGB} from '@progress-fe/ui-kit';

import {ProjectTypeEnum} from 'api';

interface ITypeBadge {
  name: string;
  color: string;
}

interface IProps {
  type: ProjectTypeEnum;
}

const TagsOptions: Record<ProjectTypeEnum, ITypeBadge> = {
  [ProjectTypeEnum.SamplesAndOilBlend]: {
    name: i18n.t(`enum.projectType.${ProjectTypeEnum.SamplesAndOilBlend}`),
    color: 'highlight'
  },
  [ProjectTypeEnum.TechProcess]: {
    name: i18n.t(`enum.projectType.${ProjectTypeEnum.TechProcess}`),
    color: 'accentIcon'
  }
};

const ProjectTypeTagFC: FC<IProps> = ({type}) => {
  const tagOption = TagsOptions[type];

  const [colorHex] = useToken('colors', [tagOption.color]);

  return (
    <Tag h="24px" p="4px 8px" borderRadius="8px" backgroundColor={hexToRGB(colorHex, 0.1)}>
      <TagLabel color={colorHex} fontWeight="400" lineHeight="16px">
        {tagOption.name}
      </TagLabel>
    </Tag>
  );
};

export const ProjectTypeTag = observer(ProjectTypeTagFC);
