/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {ModelOut} from './ModelOut';
import {ModelOutFromJSON, ModelOutFromJSONTyped, ModelOutToJSON} from './ModelOut';

/**
 *
 * @export
 * @interface CreateModelActionResult
 */
export interface CreateModelActionResult {
  /**
   *
   * @type {boolean}
   * @memberof CreateModelActionResult
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateModelActionResult
   */
  errorMsg?: string | null;
  /**
   *
   * @type {ModelOut}
   * @memberof CreateModelActionResult
   */
  data: ModelOut;
}

/**
 * Check if a given object implements the CreateModelActionResult interface.
 */
export function instanceOfCreateModelActionResult(value: object): value is CreateModelActionResult {
  if (!('success' in value) || value['success'] === undefined) return false;
  if (!('data' in value) || value['data'] === undefined) return false;
  return true;
}

export function CreateModelActionResultFromJSON(json: any): CreateModelActionResult {
  return CreateModelActionResultFromJSONTyped(json, false);
}

export function CreateModelActionResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateModelActionResult {
  if (json == null) {
    return json;
  }
  return {
    success: json['success'],
    errorMsg: json['error_msg'] == null ? undefined : json['error_msg'],
    data: ModelOutFromJSON(json['data'])
  };
}

export function CreateModelActionResultToJSON(value?: CreateModelActionResult | null): any {
  if (value == null) {
    return value;
  }
  return {
    success: value['success'],
    error_msg: value['errorMsg'],
    data: ModelOutToJSON(value['data'])
  };
}
