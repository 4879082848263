import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Center} from '@chakra-ui/react';
import {IEntityErrorMessage, Loader} from '@progress-fe/ui-kit';

import {TAdditionalTabModel, TJsonSchemaModel} from 'core/models';

import {IJsonSchemaTab, JsTabsForm} from './components';

interface IProps {
  uuid: string;
  name: string | undefined;
  isLoading?: boolean;
  isUpdating?: boolean;
  parentFormName?: string | null;
  jsonSchemas: Array<TJsonSchemaModel> | null | undefined;
  additionalTabs: Array<TAdditionalTabModel> | null | undefined;
  errors?: Array<IEntityErrorMessage>;
  onUpdateFormData: (
    uuid: string,
    schemaId: string,
    formData: unknown,
    tabCode?: string
  ) => Promise<void>;
  onToggleTab?: (uniqueCode: string) => Promise<void>;
  onBackToForm?: () => void;
}

const BaseJsFormsFC: FC<IProps> = ({
  uuid,
  name,
  errors,
  isLoading,
  isUpdating,
  jsonSchemas,
  additionalTabs,
  parentFormName,
  onUpdateFormData,
  onBackToForm,
  onToggleTab
}) => {
  const tabs: IJsonSchemaTab[] =
    jsonSchemas?.map((jsonSchema, index) => ({
      index: index,
      name: jsonSchema.name,
      jsonSchema
    })) || [];

  return (
    <Box h={'100%'}>
      {isLoading ? (
        <Center h={'100%'}>
          <Loader />
        </Center>
      ) : (
        <>
          {tabs.length > 0 && (
            <JsTabsForm
              name={name}
              tabs={tabs}
              errors={errors}
              isUpdating={isUpdating}
              parentFormName={parentFormName}
              additionalTabs={additionalTabs || []}
              onToggleTab={onToggleTab}
              onBackToForm={onBackToForm}
              onChange={async (formData, tabIdx: number) => {
                await onUpdateFormData(
                  uuid,
                  tabs[tabIdx].jsonSchema.id,
                  formData,
                  tabs[tabIdx].jsonSchema.tabCode
                );
              }}
            />
          )}
        </>
      )}
    </Box>
  );
};

export const BaseJsForms = observer(BaseJsFormsFC);
