import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {SystemStyleObject} from '@chakra-ui/styled-system';
import {VirtualTable, ISimpleTableColumn, ISimpleTableRow} from '@progress-fe/ui-kit';
import {useT, IImportedBlend} from '@progress-fe/core';

interface IProps {
  blend: IImportedBlend | null | undefined;
  tableSx?: SystemStyleObject;
}

const SelectedBlendFC: FC<IProps> = ({blend, tableSx}) => {
  const {t} = useT();

  const columns: ISimpleTableColumn[] = [
    {name: t('fields.pseudoComponent'), align: 'left'},
    {name: t('fields.content'), align: 'left'}
  ];

  const rows: ISimpleTableRow<string>[] =
    blend?.pseudoComponents.map((sample) => {
      return {
        key: sample.uuid,
        values: [sample.name, sample.value]
      };
    }) ?? [];

  return (
    <VirtualTable
      rows={rows}
      columns={columns}
      isTableShownWhenEmpty
      noItemsTitle={t('blends.noSelected')}
      tableSx={tableSx}
    />
  );
};

export const SelectedBlend = observer(SelectedBlendFC);
