import {flow, Instance, types} from 'mobx-state-tree';
import {IComponent} from '@progress-fe/core';

import {TProjectBaseModel} from '../core/models';

import {AuthStore} from './AuthStore';
import {CatalogsStore} from './CatalogsStore';
import {MyProjectsStore} from './MyProjectsStore';
import {SamplesAndBlendsStore} from './SamplesAndBlendsStore';
import {TechProcessStore} from './TechProcessStore';

const RootStore = types
  .model('RootStore', {
    isInitialized: false,
    authStore: types.optional(AuthStore, {}),
    catalogsStore: types.optional(CatalogsStore, {}),
    myProjectsStore: types.optional(MyProjectsStore, {}),
    techProcessStore: types.optional(TechProcessStore, {}),
    samplesAndBlendsStore: types.optional(SamplesAndBlendsStore, {})
  })
  .actions((self) => ({
    initApplication: flow(function* () {
      yield self.authStore.loadUser();
      yield self.catalogsStore.load();
      self.isInitialized = true;
    })
  }))
  .views((self) => ({
    get favoriteComponents(): IComponent[] {
      return self.techProcessStore.projectSettings.favorites.map(
        (uuid) => self.catalogsStore.componentsDictionary[uuid]
      );
    },
    get projectStore(): TProjectBaseModel | null {
      return self.techProcessStore ?? self.samplesAndBlendsStore ?? null;
    }
  }));

export type TRootStore = Instance<typeof RootStore>;

export {RootStore};
