/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {HandleQualifier} from './HandleQualifier';
import {
  HandleQualifierFromJSON,
  HandleQualifierFromJSONTyped,
  HandleQualifierToJSON
} from './HandleQualifier';

/**
 *
 * @export
 * @interface HandleQualifiers
 */
export interface HandleQualifiers {
  /**
   *
   * @type {string}
   * @memberof HandleQualifiers
   */
  handle: string;
  /**
   *
   * @type {Array<HandleQualifier>}
   * @memberof HandleQualifiers
   */
  qualifiers: Array<HandleQualifier>;
}

/**
 * Check if a given object implements the HandleQualifiers interface.
 */
export function instanceOfHandleQualifiers(value: object): value is HandleQualifiers {
  if (!('handle' in value) || value['handle'] === undefined) return false;
  if (!('qualifiers' in value) || value['qualifiers'] === undefined) return false;
  return true;
}

export function HandleQualifiersFromJSON(json: any): HandleQualifiers {
  return HandleQualifiersFromJSONTyped(json, false);
}

export function HandleQualifiersFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): HandleQualifiers {
  if (json == null) {
    return json;
  }
  return {
    handle: json['handle'],
    qualifiers: (json['qualifiers'] as Array<any>).map(HandleQualifierFromJSON)
  };
}

export function HandleQualifiersToJSON(value?: HandleQualifiers | null): any {
  if (value == null) {
    return value;
  }
  return {
    handle: value['handle'],
    qualifiers: (value['qualifiers'] as Array<any>).map(HandleQualifierToJSON)
  };
}
