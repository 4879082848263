/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {UoM} from './UoM';
import {UoMFromJSON, UoMFromJSONTyped, UoMToJSON} from './UoM';
import type {PseudoComponentOut} from './PseudoComponentOut';
import {
  PseudoComponentOutFromJSON,
  PseudoComponentOutFromJSONTyped,
  PseudoComponentOutToJSON
} from './PseudoComponentOut';

/**
 *
 * @export
 * @interface AppProjectsTechprocessSchemasBlendOut
 */
export interface AppProjectsTechprocessSchemasBlendOut {
  /**
   *
   * @type {string}
   * @memberof AppProjectsTechprocessSchemasBlendOut
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof AppProjectsTechprocessSchemasBlendOut
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AppProjectsTechprocessSchemasBlendOut
   */
  prefix: string;
  /**
   *
   * @type {UoM}
   * @memberof AppProjectsTechprocessSchemasBlendOut
   */
  unitsOfMeasure: UoM;
  /**
   *
   * @type {Array<PseudoComponentOut>}
   * @memberof AppProjectsTechprocessSchemasBlendOut
   */
  pseudoComponents: Array<PseudoComponentOut>;
}

/**
 * Check if a given object implements the AppProjectsTechprocessSchemasBlendOut interface.
 */
export function instanceOfAppProjectsTechprocessSchemasBlendOut(
  value: object
): value is AppProjectsTechprocessSchemasBlendOut {
  if (!('uuid' in value) || value['uuid'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('prefix' in value) || value['prefix'] === undefined) return false;
  if (!('unitsOfMeasure' in value) || value['unitsOfMeasure'] === undefined) return false;
  if (!('pseudoComponents' in value) || value['pseudoComponents'] === undefined) return false;
  return true;
}

export function AppProjectsTechprocessSchemasBlendOutFromJSON(
  json: any
): AppProjectsTechprocessSchemasBlendOut {
  return AppProjectsTechprocessSchemasBlendOutFromJSONTyped(json, false);
}

export function AppProjectsTechprocessSchemasBlendOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AppProjectsTechprocessSchemasBlendOut {
  if (json == null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    name: json['name'],
    prefix: json['prefix'],
    unitsOfMeasure: UoMFromJSON(json['units_of_measure']),
    pseudoComponents: (json['pseudo_components'] as Array<any>).map(PseudoComponentOutFromJSON)
  };
}

export function AppProjectsTechprocessSchemasBlendOutToJSON(
  value?: AppProjectsTechprocessSchemasBlendOut | null
): any {
  if (value == null) {
    return value;
  }
  return {
    uuid: value['uuid'],
    name: value['name'],
    prefix: value['prefix'],
    units_of_measure: UoMToJSON(value['unitsOfMeasure']),
    pseudo_components: (value['pseudoComponents'] as Array<any>).map(PseudoComponentOutToJSON)
  };
}
