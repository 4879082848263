import {FC, memo, useState} from 'react';
import {Box, Grid} from '@chakra-ui/react';
import {useT} from '@progress-fe/core';
import {Dialog, Select, ISelectOption} from '@progress-fe/ui-kit';

interface IProps {
  nodeA: string;
  nodeB: string;
  isPortA: boolean;
  options: Array<ISelectOption<string>>;
  onSelect: (qualifier: string) => Promise<void>;
  onClose: () => Promise<void>;
}

const QualifierDialogFC: FC<IProps> = ({nodeA, nodeB, isPortA, options, onSelect, onClose}) => {
  const [qualifier, setQualifier] = useState<string>('');

  const {t} = useT();

  return (
    <Dialog
      title={t('connections.createBetween', {nodeA, nodeB})}
      buttons={{
        apply: {
          isDisabled: !qualifier,
          title: t('actions.select'),
          onClick: async () => {
            await onSelect(qualifier);
          }
        },
        close: {
          title: t('actions.cancel'),
          onClick: async () => {
            await onClose();
          }
        }
      }}
    >
      <Box w={'400px'}>
        <Grid gridTemplateColumns={`1fr 1fr`} alignItems={'center'}>
          <Box>{t('connections.portName', {name: isPortA ? nodeA : nodeB})}</Box>
          <Select
            options={options}
            name={'Qualifier-Select'}
            placeholder={t('common.notSelected')}
            value={options.find((opt) => opt.value === qualifier)}
            onChange={(v) => setQualifier((v as ISelectOption<string>).value)}
          />
        </Grid>
      </Box>
    </Dialog>
  );
};

export const QualifierDialog = memo(QualifierDialogFC);
