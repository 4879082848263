import {applySnapshot, flow, Instance, types} from 'mobx-state-tree';
import {EElement} from '@progress-fe/core';

import {EWidgetType} from 'core/enums';
import {
  CalculationResultUpdateOut,
  RJSFSchemas,
  TechProcessApi,
  TechprocessCalculationResult
} from 'api';
import {
  ResetModel,
  RequestModel,
  ElementResultInfo,
  TElementResultInfoModel,
  JsonSchemaForm
} from 'core/models';

const ProjectElementsResults = types
  .compose(
    ResetModel,
    types.model('ProjectElementsResults', {
      projectUuid: '',
      checkpointUuid: '',

      elementResults: types.optional(types.array(ElementResultInfo), []),
      jsonForm: types.maybeNull(JsonSchemaForm),

      fetchRequest: types.optional(RequestModel, {})
    })
  )
  .actions((self) => ({
    _clearJsonForm() {
      self.jsonForm?.clear();
      self.jsonForm = null;
    },
    _reloadJsonForm: flow(function* (uuid: string) {
      self.jsonForm = JsonSchemaForm.create({entityUuid: uuid});
      const response: {[key: string]: RJSFSchemas} = yield self.jsonForm.fetchRequest.send(
        TechProcessApi.techProcessGetTechprocessCalculationResultSchemas.bind(TechProcessApi),
        {
          calculationResultUuid: uuid,
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (response) {
        self.jsonForm = JsonSchemaForm.create({entityUuid: uuid});
        self.jsonForm.setJsonSchemas(response);
      }
    })
  }))
  .actions((self) => ({
    _reload: flow(function* () {
      const response: TechprocessCalculationResult[] = yield self.fetchRequest.send(
        TechProcessApi.techProcessGetTechprocessCalculationResults.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      const responseResults = response.map((result) => ({
        uuid: result.uuid,
        name: result.name,
        deletable: false,
        lastUpdated: new Date(),
        elementUuid: result.elementUuid,
        elementType: result.elementType as EElement,
        widget: {
          uuid: result.widget.uuid,
          name: result.widget.name,
          data: result.widget.data,
          widgetType: result.widget.widgetType as EWidgetType
        }
      }));

      applySnapshot(self.elementResults, responseResults);
    })
  }))
  .actions((self) => ({
    _updateElementResultName(uuid: string, name: string) {
      const elementResult = self.elementResults.find((n) => n.uuid === uuid);
      elementResult?.setName(name);
    }
  }))
  .actions((self) => ({
    _updateElementResultFormData: flow(function* (uuid: string, schemaId: string, data: unknown) {
      const jsonSchema = self.jsonForm?.jsonSchemas.find((js) => js.id === schemaId);
      if (!self.jsonForm || !jsonSchema) {
        return;
      }

      jsonSchema.updateFormData(data);
      const response: CalculationResultUpdateOut = yield self.jsonForm.updateRequest.send(
        TechProcessApi.techProcessUpdateTechprocessCalculationResult.bind(TechProcessApi),
        {
          calculationResultUuid: uuid,
          body: data as object,
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (response?.name) {
        self._updateElementResultName(uuid, response.name);
      }
    })
  }))
  .actions((self) => ({
    init: flow(function* (projectUuid: string, checkpointUuid: string) {
      self.projectUuid = projectUuid;
      self.checkpointUuid = checkpointUuid;
      yield self._reload();
    })
  }))
  .actions((self) => ({
    hasElementResult(uuid: string) {
      return self.elementResults.some((r) => r.uuid === uuid);
    },
    findElementResult(uuid: string): TElementResultInfoModel | undefined {
      return self.elementResults.find((e) => e.uuid === uuid);
    }
  }))
  .views((self) => ({
    get isLoading(): boolean {
      return self.fetchRequest.isPending;
    },
    get isFormLoading(): boolean {
      return self.jsonForm?.isFormLoading ?? false;
    },
    get isFormDataUpdating(): boolean {
      return self.jsonForm?.isFormDataUpdating ?? false;
    }
  }));

export type TProjectElementsResultsModel = Instance<typeof ProjectElementsResults>;

export {ProjectElementsResults};
