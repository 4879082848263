import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import AutoSizer from 'react-virtualized-auto-sizer';
import {Box, Center, IconButton} from '@chakra-ui/react';
import {EStructureItem, useT} from '@progress-fe/core';
import {Svg} from '@progress-fe/ui-kit';

import {useStore} from 'core';
import {RFWidgetsZone} from 'ui-kit';

interface IProps {
  isExpanded: boolean;
  toggleIsExpanded: () => void;
}

const MIN_RF_WIDTH_PX = 470;
const MIN_RF_MESSAGE_WIDTH_PX = 160;

const ProjectZonesFC: FC<IProps> = ({isExpanded, toggleIsExpanded}) => {
  const {samplesAndBlendsStore} = useStore();
  const {projectSamplesResults, projectBlendsResults} = samplesAndBlendsStore;
  const {selectEntityByType, uiState} = samplesAndBlendsStore;
  const {entityId} = uiState;

  const {t} = useT();

  return (
    <Box
      h="100%"
      bg="darkWhite"
      position={isExpanded ? 'absolute' : 'relative'}
      {...(isExpanded ? {top: 0, bottom: 0, left: '-48px', right: 0, zIndex: 1} : {})}
    >
      <AutoSizer disableWidth={false}>
        {({height, width}) => {
          if (width < MIN_RF_WIDTH_PX) {
            return (
              <Center
                bg="darkWhite"
                width={`${width}px`}
                height={`${height}px`}
                flexDirection="column"
              >
                {width >= MIN_RF_MESSAGE_WIDTH_PX && (
                  <Box p="0 8px" textAlign="center">
                    <Box fontWeight={700}>{t('rfZones.common.noSpace')}</Box>
                    <Box>{t('rfZones.common.changeWidth')}</Box>
                  </Box>
                )}
              </Center>
            );
          }

          return (
            <Box width={width}>
              <Box top="12px" right="12px" position="absolute" borderRadius="4px" zIndex={1}>
                <IconButton
                  size="sm"
                  aria-label=""
                  variant="ghostBg"
                  onClick={toggleIsExpanded}
                  sx={{boxShadow: 'shadow_2'}}
                  icon={<Svg name={isExpanded ? 'Collapse' : 'Expand'} />}
                />
              </Box>

              {/* SAMPLES RESULTS WIDGETS */}
              {uiState.isSampleResult && !!entityId && (
                <Box position="absolute" top={0} left={0} right={0} bottom={0}>
                  <RFWidgetsZone
                    height={height}
                    selectedEntityId={entityId}
                    widgetList={projectSamplesResults.sampleResults.map((er) => er.widgetData)}
                    onSelect={(uuid) => selectEntityByType(EStructureItem.SampleResult, uuid)}
                  />
                </Box>
              )}

              {/* BLENDS RESULTS WIDGETS */}
              {uiState.isBlendResult && !!entityId && (
                <Box position="absolute" top={0} left={0} right={0} bottom={0}>
                  <RFWidgetsZone
                    height={height}
                    selectedEntityId={entityId}
                    widgetList={projectBlendsResults.blendResults.map((er) => er.widgetData)}
                    onSelect={(uuid) => selectEntityByType(EStructureItem.BlendResult, uuid)}
                  />
                </Box>
              )}

              {/* EMPTY WIDGETS */}
              {!uiState.isSampleResult && !uiState.isBlendResult && (
                <Box position="absolute" top={0} left={0} right={0} bottom={0}>
                  <RFWidgetsZone height={height} />
                </Box>
              )}
            </Box>
          );
        }}
      </AutoSizer>
    </Box>
  );
};

export const ProjectZones = observer(ProjectZonesFC);
